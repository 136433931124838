import React, { useState, useEffect, useContext } from 'react';
function useFilterReport() { //filter report data by selected Audit End Dates
    const [filteredData, setFilteredData] = useState([]); //use filteredData as temp arr
    //const [counter, setCounter] = useState(0);

    function handleFiltering(reportData, selectedAuditDates) {
        if (selectedAuditDates) {
            //console.log("reportData --- ", reportData);
            //console.log("selectedAuditDates --- ", selectedAuditDates);
            setFilteredData(reportData?.filter((record) => //filter each record by selected date(s)
                //selectedAuditDates?.map((date) => {
                //    record?.auditEndDate?.includes(date)
                //})
                //setCounter(...counter + 1);
                //counter = counter + 1;
                record?.auditEndDate?.toLowerCase().includes(selectedAuditDates[0]?.auditEndDate) //select all records with given date
            ));

        }
    }


    return { filteredData, handleFiltering };
}
export default useFilterReport;