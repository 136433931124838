import DocumentViewer from "../components/processing/DocumentViewer";
//import useFetch from "../Hooks/useFetch";
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import IconButton from '@mui/material/IconButton';
import Typography from "@mui/material/Typography";
import { useEffect, useState, useContext, createContext, useMemo, useCallback, useRef } from 'react';
import Button from '@mui/material/Button';
import { BatchContext, DocViewerContext } from "../App.js";
import bmilogo from '../assets/bmi-logo-cross-transparent.png';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import { RoutesContext } from "../App";
import { RolesContext } from "../App";
import { PermissionsContext } from "../App";
import { SidebarContext } from "../App";
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import HomeIcon from '@mui/icons-material/Home';
import { useParams, useNavigate, Navigate, Link, Outlet, useLocation } from "react-router-dom";
import { ToastContainer, toast, Bounce } from 'react-toastify';
import { useMsal } from '@azure/msal-react';
import { loginRequest, b2cPolicies } from "../authConfig";
import { InteractionType, InteractionStatus, InteractionRequiredAuthError } from '@azure/msal-browser';
import { debounce, throttle } from 'lodash';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';

export default function Viewer() {
    const navigate = useNavigate();


    const [choice, setChoice] = useState('');
    //const { responseData: docData, responseError: docError, loading: docLoading, getData: getDocData } = useFetch();
    const [document, setDocument] = useState(null);
    //const reader = new FileReader();
    const { documentListState, setDocumentListState, batchFamilyId, setBatchFamilyId } = useContext(BatchContext);
    //const [batch, setBatch] = useState([]);
    const [documentList, setDocumentList] = useState([]);
    const [familyDetails, setFamilyDetails] = useState([]);
    const [employeeDetails, setEmployeeDetails] = useState([]);
    //const [auditDetails, setAuditDetails] = useState([]);
    const [currentDocList, setCurrentDocList] = useState([]);
    const [currentDocDetails, setCurrentDocDetails] = useState([]);
    const { accounts, instance } = useMsal();
    //const [docNum, setDocNum] = useState(0);
    //const [numDocs, setNumDocs] = useState(0);
    //const [audit, setAudit] = useState(null);
    //const [familyId, setFamilyId] = useState(null);

    //const [documentDependentProgress, setDocumentDependentProgress] = useState([]);

    const { permissions, setPermissions } = useContext(PermissionsContext);
    const { roles, setRoles } = useContext(RolesContext);

    const {

        setSidebarTitle,
        sidebarActions,
        setSidebarActions,
        setClientSearchData
    } = useContext(SidebarContext);

    const {
        docNum, numDocs, prevDocument, nextDocument, fetchBatch,
        documentDependentProgress, setDocumentDependentProgress,
        handleUpdateDocumentDependentProgressIndicator,
        handleProvideDocumentProcessStatusByDependentID,
        batch, setBatch, setDocNum, setNumDocs, documentTypes, setDocumentTypes, relationshipTypes, setRelationshipTypes,
        isDocSwitchDisabled, setIsDocSwitchDisabled, auditDocData, setAuditDocData, currentAuditDocData, setCurrentAuditDocData,
        currentFamilyId, setCurrentFamilyId, setCurrentAuditId, currentAuditId
    } = useContext(DocViewerContext);
    
    

    //function nextDocument() {

    //    setDocNum(docNum + 1);

    //}

    //function prevDocument() {

    //    setDocNum(docNum - 1);

    //}
    const [isLoading, setIsLoading] = useState(false);
    const { newRoutes, setRoutes } = useContext(RoutesContext);
    const [pageRoutes, setPageRoutes] = useState([
        {
            id: 0,
            label: "Home",
            path: "report",
            icon: <HomeIcon />,
            isActive: false,
            style: '',
            isNested: false
        },
        {
            id: 1,
            label: "Processing",
            path: "processing",
            icon: <AssignmentTurnedInIcon />,
            isActive: true,
            style: 'active-navLink border-bottom__thick-blue',
            isNested: false
        },
        {
            id: 2,
            label: "Search All",
            path: "globalsearch",
            icon: <TravelExploreIcon />,
            isActive: false,
            style: 'active-navLink border-bottom__thick-blue',
            isNested: false
        }
    ]);

    if ((roles === "CLIENT")) {
        /* <Navigate to={`/report/${String(permissions[0]?.auditId)}/${permissions[0]?.auditName}`} replace />*/

        navigate(`/report/${String(permissions[0]?.auditId)}/${permissions[0]?.auditName}`, { replace: true });



    }

    useEffect(() => {



        if ({ pageRoutes } !== { newRoutes } && (roles === "SERVICES")) {
            setRoutes([
                {
                    id: 1,
                    label: "Processing",
                    path: "processing",
                    icon: <AssignmentTurnedInIcon />,
                    isActive: true,
                    style: 'active-navLink border-bottom__thick-blue',
                    isNested: false
                },
                {
                    id: 2,
                    label: "Search All",
                    path: "globalsearch",
                    icon: <TravelExploreIcon />,
                    isActive: false,
                    style: 'active-navLink border-bottom__thick-blue',
                    isNested: false
                }
            ])
            //console.log("newroutes - : report", {newRoutes});
            //console.log("pageroutes - report: ", { pageRoutes });
        }

        if ({ pageRoutes } !== { newRoutes } && (roles === "PROJMGR")) {
            setRoutes([
                {
                    id: 0,
                    label: "Home",
                    path: "report",
                    icon: <HomeIcon />,
                    isActive: false,
                    style: '',
                    isNested: false
                },
                {
                    id: 1,
                    label: "Processing",
                    path: "processing",
                    icon: <AssignmentTurnedInIcon />,
                    isActive: true,
                    style: 'active-navLink border-bottom__thick-blue',
                    isNested: false
                },
                {
                    id: 2,
                    label: "Search All",
                    path: "globalsearch",
                    icon: <TravelExploreIcon />,
                    isActive: false,
                    style: 'active-navLink border-bottom__thick-blue',
                    isNested: false
                }
            ])
            
        }
        //setIsLoading(true);
    }, [pageRoutes, roles]);

    useEffect(() => {
        setIsLoading(true);
    }, []);

    const [documentBatch, setDocumentBatch] = useState([]);
    const [isNextDocumentDisabled, setIsNextDocumentDisabled] = useState(true); //disables next/prev document while fetching batch
    const [currentDocumentBlob, setCurrentDocumentBlob] = useState([]); //holds current document url to pass to document viewer component 
    const [batchFetch, setBatchFetch] = useState(true);
    const [initDocFetch, setInitDocFetch] = useState(true);
    const [tempBatchArr, setTempBatchArr] = useState([]);

    useEffect(() => {
        //setCurrentDocDetails(documentList[docNum]);

        //async function fetchDocument() {
        //    await fetch(process.env.REACT_APP_BACK_URL + '/api/Processing/GetDocument',
        //        {
        //            method: 'POST',
        //            mode: 'cors',
        //            headers: { "Content-Type": "application/json", },
        //            body: JSON.stringify({ "documentId": documentList[docNum]?.id }),
        //        })
        //        .then((res) => res.blob())
        //        .then((response) => {
        //            setDocument({ response: URL.createObjectURL(response), type: response.type });
        //            console.log("response type = " + response.type);
        //            console.log(URL.createObjectURL(response));

        //        })
        //        .catch((error) => {
        //            console.log(error);
        //        });
        //}

        //TODO: fetch batch of all documents, store during rendering // todo todo, fetch in promise all settled
        async function fetchDocumentBatch() {

            //await documentList?.map(async (document) => {
            //    if (accounts.length > 0) {
            //        await instance.acquireTokenSilent({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) })
            //            .then(result => {
            //                const fetchData = async () => {
            //                    await fetch(process.env.REACT_APP_BACK_URL + '/api/Processing/GetDocument',
            //                        {
            //                            method: 'POST',
            //                            mode: 'cors',
            //                            headers: {
            //                                'Content-Type': 'application/json',
            //                                'Authorization': 'Bearer ' + result.idToken,
            //                            },
            //                            body: JSON.stringify({ "documentId": document?.id }),
            //                        })
            //                        .then((res) => res.blob())
            //                        .then((response) => {
            //                            //happens every fetch request, creates problems with rendering
            //                            setDocumentBatch(documentBatch => [...documentBatch, { response: URL.createObjectURL(response), type: response.type }]);
            //                            console.log("response type = " + response.type);
            //                            console.log(URL.createObjectURL(response));

            //                        })
            //                        .catch((error) => {
            //                            console.log(error);
            //                        })
            //                        .finally(() => { setBatchFetch(false) });
            //                }
            //                fetchData();
            //            }).catch((error) => {
            //                console.log(error) //token expired/is not valid => user is redirected to login page
            //                if (error instanceof InteractionRequiredAuthError) {
            //                    instance.acquireTokenRedirect({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) });
            //                }
            //            });
            //    }
            //});
            if (accounts.length > 0) {
                await instance.acquireTokenSilent({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) })
                    .then(result => {
                        let fetches = documentList?.map(async (document) => {
                            let response = null;
                            console.log("document batch fetch doc id - ", document?.id);
                            await fetch(process.env.REACT_APP_BACK_URL + '/api/Processing/GetDocument',
                            {
                                method: 'POST',
                                mode: 'cors',
                                headers: {
                                    'Content-Type': 'application/json',
                                     'Authorization': 'Bearer ' + result.idToken,
                                },
                                    body: JSON.stringify({ "documentId": document?.id }),
                            })
                            .then((res) => { response = res.blob() })
                            .catch((error) => {
                                console.log(error);
                            })
                            
                            return response;
                        });
                        const fetchData = async () => {
                            await Promise.allSettled(fetches)
                                .then((result) => {
                                    //result[fetchId]?.value
                                    console.log("doc batch result - ", result);
;                                    result?.map((res) => {
                                        //update document batch based on result 
                                        console.log("result from batch map = ", { response: URL.createObjectURL(res?.value), type: res?.value?.type })
                                        //setTempBatchArr(tempBatchArr => [...tempBatchArr, { response: URL.createObjectURL(res?.value), type: res?.value?.type }]);
                                        setDocumentBatch(documentBatch => [...documentBatch, { response: URL.createObjectURL(res?.value), type: res?.value?.type }])

                                    });
                                    console.log("temp batch arr - ", documentBatch);
                                    //setDocumentBatch(tempBatchArr);
                                    setIsDocSwitchDisabled(false);
                                });


                        }

                        fetchData(); //call promise.allsettled func

                    }).catch((error) => {
                        console.log(error) //token expired/is not valid => user is redirected to login page
                        if (error instanceof InteractionRequiredAuthError) {
                            instance.acquireTokenRedirect({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) });
                        }
                    });
            }
            
         
               
    

        }


        async function fetchDocument() {

            if (accounts.length > 0) {
                await instance.acquireTokenSilent({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) })
                    .then(result => {
                        const fetchData = async () => {
                            await fetch(process.env.REACT_APP_BACK_URL + '/api/Processing/GetDocument',
                                {
                                    method: 'POST',
                                    mode: 'cors',
                                    headers: {
                                        'Content-Type': 'application/json',
                                        'Authorization': 'Bearer ' + result.idToken,
                                    },
                                    body: JSON.stringify({ "documentId": documentList[0]?.id }),
                                })
                                .then((res) => res.blob())
                                .then((response) => {
                                    setCurrentDocumentBlob({ response: URL.createObjectURL(response), type: response.type });
                                    //setDocument({ response: URL.createObjectURL(response), type: response.type });
                                    console.log("response type = " + response.type);
                                    console.log(URL.createObjectURL(response));
                                    //const debounceDocBatchFetch = debounce(() => fetchDocumentBatch(), 5000, { trailing: false }); //after first document is successfully retreived, fetch entire batch.
                                    if (batchFetch === true) {
                                   
                                        //debounceDocBatchFetch();
                                        //debounce.cancel();
                                        fetchDocumentBatch();
                                    }
                                })
                                .catch((error) => {
                                    console.log(error);
                                })
                                .finally(() => { setInitDocFetch(false) });
                        }
                        fetchData();
                    }).catch((error) => {
                        console.log(error) //token expired/is not valid => user is redirected to login page
                        if (error instanceof InteractionRequiredAuthError) {
                            instance.acquireTokenRedirect({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) });
                        }
                    });
            }
        }

        if ((batchFamilyId !== null) && (batchFamilyId !== 0)) { //if batchFamilyId is null or 0, documentList and batch is not populated. No need to fetch document
         
            if ((documentBatch?.length < numDocs) && (docNum === 0) && (initDocFetch === true) && (documentList[0]?.id !== null)) { //current document id (docNum) must be zero, indicating that the list is not yet populated to trigger separate fetch for document
                //if documentbatch is empty, fetch first document to pass to document viewer component
                console.log("doc fetch called");
                //const debounceDocFetch = debounce(() => fetchDocument(), 5000, { trailing: false });
               
                    fetchDocument();
                    setIsDocSwitchDisabled(true);
                   
                
              
     
                //debounceDocFetch.cancel();
                setInitDocFetch(false);
            
            }

          
        }
        
    //}, [documentList, docNum, setDocNum]);
    }, [documentList]);

  


    useEffect(() => {

        setCurrentDocDetails(documentList[docNum]);
        if ((batchFamilyId !== null) && (batchFamilyId !== 0) && (documentBatch?.length === numDocs)) { //if batchFamilyId is null or 0, documentList and batch is not populated. No need to fetch document
            setIsDocSwitchDisabled(false);
            setCurrentDocumentBlob(documentBatch[docNum]);
          
        }

    }, [docNum, setDocNum, documentList]);

    //variables to track retrys and status of fetch requests
    const [docTypesIsSuccess, setDocTypesIsSuccess] = useState(false);
    const [relationshipTypesIsSucess, setRelationshipTypesIsSuccess] = useState(false);
    const [retryFetchCounter, setRetryFetchCounter] = useState(0);

    useEffect(() => {

        const fetchDocTypes = async () => {

            if (accounts.length > 0) {
                await instance.acquireTokenSilent({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) })
                    .then(result => {
                        let retry = 5;
                        let counter = 1;
                        let timeout = (delay) => new Promise(resolve => setTimeout(resolve, delay));
                        const fetchData = async () => {
                            while (retry > 0) {
                                    if (retry < 5) {
                                        let d = 2000 * counter;
                                        await timeout(d);
                                    }
                                    await fetch(process.env.REACT_APP_BACK_URL + '/api/Processing/GetDocuments',
                                    {
                                        method: 'GET',
                                        mode: 'cors',
                                        headers: {
                                            'Content-Type': 'application/json',
                                            'Authorization': 'Bearer ' + result.idToken,
                                        },
                                    })
                                        .then((res) => {
                                            if (res.ok) {
                                                retry = 0;
                                                return res.json();
                                            }
                                            if (retry > 1) {
                                                retry--;
                                                counter++;
                                                return;
                                            }
                                            if (retry <= 1) {
                                                let stat = "";
                                                switch (res.status) {
                                                    case 400:
                                                        stat = '400';
                                                        break;
                                                    case 401:
                                                        stat = '401';
                                                        break;
                                                    case 403:
                                                        stat = '403';
                                                        break;
                                                    case 404:
                                                        stat = '404';
                                                        break;
                                                    case 429:
                                                        stat = '429';
                                                        break;
                                                    case 500:
                                                        stat = '500';
                                                        break;
                                                    case 503:
                                                        stat = '503';
                                                        break;
                                                    case 504:
                                                        stat = '504';
                                                        break;
                                                    default:
                                                        stat = 'Unknown';
                                                        break;
                                                }

                                                toast.error(stat + " error: Please refresh and try again.", {
                                                    position: "bottom-center",
                                                    theme: "colored",
                                                    autoClose: false,
                                                    closeOnClick: true,
                                                    toastId: "failedToFetch"
                                                });
                                                retry = 0;
                                                return;
                                            }
                                          
                                        })
                                        .then((response) => {
                                            console.log('get doc types response --', response);
                                            setDocumentTypes(response);
                                            setDocTypesIsSuccess(true);

                                        })
                                        .catch((error) => {
                                            console.log(error);
                                            //setRetryFetchCounter(...retryFetchCounter + 1);
                                        });
                            }
                        }
                        fetchData();
                    }).catch((error) => {
                        console.log(error) //token expired/is not valid => user is redirected to login page
                        if (error instanceof InteractionRequiredAuthError) {
                            instance.acquireTokenRedirect({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) });
                        }
                    });
            }

        }

        const fetchRelationshipTypes = async () => {
  
            if (accounts.length > 0) {
                await instance.acquireTokenSilent({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) })
                    .then(result => {
                        let retry = 5;
                        let counter = 1;
                        let timeout = (delay) => new Promise(resolve => setTimeout(resolve, delay));
                        const fetchData = async () => {
                            while (retry > 0) {
                                if (retry < 5) {
                                    let d = 2000 * counter;
                                    await timeout(d);
                                }
                                await fetch(process.env.REACT_APP_BACK_URL + '/api/Processing/GetRelationships',
                                    {
                                        method: 'GET',
                                        mode: 'cors',
                                        headers: {
                                            'Content-Type': 'application/json',
                                            'Authorization': 'Bearer ' + result.idToken,
                                        },
                                    })
                                    .then((res) => {
                                        if (res.ok) {
                                            retry = 0;
                                            //success = true;
                                            return res.json();

                                        }
                                        if (retry > 1) {
                                            retry--;
                                            counter++;
                                        }
                                        if (retry <= 1) {
                                            let stat = "";
                                            switch (res.status) {
                                                case 400:
                                                    stat = '400';
                                                    break;
                                                case 401:
                                                    stat = '401';
                                                    break;
                                                case 403:
                                                    stat = '403';
                                                    break;
                                                case 404:
                                                    stat = '404';
                                                    break;
                                                case 429:
                                                    stat = '429';
                                                    break;
                                                case 500:
                                                    stat = '500';
                                                    break;
                                                case 503:
                                                    stat = '503';
                                                    break;
                                                case 504:
                                                    stat = '504';
                                                    break;
                                                default:
                                                    stat = 'Unknown';
                                                    break;
                                            }

                                            toast.error(stat + " error: Please refresh and try again.", {
                                                position: "bottom-center",
                                                theme: "colored",
                                                autoClose: false,
                                                closeOnClick: true,
                                                toastId: "failedToFetch"
                                            });
                                            retry = 0;
                                            return;

                                        }
                                    })
                                    .then((response) => {
                                        console.log('get relationship types response --', response);
                                        setRelationshipTypes([]);
                                        response?.relationships?.map((relationship) => {

                                            //add relationships that are currently active from 'get relationships'
                                            if (relationship?.active === 1) {
                                                setRelationshipTypes(relationshipTypes => [...relationshipTypes, relationship]);
                                            }

                                        });
                                        //setRelationshipTypes(response?.relationships);
                                        setRelationshipTypesIsSuccess(true);

                                    })
                                    .catch((error) => {
                                        console.log(error);
                                        //setRetryFetchCounter(...retryFetchCounter + 1);
                                    });
                            }
                        }
                            fetchData();
                     
                    }).catch((error) => {
                        console.log(error) //token expired/is not valid => user is redirected to login page
                        if (error instanceof InteractionRequiredAuthError) {
                            instance.acquireTokenRedirect({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) });
                        }
                    });
            }

        }

        const fetchAuditDocData = async () => {

            if (accounts.length > 0) {
                await instance.acquireTokenSilent({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) })
                    .then(result => {
                        let retry = 5;
                        let counter = 1;
                        let timeout = (delay) => new Promise(resolve => setTimeout(resolve, delay));
                        const fetchData = async () => {
                            while (retry > 0) {
                                if (retry < 5) {
                                    let d = 2000 * counter;
                                    await timeout(d);
                                }
                                await fetch(process.env.REACT_APP_BACK_URL + '/api/Processing/GetAuditDocData',
                                    {
                                        method: 'GET',
                                        mode: 'cors',
                                        headers: {
                                            'Content-Type': 'application/json',
                                            'Authorization': 'Bearer ' + result.idToken,
                                        },
                                    })
                                    .then((res) => {
                                        if (res.ok) {
                                            retry = 0;
                                            //success = true;
                                            return res.json();

                                        }
                                        if (retry > 1) {
                                            retry--;
                                            counter++;
                                        }
                                        if (retry <= 1) {
                                            let stat = "";
                                            switch (res.status) {
                                                case 400:
                                                    stat = '400';
                                                    break;
                                                case 401:
                                                    stat = '401';
                                                    break;
                                                case 403:
                                                    stat = '403';
                                                    break;
                                                case 404:
                                                    stat = '404';
                                                    break;
                                                case 429:
                                                    stat = '429';
                                                    break;
                                                case 500:
                                                    stat = '500';
                                                    break;
                                                case 503:
                                                    stat = '503';
                                                    break;
                                                case 504:
                                                    stat = '504';
                                                    break;
                                                default:
                                                    stat = 'Unknown';
                                                    break;
                                            }

                                            toast.error(stat + " error: Please refresh and try again.", {
                                                position: "bottom-center",
                                                theme: "colored",
                                                autoClose: false,
                                                closeOnClick: true,
                                                toastId: "failedToFetch"
                                            });
                                            retry = 0;
                                            return;

                                        }
                                    })
                                    .then((response) => {
                                        //console.log('get auditDocData --', response);
                                        setAuditDocData(response);

                                    })
                                    .catch((error) => {
                                        console.log(error);
                                        //setRetryFetchCounter(...retryFetchCounter + 1);
                                    });
                            }
                        }
                        fetchData();

                    }).catch((error) => {
                        console.log(error) //token expired/is not valid => user is redirected to login page
                        if (error instanceof InteractionRequiredAuthError) {
                            instance.acquireTokenRedirect({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) });
                        }
                    });
            }

        }

        if ((docTypesIsSuccess === false || relationshipTypesIsSucess === false) && retryFetchCounter < 5 && isLoading === true) { //if either fetch request fails and 
            fetchDocTypes();
            fetchRelationshipTypes();
            fetchAuditDocData();
            setIsLoading(false);
        }
        //if ((docTypesIsSuccess === false || relationshipTypesIsSucess === false) && retryFetchCounter >= 5) {
          

        //    toast.error("Unable to contact server. Please refresh and try again.", {
        //        position: "bottom-center",
        //        theme: "colored",
        //        autoClose: false,
        //        closeOnClick: true,
        //        toastId: "failedToFetch"

        //    });

           
        //}




    }, [docTypesIsSuccess, relationshipTypesIsSucess, retryFetchCounter, setDocTypesIsSuccess, setRelationshipTypesIsSuccess, setRetryFetchCounter, isLoading, setIsLoading]);


    const [changeCurrentAuditId, setChangeCurrentAuditId] = useState(false);

    useEffect(() => {

        function setData() {
            //TODO: based on choice, fetch data from api
            setDocumentList([]);
            setDocumentBatch([]);
            setFamilyDetails([]);
            setEmployeeDetails([]);
            setCurrentDocDetails([]);
            setDocumentListState([]);
            setDocumentDependentProgress([]);
            setNumDocs(0);
            setDocNum(0);
            setBatchFetch(true);
            setInitDocFetch(true);
            setCurrentAuditId();
            //setAuditDocData([]);
            console.log(choice);
            //setCurrentFamilyId();
            //console.log("family id ---- ", batch[0]?.family?.familyId);

            if(batch[0]?.family?.familyId !== null && typeof batch[0]?.family?.familyId !== "undefined" && auditDocData?.length > 0) {
                //setCurrentFamilyId(batch[0]?.family?.familyId);
                console.log("current family id ==== ", batch[0]?.family?.familyId);
                let currentFamilyIdentification = batch[0]?.family?.familyId;
                let currentAudit = currentFamilyIdentification.split("-")[0]; //get audit id (0000) associated with family from batch (getNextFamily)
                let currentFamily = currentFamilyIdentification.split("-")[1]; //get family id from batch (getNextFamily)
                let findAuditResult = auditDocData.find((audit) => audit.auditId === parseInt(currentAudit))
                setCurrentAuditId(findAuditResult?.auditAcronym + " - " + currentFamily);


            }
            batch[0]?.eligibilityDocuments?.eligibilityDocument?.map((document) => {

                // return(
                //     console.log("document - ", document)

                // );
                setDocumentListState(documentListState => [...documentListState, { id: document?.doc_Que_Id, isSubmitted: false}]);

                setDocumentList(documentList => [...documentList, document]);
                setNumDocs(numDocs => numDocs + 1);

            });

            // batch?.family?.flat((item) => {

            //     console.log("item - ", item);

            // });

            //setAuditDetails([{ batch[0]?.family?.familyId, batch[0]?.family?.auditId, batch[0]?.family?.employerId }])
            batch[0]?.family?.familyMembers?.familyMember?.map((member) => {
                setDocumentDependentProgress(documentDependentProgress => [...documentDependentProgress, { memberId: member?.memberId, indicatorStatus: 0 }]);

            });


            batch[0]?.family?.familyMembers?.familyMember?.map((member) => {

                // return(
                //     console.log("member - ", member)
                // );

                if (member?.memberRelationship.includes("D") === true) {
                    setFamilyDetails(familyDetails => [...familyDetails, member]);
                }

                if (member?.memberRelationship.includes("S") === true) {
                    setFamilyDetails(familyDetails => [...familyDetails, member]);
                }

                if ((member?.memberRelationship.includes("D") !== true && member?.memberRelationship.includes("E") !== true && member?.memberRelationship.includes("S") !== true) && member?.memberRelationship !== null && member?.memberRelationship !== "undefined") {
                    setFamilyDetails(familyDetails => [...familyDetails, member]);
                }

                if (member?.memberRelationship.includes("E") === true) {
                    setEmployeeDetails(employeeDetails => [...employeeDetails, member]);
                }

            });

            batch?.map((item) => {
                //setAudit(item?.family?.auditId);
                //setFamilyId(item?.family?.familyId);
                setFamilyDetails(familyDetails => [...familyDetails, { "auditId": item?.family?.auditId }, { "familyId": item?.family?.familyId }]);
                setEmployeeDetails(employeeDetails => [...employeeDetails, { "auditId": item?.family?.auditId }, { "familyId": item?.family?.familyId }]);
            });

        }

        setData();
        console.log("batch status ", batch[0]?.status);
       
        if (batchFamilyId === null && batch[0]?.status !== 1013) {
            let status = "";
            switch (batch[0]?.status) {
                case 1011:
                    status = "No documents available to process. Please try again later.";
                    break;
                case 1012:
                    status = "Unable to fetch next family. Please Try again.";
                    break;
                default:
                    status = "An error occurred. Please Try again.";
                    break;
            }

            toast.warn(status, {
                position: "bottom-center",
                theme: "colored",
                autoClose: false,
                closeOnClick: true,
                toastId: "batchNullResponse"

            });

        }

        console.log("documents - ", documentList);
        console.log("employees - ", employeeDetails);
        console.log("families - ", familyDetails);
        //console.log("familyID - ", familyId);
        //console.log("auditId - ", audit);


        // }, [choice, setChoice]);
        setChangeCurrentAuditId(true);

    }, [batch, setBatch]);

  

    //useEffect(() => { 
    //    if (batch[0]?.length > 0) { 
    //        if (changeCurrentAuditId === true) {
    //            if (auditDocData?.length > 0 && currentFamilyId !== null) {
    //                let currentAudit = currentFamilyId.split("-")[0]; //get audit id (0000) associated with family from batch (getNextFamily)
    //                let currentFamily = currentFamilyId.split("-")[1]; //get family id from batch (getNextFamily)
    //                let findAuditResult = auditDocData.find((audit) => audit.auditId === parseInt(currentAudit))
    //                console.log("findAuditResult - ", findAuditResult);
    //                setCurrentAuditDocData(findAuditResult); //find audit with matching id on file for family

    //            }
    //        }
    //    }

    //}, [changeCurrentAuditId, setChangeCurrentAuditId]); 


    //useEffect(() => {
    //    //console.log("hit current audit doc data ", currentAuditDocData);
    //    if (batch[0]?.length > 0) {
    //        if (currentFamilyId !== null && currentAuditDocData?.auditId !== null) {
    //            console.log("hit current audit doc data 2")
    //            let currentFamily = currentFamilyId.split("-")[1];
    //            //console.log("currentAuditDocData - ", currentAuditDocData);
    //            setCurrentAuditId(currentAuditDocData?.auditAcronym + " - " + currentFamily);
    //        }
    //    }
    //}, [currentAuditDocData, setCurrentAuditDocData]);

  
    

    //TODO: render documentviewer component when docData is not null
    return (
        <>
            {documentList && currentDocDetails && currentDocumentBlob?.response?.length > 0 && currentAuditId !== null ? (
                   
                        <DocumentViewer
                            doc={currentDocumentBlob}
                            familyDetails={familyDetails}
                            employeeDetails={employeeDetails}
                            docDetails={currentDocDetails}
                        />
             
            ) : (<></>)}
            <ToastContainer
                position="bottom-center"
                autoClose={10000}
                theme="colored"
                closeOnClick
                hideProgressBar
                pauseOnHover={false}
                newestOnTop={false}
                transition={Bounce}

            />

        </>
    );
}
