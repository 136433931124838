import React, {useContext} from 'react';

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from '@mui/material/Grid';
import { MdStar, MdStarBorder } from "react-icons/md";
import IconButton from '@mui/material/IconButton';
import Container from "@mui/material/Container";
import Tooltip from "@mui/material/Tooltip";


import { useParams, useNavigate, Navigate, Link } from "react-router-dom";

import QueryStatsIcon from '@mui/icons-material/QueryStats';
import SearchIcon from '@mui/icons-material/Search';

import { GraphDetailsContext } from '../../App';


function GraphCard({ graphName, graphComponent, graphLink, desiredCardWidth, desiredCardHeight, graphLegendComponent, graphData, graphTableData, graphLegendStyle, graphHeightPercentage }) {

    const {
        graphComponentData,
        handleUpdateGraphComponentData,
        graphTableComponentData,
        handleUpdateGraphTableComponentData,
        handleUpdateGraphLegendStyle
    } = useContext(GraphDetailsContext);

    let alertGraphName = 'Alert Report'

    if (graphName == 'Alert') {
        graphName = alertGraphName;
    }

    const navigate = useNavigate();

    let stateObj = { graphname: graphName, graphcomponent: graphComponent, graphlegend: graphLegendComponent }

    /*let stringedStateObj = JSON.stringify(stateObj)*/

    const handleNavigateToProject = (params) => {
        if (graphData !== null) {
            handleUpdateGraphComponentData(graphData) 
        }
        if (graphTableData !== null) {
            handleUpdateGraphTableComponentData(graphTableData)
        }

        if (graphLegendStyle !== null) {
            handleUpdateGraphLegendStyle(graphLegendStyle)
        }
        /* console.log(params);*/

        if (graphLink !== null) {
            navigate(graphLink, { state: {graph_title: graphName} });
        }
       
        /* navigate(`report/:id/:auditName/search/`);*/

    };

    return (
        <>
            <Card style={{ height: graphHeightPercentage != null ? (graphHeightPercentage) : ('100%') }} sx={{ mr: 0, minWidth: desiredCardWidth ? (desiredCardWidth) : (75), minHeight: desiredCardHeight ? (desiredCardHeight) : (400), height: desiredCardHeight ? (desiredCardHeight) : (400), width: desiredCardWidth ? (desiredCardWidth) : (400) }}>
               
                <CardContent>
                   
                    <Typography sx={{  }} textAlign="center" variant="h6" fontWeight="600" fontSize="1.55em" component="div">
                        {graphName == 'Alert' ? (alertGraphName) : (graphName)} {graphLink !== null ? (<>
                            <Tooltip title={"Search Report Details"}
                              placement="top">
                            <IconButton
                                    sx={{ mt: 0, bgcolor: "#EFF6FF" }}
                                onClick={() => handleNavigateToProject()}
                            >
                                    <SearchIcon />
                            </IconButton>
                            </Tooltip>
                        </>) : (<></>)}
                    </Typography>
                    {/*<div className="row-no-left-margin">*/}

                    {/*    <div className="row-right">*/}

                           


                    {/*    </div>*/}

                    {/*</div>*/}

                    {graphComponent ? (<>{graphComponent}</>) : (<></>)}
                    {graphLegendComponent ? (<div>
                        {graphLegendComponent}
                    </div>) : (<></>)}

                </CardContent>

            </Card>
        </>
    );
}

export default GraphCard;

