

import React, { useState, useContext } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { BsBoxArrowLeft } from "react-icons/bs";
import ListItem from '@mui/material/ListItem';
import MuiListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';

import Popper from "@mui/material/Popper";
import Container from "@mui/material/Container";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { MdMenu, MdDarkMode, MdLightMode } from "react-icons/md";
import { VscLayoutSidebarLeft } from "react-icons/vsc";

import bmilogo from '../assets/bmi-logo-cross-transparent.png';
import Avatar from '@mui/material/Avatar';

import { SidebarContext } from "../App";

import { LuLogOut } from "react-icons/lu";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Logout from '@mui/icons-material/Logout';

import { RxCross2 } from "react-icons/rx";

import { VscTriangleDown } from "react-icons/vsc";


import { MdSearch } from "react-icons/md";


import { BsSearch } from "react-icons/bs"

import { PiArrowLeftBold } from "react-icons/pi";

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

//from NavigationBar.jsx

import { useNavigate, Link, NavLink, useLocation, } from "react-router-dom";

import { loginRequest, b2cPolicies } from "../authConfig";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";


const ListItemButton = styled(MuiListItemButton)({
    '&.active .MuiTypography-root': {
        fontWeight: 'bold',
        color: '#1E40AF',
        backgroundColor: '#EFF6FF',
    },
});


const drawerWidth = '100VW';


const openedMixin = (theme) => ({
    width: drawerWidth,
    zIndex: 1204,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({

    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),

    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open' || prop !== 'openMobile'
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' || prop !== 'openMobile' })(
    ({ theme, open }) => ({

        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',

        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);




function AnonSidebarMobile() {

    const { instance, accounts } = useMsal();

    const {
        sidebarTitle,
        setSidebarTitle,
        sidebarActions,
        setSidebarActions,
        sidebarUserRole,
        setSidebarUserRole,
        sidebarAccountMenuAnchor,
        setSidebarAccountMenuAnchor,
        switchActiveClientData, setSwitchActiveClientData,
        clientSearchData, setClientSearchData,
        sidebarDataHasUpdate, setSidebarDataHasUpdate,
        sidebarActiveUser,
        setSidebarActiveUser,
        sidebarLogoutMode,
        setSidebarLogoutMode,
        sidebarCurrentUserTheme

    } = useContext(SidebarContext);

    const activeUserData = sidebarActiveUser;

    function getAccountBadge() {
        //const firstName = activeUserData?.given_name;
        //const lastName = activeUserData?.family_name;
        const account = accounts[0]?.idTokenClaims;

        const firstName = account?.given_name;
        const lastName = account?.family_name;
        const initials = firstName?.slice(0, 1) + lastName?.slice(0, 1);
        /* console.log("initials " + initials);*/

        return {
            sx: {
                fontSize: '14px',
                bgcolor: sidebarCurrentUserTheme,
                color: '#FFFFFF',
            },
            children: `${initials}`,
        };
    }

    //ClientSearchPopper functions
    /* console.log("Within Sidebar: ClientSearchData:", clientSearchData);*/

    const [clientSearchFilterInput, setClientSearchFilterInput] = useState('');
    const [filteredClients, setFilteredClients] = useState([])
    const [displayFileredClients, setDisplayFileredClients] = useState(false);

    function handleResetAllClientFilters() {
        setClientSearchFilterInput('');
        setFilteredClients([]);

    }

    const searchClients = (searchValue) => {
        setClientSearchFilterInput(searchValue)

        if (clientSearchFilterInput !== '') {
            const filteredData = clientSearchData.filter((item) => {
                return item.auditName.toLowerCase().includes(clientSearchFilterInput.toLowerCase())
            })
            /* console.log("FOUND in Search Filtered Clients: ", filteredData);*/
            setFilteredClients(filteredData);


        } else {
            setFilteredClients(clientSearchData)

        }

    }

    const location = useLocation();

    const currentRouteLocation = location.pathname;

    let trimmedCurrentRouteLocation = currentRouteLocation.slice(1)
    const capitalizedTrimmedCurrentRouteLocation = trimmedCurrentRouteLocation.charAt(0).toUpperCase() + trimmedCurrentRouteLocation.slice(1);
    /* console.log("currentRouteLocation,", currentRouteLocation);*/

    const [topbarLocation, setTopbarLocation] = useState("");



    const isAuthenticated = useIsAuthenticated();

    const [visible, setVisible] = useState(false);
    const [isActive, setActive] = useState(false);


    const handleLogin = () => {
        instance.loginRedirect(loginRequest).catch((error) => console.log(error))
        /*console.log(isAuthenticated);*/
    }

    const handleLogout = () => {
        setSidebarLogoutMode(true);
        instance.logoutRedirect()
    }


    // Material UI provided hooks

    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const openAccountMenu = Boolean(anchorEl);
    const handleOpenAccountMenu = (event) => {
        setAnchorEl(event.currentTarget);

    };
    const handleCloseAccountMenu = () => {
        setAnchorEl(null);

    };


    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);


    const [open, setOpen] = React.useState(false);

    const themeMobile = useTheme();
    const [openMobile, setOpenMobile] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };


    const handleDrawerOpenMobile = () => {
        setOpenMobile(true);
    };

    const handleDrawerCloseMobile = () => {
        setOpenMobile(false);
    };

    const handleDrawerToggleListItemDesktop = () => {
        setOpen(!open);
    }


    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleMyAccountClick = () => {

        navigate("/profile");

    }

    //custom NavLink component wrapper to utilize active class styling within MUI ListItem component



    const NavLinkWrapper = React.forwardRef((props, ref) => (
        <NavLink ref={ref} {...props} className={props.item?.isActive ? ('active-navLink border-bottom__thick-blue') : (" ")} tag={Link} to={props.item?.path} style={{ textDecoration: 'none' }} />
    ));

    //For SwitchClient popper

    const [switchClientAnchorEl, setSwitchClientAnchorEl] = React.useState(null);

    const handleOpenSwitchClientPopper = (event) => {
        setSwitchClientAnchorEl(switchClientAnchorEl ? null : event.currentTarget);
    };

    const handleCloseSwitchClientPopper = () => {
        setSwitchClientAnchorEl(null);
    };

    const openSwitchClientPopper = Boolean(switchClientAnchorEl);


    return (
        <>


            {/* MOBILE PERSISTENT DRAWER MUI SIDEBAR*/}
            <Box sx={{ display: { xs: "flex", md: "none" } }}>

                <AppBar position="fixed" open={openMobile} sx={{ backgroundColor: '#FFFFFF' }}>
                    <Toolbar>


                        <Avatar src={bmilogo} variant="square" sx={{
                            color: '#FFFFFF', mr: '0.25rem', ml: 0, height: '90%',
                            minWidth: 36, ...(openMobile && { display: 'none' })
                        }} />

                        {openMobile ? (<></>) : (<Divider orientation="vertical" variant="middle" flexItem sx={{ ml: '0.5rem', mr: '0.5rem' }} />)}

                        <div className="spacer-margin-left">
                            <SkeletonTheme baseColor="#EFF6FF" highlightColor="ffffff">
                                <Skeleton height={20} width={52} />
                            </SkeletonTheme>
                        </div>


                        {/*{sidebarUserRole == 'PROJMGR' && currentRouteLocation !== '/report' ? (<>*/}
                        {/*    <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon fontSize="small" />}>*/}
                        {/*        <div className="halfquarterspacer-margin-left">*/}
                        {/*            <NavLink underline="hover" color="#525252" to="/report" style={{ textDecoration: 'none', color: '#525252', fontSize: '16px', fontWeight: 700, }}>*/}
                        {/*                Clients*/}
                        {/*            </NavLink>*/}
                        {/*        </div>*/}

                        {/*        <Typography variant="h6" noWrap component="div" sx={{ fontWeight: 700, fontSize: '16px', color: '#262626', mr: 'auto', display: { xs: "flex", md: "none" } }}>*/}
                        {/*            {sidebarTitle.substring(0, 3)}*/}
                        {/*        </Typography>*/}

                        {/*    </Breadcrumbs>*/}
                        {/*    <Tooltip title="Switch Client">*/}
                        {/*        <IconButton sx={{ mt: '-0.25rem' }} type="button" onClick={handleOpenSwitchClientPopper}>*/}
                        {/*            <VscTriangleDown style={{ height: '1rem', width: '1rem' }} />*/}
                        {/*        </IconButton>*/}
                        {/*    </Tooltip>*/}
                        {/*</>) : (<></>)}*/}
                       
                       

                        <Box
                            style={{ marginLeft: "auto", position: 'sticky', marginRight: '1rem' }}
                            justifyContent="flex-end"
                            sx={{ flexGrow: 0, display: { xs: "flex", md: "none" }, mr: 1 }}>
                            <Tooltip title="Open Navigation menu">
                                <IconButton
                                    onClick={handleDrawerOpenMobile}
                                    sx={{ p: 0, color: "#525252", fontWeight: "bold", }}
                                >
                                    <MdMenu />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Toolbar>
                </AppBar>
                <Drawer
                    sx={{
                        ml: 0,
                        mr: 0,
                        width: drawerWidth,
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: drawerWidth,
                            boxSizing: 'border-box',
                        },
                    }}
                    variant="persistent"
                    PaperProps={{
                        sx: {
                            backgroundColor: '#FFFFFF'
                        }
                    }}
                    anchor="left"
                    open={openMobile}
                >
                    <DrawerHeader>
                        <Avatar src={bmilogo} variant="square" sx={{ ml: '0.25rem', mr: 'auto' }} />
                        <SkeletonTheme baseColor="#EFF6FF" highlightColor="ffffff">
                            <Skeleton circle={true} height={40} width={40} style={{marginLeft: '0', marginRight: 'auto'}} />
                        </SkeletonTheme>
                        {/*{isAuthenticated ? (<>*/}
                        {/*    <Tooltip title="Account settings">*/}
                        {/*        <IconButton*/}
                        {/*            onClick={handleOpenAccountMenu}*/}
                        {/*            size="small"*/}
                        {/*            sx={{ ml: 0, mr: 'auto' }}*/}
                        {/*            aria-controls={openAccountMenu ? 'account-menu' : undefined}*/}
                        {/*            aria-haspopup="true"*/}
                        {/*            aria-expanded={openAccountMenu ? 'true' : undefined}*/}
                        {/*        >*/}
                        {/*            {sidebarLogoutMode ? (<>*/}
                        {/*                <Avatar*/}
                        {/*                    alt=""*/}
                        {/*                    sx={{*/}
                        {/*                        fontSize: '14px',*/}
                        {/*                        bgcolor: sidebarCurrentUserTheme,*/}
                        {/*                        color: '#FFFFFF',*/}
                        {/*                    }}>*/}
                        {/*                    .*/}
                        {/*                </Avatar>*/}
                        {/*            </>) : (<>*/}
                        {/*                <Avatar {...getAccountBadge()} />*/}
                        {/*            </>)}*/}

                        {/*        </IconButton>*/}
                        {/*    </Tooltip></>) : (<></>)}*/}
                        <Box sx={{ height: '32px', width: '32px', mr: '1rem' }}>
                            <Tooltip title="Close Sidebar">
                                <IconButton onClick={handleDrawerCloseMobile} sx={{ color: '#5b5b5b' }} aria-label="Close Sidebar">
                                    <RxCross2 />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </DrawerHeader>
                    <Divider />
                    <Divider style={{ background: '#FFFFFF' }} />
                    {/*<List>*/}

                    {/*    {routes.map((item, index) => (*/}
                    {/*        <ListItem key={item?.id} disablePadding sx={{ display: 'block' }}*/}
                    {/*            component={NavLinkWrapper}*/}
                    {/*            item={item}*/}
                    {/*            activeclassname={'active-navLink border-bottom__thick-blue'}*/}
                    {/*        >*/}

                    {/*            <ListItemButton*/}
                    {/*                onClick={handleDrawerCloseMobile}*/}

                    {/*                selected={item?.isActive}*/}
                    {/*                sx={{*/}
                    {/*                    minHeight: 48,*/}
                    {/*                    justifyContent: open ? 'initial' : 'center',*/}
                    {/*                    px: 2.5,*/}
                    {/*                    "&.Mui-selected": {*/}
                    {/*                        backgroundColor: '#EFF6FF'*/}
                    {/*                    },*/}
                    {/*                    "&.Mui-focusVisible": {*/}
                    {/*                        backgroundColor: '#EFF6FF'*/}
                    {/*                    },*/}
                    {/*                    ":hover": {*/}
                    {/*                        backgroundColor: '#EFF6FF'*/}
                    {/*                    },*/}
                    {/*                    ":active": {*/}
                    {/*                        backgroundColor: '#EFF6FF'*/}
                    {/*                    }*/}
                    {/*                }}*/}
                    {/*                style={{ textDecoration: 'none' }}*/}
                    {/*            >*/}
                    {/*                <ListItemIcon*/}
                    {/*                    sx={{*/}
                    {/*                        color: item?.isActive ? '#1E40AF' : 'inherit',*/}
                    {/*                        minWidth: 0,*/}
                    {/*                        mr: openMobile ? 3 : 'auto',*/}
                    {/*                        justifyContent: 'center',*/}
                    {/*                    }}*/}
                    {/*                >*/}
                    {/*                    {item.icon}*/}
                    {/*                </ListItemIcon>*/}
                    {/*                <ListItemText primary={item.label} sx={{ color: item?.isActive ? '#1E40AF' : 'inherit', opacity: openMobile ? 1 : 0 }} style={{ textDecoration: 'none' }} />*/}
                    {/*            </ListItemButton>*/}

                    {/*        </ListItem>*/}
                    {/*    ))}*/}

                    {/*</List>*/}

                    <List
                        style={{ marginTop: 'auto' }}
                    >
                        <ListItem key={'vNumberMobile'} disablePadding sx={{ display: 'block' }}>


                            <ListItemText
                                primaryTypographyProps={{ fontSize: '12px' }}
                                primary={"Version: 1.2.0"} sx={{ ml: '1rem', fontWeight: '400', color: '#525252', justifyContent: open ? 'initial' : 'center', opacity: openMobile ? 1 : 0 }} />


                        </ListItem>

                        {/*{accountRoutes.map((item, index) => (*/}
                        {/*    <ListItem key={item} disablePadding sx={{ display: 'block' }}>*/}
                        {/*        <NavLink tag={Link} to={item.path}*/}
                        {/*            className={({ isActive }) => (isActive ? 'active-navLink' : '')}*/}
                        {/*        >*/}
                        {/*            <ListItemButton*/}
                        {/*                sx={{*/}
                        {/*                    minHeight: 48,*/}
                        {/*                    justifyContent: openMobile ? 'initial' : 'center',*/}
                        {/*                    px: 2.5,*/}
                        {/*                }}*/}
                        {/*            >*/}
                        {/*                <ListItemIcon*/}
                        {/*                    sx={{*/}
                        {/*                        color: '#ffffff',*/}
                        {/*                        minWidth: 0,*/}
                        {/*                        mr: openMobile ? 3 : 'auto',*/}
                        {/*                        justifyContent: 'center',*/}
                        {/*                    }}*/}
                        {/*                >*/}
                        {/*                    {item.icon}*/}
                        {/*                </ListItemIcon>*/}
                        {/*                <ListItemText primary={item.label} sx={{ color: '#ffffff', opacity: openMobile ? 1 : 0 }} />*/}
                        {/*            </ListItemButton>*/}
                        {/*        </NavLink>*/}
                        {/*    </ListItem>*/}
                        {/*))}*/}



                    </List>

                </Drawer>
            </Box>


           


        </>
    );
}

export default AnonSidebarMobile;