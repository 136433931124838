import React, { useState, useEffect, useContext } from 'react';

function useFilterGraph() {
    //handles calculations for graphs with the assumption that reports are complete/already filtered to match audit date selection
    const [filteredGraphData, setFilteredGraphData] = useState([]);
    const [filteredGraphCardData, setFilteredGraphCardData] = useState([]);
    //const [tempArr, setTempArr] = useState([]);

    function handleFamilyGraphFiltering(filteredFamilyReport) {
        //FamilyGraphData
        let employeeStatusCompleteTotal = 0;
        let employeeStatusIncompleteTotal = 0;
        let employeeStatusNoResponseTotal = 0;
        let employeeStatusGrandTotal = 0;
        let employeeStatusCompletePercent = 0;
        let employeeStatusIncompletePercent = 0;
        let employeeStatusNoResponsePercent = 0;

        //ResponseRateGraphData
        let responseRateNoResponseTotal = 0;
        let responseRateRespondedTotal = 0;
        let responseRateGrandTotal = 0;
        let responseRateRespondedPercent = 0;
        let responseRateNoResponsePercent = 0;

        filteredFamilyReport?.map((record) => {
            //calculate family status
            switch (record?.familyStatus) {
                case "complete":
                    employeeStatusCompleteTotal++;
                    break;
                case "incomplete":
                    employeeStatusIncompleteTotal++;
                    break;
                case "": //no response
                    employeeStatusNoResponseTotal++;
                    break;
                default:
                    break;
            }
            //calculate response rate
            switch (record?.status) {
                case "Passed":
                    responseRateRespondedTotal++;
                    break;
                case "Suspend":
                    responseRateRespondedTotal++;
                    break;
                case "Incomplete":
                    responseRateRespondedTotal++;
                    break;
                case "":
                    responseRateNoResponseTotal++;
                default:
                    break;

            }


        });

        employeeStatusGrandTotal = employeeStatusCompleteTotal + employeeStatusIncompleteTotal + employeeStatusNoResponseTotal;
        responseRateGrandTotal = responseRateNoResponseTotal + responseRateRespondedTotal;

        if (responseRateGrandTotal > 0) {
            responseRateRespondedPercent = responseRateRespondedTotal / responseRateGrandTotal;
            responseRateNoResponsePercent = responseRateNoResponseTotal / responseRateGrandTotal;
        }
        if (employeeStatusGrandTotal > 0) {
            employeeStatusCompletePercent = employeeStatusCompleteTotal / employeeStatusGrandTotal;
            employeeStatusIncompletePercent = employeeStatusIncompleteTotal / employeeStatusGrandTotal;
            employeeStatusNoResponsePercent = employeeStatusNoResponseTotal / employeeStatusGrandTotal;

        }

        /* family status */
        /* let ESGrandTotal = employeeStatusData?.employeeStatusGrandTotal;*/
        let ESCompleteTotal = employeeStatusCompleteTotal;
        let ESIncompleteTotal = employeeStatusIncompleteTotal;
        let ESNoResponseTotal = employeeStatusNoResponseTotal;
        let ESCompletePercent = Number(employeeStatusCompletePercent * 100).toFixed(2);
        let ESIncompletePercent = Number(employeeStatusIncompletePercent * 100).toFixed(2);
        let ESNoResponsePercent = Number(employeeStatusNoResponsePercent * 100).toFixed(2);

        /* response rate */
        let RRGrandTotal = responseRateGrandTotal;
        let RRNoResponsePercent = Number(responseRateNoResponsePercent * 100).toFixed(2);
        let RRNoResponseTotal = responseRateNoResponseTotal;
        let RRRespondedPercent = Number(responseRateRespondedPercent * 100).toFixed(2);
        let RRRespondedTotal = responseRateRespondedTotal;

        setFilteredGraphData([{
            employeeStatusCompleteTotal,
            employeeStatusIncompleteTotal,
            employeeStatusNoResponseTotal,
            employeeStatusGrandTotal,
            employeeStatusCompletePercent,
            employeeStatusIncompletePercent,
            employeeStatusNoResponsePercent,
        },
        {
            responseRateNoResponseTotal,
            responseRateRespondedTotal,
            responseRateGrandTotal,
            responseRateRespondedPercent,
            responseRateNoResponsePercent,
        }
        ]);

        setFilteredGraphCardData([[ //0
            {
                metricTitle: "Complete",
                metricValue: ESCompleteTotal,
                metricPercentage: ESCompletePercent,
            },
            {
                metricTitle: "No Response",
                metricValue: ESNoResponseTotal,
                metricPercentage: ESNoResponsePercent,
            },
            {
                metricTitle: "Incomplete",
                metricValue: ESIncompleteTotal,
                metricPercentage: ESIncompletePercent,
            },

        ],
        [ //1
            {
                metricTitle: "Responded",
                metricValue: RRRespondedTotal,
                metricPercentage: RRRespondedPercent,
            },
            {
                metricTitle: "No Response",
                metricValue: RRNoResponseTotal,
                metricPercentage: RRNoResponsePercent,
            },
        ],
        { //2
            ESCompleteTotal: ESCompleteTotal,
            ESIncompleteTotal: ESIncompleteTotal,
            ESNoResponseTotal: ESNoResponseTotal,
            ESCompletePercent: ESCompletePercent,
            ESIncompletePercent: ESIncompletePercent,
            ESNoResponsePercent: ESNoResponsePercent
        },
        { //3
            RRGrandTotal: RRGrandTotal,
            RRNoResponsePercent: RRNoResponsePercent,
            RRNoResponseTotal: RRNoResponseTotal,
            RRRespondedPercent: RRRespondedPercent,
            RRRespondedTotal: RRRespondedTotal,
        },

        ]);
    }

    function handleDependentGraphFiltering(filteredDependentReport) {
        /* calculating dependent statuses */
        let dependentStatusNoResponseTotal = 0;
        let dependentStatusIneligibleTotal = 0;
        let dependentStatusSuspendTotal = 0;
        let dependentStatusReviewTotal = 0;
        let dependentStatusIncompleteTotal = 0;
        let dependentStatusPassedTotal = 0;
        let dependentStatusGrandTotal = 0;

        let dependentStatusNoResponsePercent = 0;
        let dependentStatusIneligiblePercent = 0;
        let dependentStatusSuspendPercent = 0;
        let dependentStatusReviewPercent = 0;
        let dependentStatusIncompletePercent = 0;
        let dependentStatusPassedPercent = 0;

        filteredDependentReport?.map((record) => {
            switch (record?.status) {
                case "Passed":
                    dependentStatusPassedTotal++;
                    break;
                case "Suspend":
                    dependentStatusSuspendTotal++;
                    break;
                case "Incomplete":
                    dependentStatusIncompleteTotal++;
                    break;
                case "Ineligible":
                    dependentStatusIneligibleTotal++;
                    break;
                case "Review":
                    dependentStatusReviewTotal++;
                    break;
                case "":
                    dependentStatusNoResponseTotal++;
                    break;
                default:
                    break;
            }
        });

        dependentStatusGrandTotal = dependentStatusIncompleteTotal + dependentStatusIneligibleTotal + dependentStatusPassedTotal + dependentStatusReviewTotal + dependentStatusSuspendTotal + dependentStatusNoResponseTotal;
        if (dependentStatusGrandTotal > 0) {
            dependentStatusNoResponsePercent = dependentStatusNoResponseTotal / dependentStatusGrandTotal;
            dependentStatusIneligiblePercent = dependentStatusIneligibleTotal / dependentStatusGrandTotal;
            dependentStatusSuspendPercent = dependentStatusSuspendTotal / dependentStatusGrandTotal;
            dependentStatusReviewPercent = dependentStatusReviewTotal / dependentStatusGrandTotal;
            dependentStatusPassedPercent = dependentStatusPassedTotal / dependentStatusGrandTotal;
            dependentStatusIncompletePercent = dependentStatusIncompleteTotal / dependentStatusGrandTotal;
        }

        let DSGrandTotal = dependentStatusGrandTotal;
        let DSPassedTotal = dependentStatusPassedTotal;
        let DSSuspendTotal = dependentStatusSuspendTotal;
        let DSIneligibleTotal = dependentStatusIneligibleTotal;
        let DSReviewTotal = dependentStatusReviewTotal;
        let DSIncompleteTotal = dependentStatusIncompleteTotal;
        let DSNoResponseTotal = dependentStatusNoResponseTotal;


        let DSPassedPercent = Number(dependentStatusPassedPercent * 100).toFixed(2);
        let DsSuspendPercent = Number(dependentStatusSuspendPercent * 100).toFixed(2);
        let DSIneligiblePercent = Number(dependentStatusIneligiblePercent * 100).toFixed(2);
        let DSReviewPercent = Number(dependentStatusReviewPercent * 100).toFixed(2);
        let DSIncompletePercent = Number(dependentStatusIncompletePercent * 100).toFixed(2);
        let DSNoResponsePercent = Number(dependentStatusNoResponsePercent * 100).toFixed(2);

        setFilteredGraphData([{
            dependentStatusNoResponseTotal,
            dependentStatusIneligibleTotal,
            dependentStatusSuspendTotal,
            dependentStatusReviewTotal,
            dependentStatusIncompleteTotal,
            dependentStatusPassedTotal,
            dependentStatusGrandTotal,
            dependentStatusNoResponsePercent,
            dependentStatusIneligiblePercent,
            dependentStatusSuspendPercent,
            dependentStatusReviewPercent,
            dependentStatusPassedPercent,
            dependentStatusIncompletePercent

        }]);

        setFilteredGraphCardData([
            [
                {
                    metricTitle: "No Response",
                    metricValue: DSNoResponseTotal,
                    metricPercentage: DSNoResponsePercent,
                },
                {
                    metricTitle: "Incomplete",
                    metricValue: DSIncompleteTotal,
                    metricPercentage: DSIncompletePercent,
                },
                {
                    metricTitle: "Review",
                    metricValue: DSReviewTotal,
                    metricPercentage: DSReviewPercent,
                },
                {
                    metricTitle: "Passed",
                    metricValue: DSPassedTotal,
                    metricPercentage: DSPassedPercent,
                },
                {
                    metricTitle: "Suspend",
                    metricValue: DSSuspendTotal,
                    metricPercentage: DsSuspendPercent,
                },
                {
                    metricTitle: "Ineligible",
                    metricValue: DSIneligibleTotal,
                    metricPercentage: DSIneligiblePercent,
                },

            ],
            DSGrandTotal,
            DSPassedTotal,
            DSSuspendTotal,
            DSIneligibleTotal,
            DSReviewTotal,
            DSIncompleteTotal,
            DSNoResponseTotal,
            DSPassedPercent,
            DsSuspendPercent,
            DSIneligiblePercent,
            DSReviewPercent,
            DSIncompletePercent,
            DSNoResponsePercent

        ]);
    }

    function handleMemberGraphFiltering(filteredMemberReport) {
        /* calculating member statuses */
        let memberStatusNoResponseTotal = 0;
        let memberStatusIncompleteTotal = 0;
        let memberStatusReviewTotal = 0;
        let memberStatusPassedTotal = 0;
        let memberStatusSuspendTotal = 0;
        let memberStatusIneligibleTotal = 0;
        let memberStatusGrandTotal = 0;
        let memberStatusNoResponsePercent = 0;
        let memberStatusIncompletePercent = 0;
        let memberStatusReviewPercent = 0;
        let memberStatusPassedPercent = 0;
        let memberStatusSuspendPercent = 0;
        let memberStatusIneligiblePercent = 0;

        filteredMemberReport?.map((record) => {

            switch (record.status) {

                case "":
                    memberStatusNoResponseTotal++;
                    break;
                case "Incomplete":
                    memberStatusIncompleteTotal++;
                    break;
                case "Review":
                    memberStatusReviewTotal++;
                    break;
                case "Passed":
                    memberStatusPassedTotal++;
                    break;
                case "Suspend":
                    memberStatusSuspendTotal++;
                    break;
                case "Ineligible":
                    memberStatusIneligibleTotal++;
                    break;
                default:
                    break;

            }
            memberStatusGrandTotal++;

        });

        if (memberStatusGrandTotal > 0) {
            memberStatusNoResponsePercent = memberStatusNoResponseTotal / memberStatusGrandTotal;
            memberStatusIncompletePercent = memberStatusIncompleteTotal / memberStatusGrandTotal;
            memberStatusPassedPercent = memberStatusPassedTotal / memberStatusGrandTotal;
            memberStatusReviewPercent = memberStatusReviewTotal / memberStatusGrandTotal;
            memberStatusSuspendPercent = memberStatusSuspendTotal / memberStatusGrandTotal;
            memberStatusIneligiblePercent = memberStatusIneligibleTotal / memberStatusGrandTotal;

        }

        let MSGrandTotal = memberStatusGrandTotal;
        let MSPassedTotal = memberStatusPassedTotal;
        let MSSuspendTotal = memberStatusSuspendTotal;
        let MSIneligibleTotal = memberStatusIneligibleTotal;
        let MSReviewTotal = memberStatusReviewTotal;

        let MSIncompleteTotal = memberStatusIncompleteTotal;
        let MSNoResponseTotal = memberStatusNoResponseTotal;


        let MSPassedPercent = Number(memberStatusPassedPercent * 100).toFixed(2);

        let MSSuspendPercent = Number(memberStatusSuspendPercent * 100).toFixed(2);
        let MSIneligiblePercent = Number(memberStatusIneligiblePercent * 100).toFixed(2);
        let MSReviewPercent = Number(memberStatusReviewPercent * 100).toFixed(2);
        let MSIncompletePercent = Number(memberStatusIncompletePercent * 100).toFixed(2);
        let MSNoResponsePercent = Number(memberStatusNoResponsePercent * 100).toFixed(2);


        setFilteredGraphData([{
            memberStatusNoResponseTotal,
            memberStatusIncompleteTotal,
            memberStatusReviewTotal,
            memberStatusPassedTotal,
            memberStatusSuspendTotal,
            memberStatusIneligibleTotal,
            memberStatusGrandTotal,
            memberStatusNoResponsePercent,
            memberStatusIncompletePercent,
            memberStatusReviewPercent,
            memberStatusPassedPercent,
            memberStatusSuspendPercent,
            memberStatusIneligiblePercent
        }]);

        setFilteredGraphCardData([
            [
                {
                    metricTitle: "No Response",
                    metricValue: MSNoResponseTotal,
                    metricPercentage: MSNoResponsePercent,
                },
                {
                    metricTitle: "Incomplete",
                    metricValue: MSIncompleteTotal,
                    metricPercentage: MSIncompletePercent,
                },
                {
                    metricTitle: "Review",
                    metricValue: MSReviewTotal,
                    metricPercentage: MSReviewPercent,
                },
                {
                    metricTitle: "Passed",
                    metricValue: MSPassedTotal,
                    metricPercentage: MSPassedPercent,
                },
                {
                    metricTitle: "Suspend",
                    metricValue: MSSuspendTotal,
                    metricPercentage: MSSuspendPercent,
                },
                {
                    metricTitle: "Ineligible",
                    metricValue: MSIneligibleTotal,
                    metricPercentage: MSIneligiblePercent,
                },
            ],
            MSGrandTotal,
            MSPassedTotal,
            MSSuspendTotal,
            MSIneligibleTotal,
            MSReviewTotal,
            MSIncompleteTotal,
            MSNoResponseTotal,
            MSPassedPercent,
            MSSuspendPercent,
            MSIneligiblePercent,
            MSReviewPercent,
            MSIncompletePercent,
            MSNoResponsePercent,

        ]);

    }

    function handleIneligibleGraphFiltering(filteredIneligibleReport) {
        /* calculating ineligible statuses */
        console.log("filtered ineg report in func -- ", filteredIneligibleReport);
        let notEligible = 0;
        let exceedsAgeRequirements = 0;
        let relationshipNotSupportedByDoc = 0;
        let divorced = 0;
        let legalSeparation = 0;
        let notFullTimeStudent = 0;
        let military = 0;
        let employeeRequest = 0;
        let qualifiedEvent = 0;
        let eligibleForOtherCoverage = 0;
        let ineligibleForDentalEligibleForMedVision = 0;
        let ineligibleForDentalVisionEligibleForMed = 0;
        let ineligibleForMedEligibleDentalVision = 0;
        let ineligibleForMedicalDentalEligibleVision = 0;
        let ineligibleForMedVisionEligibleForDental = 0;
        let ineligibleForVisionEligibleMedicalDental = 0;
        let total = 0;

        let notEligiblePercent = 0;
        let exceedsAgeRequirementsPercent = 0;
        let relationshipNotSupportedByDocPercent = 0;
        let divorcedPercent = 0;
        let legalSeparationPercent = 0;
        let notFullTimeStudentPercent = 0;
        let militaryPercent = 0;
        let employeeRequestPercent = 0;
        let qualifiedEventPercent = 0;
        let eligibleForOtherCoveragePercent = 0;
        let ineligibleForDentalEligibleForMedVisionPercent = 0;
        let ineligibleForDentalVisionEligibleForMedPercent = 0;
        let ineligibleForMedEligibleDentalVisionPercent = 0;
        let ineligibleForMedicalDentalEligibleVisionPercent = 0;
        let ineligibleForMedVisionEligibleForDentalPercent = 0;
        let ineligibleForVisionEligibleMedicalDentalPercent = 0;
        console.log("filter ineligible report2 - ", filteredIneligibleReport)


        filteredIneligibleReport?.map((record) => {
            switch (record?.reason) {
                case "Relationship Not Eligible":
                    notEligible++;
                    break;
                case "Exceeds Age Requirements":
                    exceedsAgeRequirements++;
                    break;
                case "Relationship Not Supported by Documentation":
                    relationshipNotSupportedByDoc++;
                    break;
                case "Divorced":
                    divorced++;
                    break;
                case "Legal Separation":
                    legalSeparation++;
                    break;
                case "Not Full-Time Student":
                    notFullTimeStudent++;
                    break;
                case "Military":
                    military++;
                    break;
                case "Employee Request":
                    employeeRequest++;
                    break;
                case "Qualified Event":
                    qualifiedEvent++;
                    break;
                case "Eligible for other coverage":
                    eligibleForOtherCoverage++;
                    break;
                case "Ineligible for Medical, remains eligible for Dental and Vision":
                    ineligibleForMedEligibleDentalVision++;
                    break;
                case "Ineligible for Medical and Dental, remains eligible for Vision":
                    ineligibleForMedicalDentalEligibleVision++;
                    break;
                case "Ineligible for Medial and Vision, remains eligible for Dental":
                    ineligibleForMedVisionEligibleForDental++;
                    break;
                case "Ineligible for Dental, remains eligible for Medical and Vision":
                    ineligibleForDentalEligibleForMedVision++;
                    break;
                case "Ineligible for Dental and Vision, remains eligible for Medical":
                    ineligibleForDentalVisionEligibleForMed++;
                    break;
                case "Ineligible for Vision, remains eligible for Medical and Dental":
                    ineligibleForVisionEligibleMedicalDental++;
                    break;
                default:
                    break;
            }
            total++;
        });



        if (total > 0) {
            notEligiblePercent = notEligible / total;
            exceedsAgeRequirementsPercent = exceedsAgeRequirements / total;
            relationshipNotSupportedByDocPercent = relationshipNotSupportedByDoc / total;
            divorcedPercent = divorced / total;
            legalSeparationPercent = legalSeparation / total;
            notFullTimeStudentPercent = notFullTimeStudent / total;
            militaryPercent = military / total;
            employeeRequestPercent = employeeRequest / total;
            qualifiedEventPercent = qualifiedEvent / total;
            eligibleForOtherCoveragePercent = eligibleForOtherCoverage / total;
            ineligibleForMedEligibleDentalVisionPercent = ineligibleForMedEligibleDentalVision / total;
            ineligibleForMedicalDentalEligibleVisionPercent = ineligibleForMedicalDentalEligibleVision / total;
            ineligibleForMedVisionEligibleForDentalPercent = ineligibleForMedVisionEligibleForDental / total;
            ineligibleForDentalEligibleForMedVisionPercent = ineligibleForDentalEligibleForMedVision / total;
            ineligibleForDentalVisionEligibleForMedPercent = ineligibleForDentalVisionEligibleForMed / total;
            ineligibleForVisionEligibleMedicalDentalPercent = ineligibleForVisionEligibleMedicalDental / total;
        }
        const tempArr = [];
        /* set metric cards in ineligible list */
        if (notEligible > 0) {
            //setTempArr(tempArr => [...tempArr, {
            //    metricTitle: "Relationship Not Eligible",
            //    metricValue: notEligible,
            //    metricPercentage: Number(notEligiblePercent * 100).toFixed(2),
            //}])
            tempArr.push({
                metricTitle: "Relationship Not Eligible",
                metricValue: notEligible,
                metricPercentage: Number(notEligiblePercent * 100).toFixed(2),
            });
        }

        if (exceedsAgeRequirements > 0) {
            //setTempArr(tempArr => [...tempArr, {
            //    metricTitle: "Exceeds Age Requirements",
            //    metricValue: exceedsAgeRequirements,
            //    metricPercentage: Number(exceedsAgeRequirementsPercent * 100).toFixed(2),
            //}])
            tempArr.push({
                    metricTitle: "Exceeds Age Requirements",
                    metricValue: exceedsAgeRequirements,
                    metricPercentage: Number(exceedsAgeRequirementsPercent * 100).toFixed(2),
            });
        }

        if (relationshipNotSupportedByDoc > 0) {
            //setTempArr(tempArr => [...tempArr, {
            //    metricTitle: "Relationship Not Supported By Docs",
            //    metricValue: relationshipNotSupportedByDoc,
            //    metricPercentage: Number(relationshipNotSupportedByDocPercent * 100).toFixed(2),
            //}])
            tempArr.push({
                    metricTitle: "Relationship Not Supported By Docs",
                    metricValue: relationshipNotSupportedByDoc,
                    metricPercentage: Number(relationshipNotSupportedByDocPercent * 100).toFixed(2),
            });
        }

        if (divorced > 0) {
            //setTempArr(tempArr => [...tempArr, {
            //    metricTitle: "Divorced",
            //    metricValue: divorced,
            //    metricPercentage: Number(divorcedPercent * 100).toFixed(2),
            //}])
            tempArr.push({
                    metricTitle: "Divorced",
                    metricValue: divorced,
                    metricPercentage: Number(divorcedPercent * 100).toFixed(2),
            });
        }

        if (legalSeparation > 0) {
            //setTempArr(tempArr => [...tempArr, {
            //    metricTitle: "Legal Separation",
            //    metricValue: legalSeparation,
            //    metricPercentage: Number(legalSeparationPercent * 100).toFixed(2),
            //}])
            tempArr.push({
                    metricTitle: "Legal Separation",
                    metricValue: legalSeparation,
                    metricPercentage: Number(legalSeparationPercent * 100).toFixed(2),
            });
        }

        if (notFullTimeStudent > 0) {
            //setTempArr(tempArr => [...tempArr, {
            //    metricTitle: "Not Full-Time Student",
            //    metricValue: notFullTimeStudent,
            //    metricPercentage: Number(notFullTimeStudentPercent * 100).toFixed(2),
            //}])
            tempArr.push({
                    metricTitle: "Not Full-Time Student",
                    metricValue: notFullTimeStudent,
                    metricPercentage: Number(notFullTimeStudentPercent * 100).toFixed(2),
            });
        }

        if (military > 0) {
            //setTempArr(tempArr => [...tempArr, {
            //    metricTitle: "Military",
            //    metricValue: military,
            //    metricPercentage: Number(militaryPercent * 100).toFixed(2),
            //}])
            tempArr.push({
                   metricTitle: "Military",
                   metricValue: military,
                   metricPercentage: Number(militaryPercent * 100).toFixed(2),
            });
        }

        if (employeeRequest > 0) {
            console.log("employee request + 1");
            //setTempArr(tempArr => [...tempArr, {
            //    metricTitle: "Employee Request",
            //    metricValue: employeeRequest,
            //    metricPercentage: Number(employeeRequestPercent * 100).toFixed(2),
            //}])
            tempArr.push({
                   metricTitle: "Employee Request",
                   metricValue: employeeRequest,
                   metricPercentage: Number(employeeRequestPercent * 100).toFixed(2),
            });
        }

        if (qualifiedEvent > 0) {
            //setTempArr(tempArr => [...tempArr, {
            //    metricTitle: "Qualified Event",
            //    metricValue: qualifiedEvent,
            //    metricPercentage: Number(qualifiedEventPercent * 100).toFixed(2),
            //}])
            tempArr.push({
                metricTitle: "Qualified Event",
                metricValue: qualifiedEvent,
                metricPercentage: Number(qualifiedEventPercent * 100).toFixed(2),
            });
        }

        if (eligibleForOtherCoverage > 0) {
            //setTempArr(tempArr => [...tempArr, {
            //    metricTitle: "Eligible for Other Coverage",
            //    metricValue: eligibleForOtherCoverage,
            //    metricPercentage: Number(eligibleForOtherCoveragePercent * 100).toFixed(2),
            //}])
            tempArr.push({
                metricTitle: "Eligible for Other Coverage",
                metricValue: eligibleForOtherCoverage,
                metricPercentage: Number(eligibleForOtherCoveragePercent * 100).toFixed(2),
            });
        }

        if (ineligibleForDentalEligibleForMedVision > 0) {
            //setTempArr(tempArr => [...tempArr, {
            //    metricTitle: "Ineligible: Dental, Eligible: Med, Vision",
            //    metricValue: ineligibleForDentalEligibleForMedVision,
            //    metricPercentage: Number(ineligibleForDentalEligibleForMedVisionPercent * 100).toFixed(2),
            //}])
            tempArr.push({
                metricTitle: "Ineligible: Dental, Eligible: Med, Vision",
                metricValue: ineligibleForDentalEligibleForMedVision,
                metricPercentage: Number(ineligibleForDentalEligibleForMedVisionPercent * 100).toFixed(2),
            });
        }

        if (ineligibleForMedicalDentalEligibleVision > 0) {
            //setTempArr(tempArr => [...tempArr, {
            //    metricTitle: "Ineligible: Dental, Med, Eligible: Vision",
            //    metricValue: ineligibleForMedicalDentalEligibleVision,
            //    metricPercentage: Number(ineligibleForMedicalDentalEligibleVisionPercent * 100).toFixed(2),
            //}])
            tempArr.push({
                metricTitle: "Ineligible: Dental, Med, Eligible: Vision",
                metricValue: ineligibleForMedicalDentalEligibleVision,
                metricPercentage: Number(ineligibleForMedicalDentalEligibleVisionPercent * 100).toFixed(2),
            });
        }

        if (ineligibleForMedVisionEligibleForDental > 0) {
            //setTempArr(tempArr => [...tempArr, {
            //    metricTitle: "Ineligible: Vision, Med, Eligible: Dental",
            //    metricValue: ineligibleForMedVisionEligibleForDental,
            //    metricPercentage: Number(ineligibleForMedVisionEligibleForDentalPercent * 100).toFixed(2),
            //}])
            tempArr.push({
                metricTitle: "Ineligible: Vision, Med, Eligible: Dental",
                metricValue: ineligibleForMedVisionEligibleForDental,
                metricPercentage: Number(ineligibleForMedVisionEligibleForDentalPercent * 100).toFixed(2),
            });
        }

        if (ineligibleForMedEligibleDentalVision > 0) {
            //setTempArr(tempArr => [...tempArr, {
            //    metricTitle: "Ineligible: Med, Eligible: Dental, Vision",
            //    metricValue: ineligibleForMedEligibleDentalVision,
            //    metricPercentage: Number(ineligibleForMedEligibleDentalVisionPercent * 100).toFixed(2),
            //}])
            tempArr.push({
                metricTitle: "Ineligible: Med, Eligible: Dental, Vision",
                metricValue: ineligibleForMedEligibleDentalVision,
                metricPercentage: Number(ineligibleForMedEligibleDentalVisionPercent * 100).toFixed(2),
            });
        }

        if (ineligibleForDentalVisionEligibleForMed > 0) {
            //setTempArr(tempArr => [...tempArr, {
            //    metricTitle: "Ineligible: Vision, Dental, Eligible: Med",
            //    metricValue: ineligibleForDentalVisionEligibleForMed,
            //    metricPercentage: Number(ineligibleForDentalVisionEligibleForMedPercent * 100).toFixed(2),
            //}])
            tempArr.push({
                metricTitle: "Ineligible: Vision, Dental, Eligible: Med",
                metricValue: ineligibleForDentalVisionEligibleForMed,
                metricPercentage: Number(ineligibleForDentalVisionEligibleForMedPercent * 100).toFixed(2),
            });
        }

        if (ineligibleForVisionEligibleMedicalDental > 0) {
            //setTempArr(tempArr => [...tempArr, {
            //    metricTitle: "Ineligible: Vision, Eligible: Med, Dental",
            //    metricValue: ineligibleForVisionEligibleMedicalDental,
            //    metricPercentage: Number(ineligibleForVisionEligibleMedicalDentalPercent * 100).toFixed(2),
            //}])
            tempArr.push({
                metricTitle: "Ineligible: Vision, Eligible: Med, Dental",
                metricValue: ineligibleForVisionEligibleMedicalDental,
                metricPercentage: Number(ineligibleForVisionEligibleMedicalDentalPercent * 100).toFixed(2),
            });
        }
        //if (result[3]?.value?.ineligibleGraphDto?.total === 0) {
        //    setShowIneligibleChart(false);
        //}

        setFilteredGraphData([{
            notEligible,
            exceedsAgeRequirements,
            relationshipNotSupportedByDoc,
            divorced,
            legalSeparation,
            notFullTimeStudent,
            military,
            employeeRequest,
            qualifiedEvent,
            eligibleForOtherCoverage,
            ineligibleForDentalEligibleForMedVision,
            ineligibleForDentalVisionEligibleForMed,
            ineligibleForMedEligibleDentalVision,
            ineligibleForMedicalDentalEligibleVision,
            ineligibleForMedVisionEligibleForDental,
            ineligibleForVisionEligibleMedicalDental,
            total,
            notEligiblePercent,
            exceedsAgeRequirementsPercent,
            relationshipNotSupportedByDocPercent,
            divorcedPercent,
            legalSeparationPercent,
            notFullTimeStudentPercent,
            militaryPercent,
            employeeRequestPercent,
            qualifiedEventPercent,
            eligibleForOtherCoveragePercent,
            ineligibleForDentalEligibleForMedVisionPercent,
            ineligibleForDentalVisionEligibleForMedPercent,
            ineligibleForMedEligibleDentalVisionPercent,
            ineligibleForMedicalDentalEligibleVisionPercent,
            ineligibleForMedVisionEligibleForDentalPercent,
            ineligibleForVisionEligibleMedicalDentalPercent,
        }]);

        setFilteredGraphCardData(tempArr);
        //setTempArr([]);
    }

    function handleIncompleteGraphFiltering(filteredIncompleteReport) {

        let invalidDocumentReason = 0;
        let invalidDocPercent = 0;
        let missingDocReason = 0;
        let missingDocPercent = 0;
        let docNotLegibleReason = 0;
        let docNotLegiblePercent = 0;
        let total = 0;

        filteredIncompleteReport?.map((record) => {
            switch (record?.reason) {
                case "Invalid Documents":
                    invalidDocumentReason++;
                    break;
                case "Missing Document(s)":
                    missingDocReason++;
                    break;
                case "Document Not Legible":
                    docNotLegibleReason++;
                    break;
                default:
                    break;
            }
            total++;
        });

        if (total > 0) {
            invalidDocPercent = invalidDocumentReason / total;
            missingDocPercent = missingDocReason / total;
            docNotLegiblePercent = docNotLegibleReason / total;
        }

        let ISInvalidDocReason = invalidDocumentReason;
        let ISInvalidDocPercent = Number(invalidDocPercent * 100).toFixed(2);
        let ISMissingDocReason = missingDocReason;
        let ISMissingDocPercent = Number(missingDocPercent * 100).toFixed(2);
        let ISDocNotLegibleReason = docNotLegibleReason;
        let ISDocNotLegiblePercent = Number(docNotLegiblePercent * 100).toFixed(2);
        let ISGrandTotal = total;


        setFilteredGraphData([{
            invalidDocPercent,
            invalidDocumentReason,
            missingDocPercent,
            missingDocReason,
            docNotLegibleReason,
            docNotLegiblePercent,
            total
        }]);

        setFilteredGraphCardData([
            [
                {
                    metricTitle: "Missing Document(s)",
                    metricValue: ISMissingDocReason,
                    metricPercentage: ISMissingDocPercent
                },
                {
                    metricTitle: "Invalid Documents",
                    metricValue: ISInvalidDocReason,
                    metricPercentage: ISInvalidDocPercent
                },
                {
                    metricTitle: "Document Not Legible",
                    metricValue: ISDocNotLegibleReason,
                    metricPercentage: ISDocNotLegiblePercent
                },
            ],

            ISInvalidDocReason,
            ISInvalidDocPercent,
            ISMissingDocReason,
            ISMissingDocPercent,
            ISDocNotLegibleReason,
            ISDocNotLegiblePercent,
            ISGrandTotal

        ]);
    }

    return { filteredGraphData, filteredGraphCardData, handleFamilyGraphFiltering, handleDependentGraphFiltering, handleMemberGraphFiltering, handleIneligibleGraphFiltering, handleIncompleteGraphFiltering };
}
export default useFilterGraph;