
import React, { useContext, useState } from 'react';

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from '@mui/material/Grid';
import { MdStar, MdStarBorder } from "react-icons/md";
import IconButton from '@mui/material/IconButton';
import Container from "@mui/material/Container";
import Tooltip from "@mui/material/Tooltip";
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { format, formatISO } from 'date-fns'




import { useParams, useNavigate, Navigate, Link } from "react-router-dom";

import QueryStatsIcon from '@mui/icons-material/QueryStats';
import SearchIcon from '@mui/icons-material/Search';

import { ProjectDashboardContext } from '../../App';


function AuditEndDateMenu({ graphName, graphComponent, graphLink, desiredCardWidth, desiredCardHeight, graphLegendComponent, graphData, graphTableData }) {

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const {
  
        availableClientAuditEndDates, setAvailableClientAuditEndDates, handleUpdateAvailableClientAuditEndDates,
        activeClientAuditEndDates, setActiveClientAuditEndDates, handleUpdateActiveClientAuditEndDates
    } = useContext(ProjectDashboardContext);

    const [applySelectedDate, setApplySelectedDate] = useState(activeClientAuditEndDates.length !== 0 ? (activeClientAuditEndDates) : ([{
        auditEndDate: "12/12/1800"
    }])
       
    );


    function renderDate(date) {
        let changedDate = null;
        if (!date) {
            return '';
        }
            changedDate = format(new Date(date), 'MM/dd/yyyy');
            if (changedDate == '12/12/1800') {
                changedDate = 'ALL';
            }
        
        return changedDate;
    }

    console.log("Available Client End Dates from Menu: ", availableClientAuditEndDates);
    console.log("Active Client End Dates from Menu: ", activeClientAuditEndDates);

    const navigate = useNavigate();

    const [personName, setPersonName] = React.useState([]);

    function dateAlreadyAdded(date, list) {
        return list.some(function (el) {

            return el.auditEndDate === date;
        });
    }

    function handleDelete(value) {

        console.log("PersonName Pre DELETE", activeClientAuditEndDates);
        const newActiveList = activeClientAuditEndDates.filter((i) => i.auditEndDate !== value)

        console.log("newPersonName Post DELETE", newActiveList);
        let newMainActiveList = newActiveList;

        if (newMainActiveList?.length == 0) {
            //if new filtered list has no value, insert ALL filter value;
            const newValue = { auditEndDate: "12/12/1800" };
            newMainActiveList = [...newMainActiveList, newValue];
            handleUpdateActiveClientAuditEndDates(newMainActiveList);
            setPersonName(
                newMainActiveList
            );
        } else {
            handleUpdateActiveClientAuditEndDates(newActiveList);
            setPersonName(
                newActiveList
            );
        }

       
    };

    function handleApplyUpdate() {
        handleUpdateActiveClientAuditEndDates(applySelectedDate);
    }

    //let isAllActive = false;
    //let isNewValueUnique = true;

    const [allDateIsActive, setAllDateIsActive] = useState(false);
    const [newValueIsUnique, setNewValueIsUnique] = useState(true)

    function removeWithSet(arr) {
        let outputArray = Array.from(new Set(arr))
        return outputArray;
    }

    //Function to provide unique collection of AuditEndDates
    function filterUniqueAuditEndDates(rows) {
        const uniqueEndDates = new Set(rows);
        const filteredRows = [];

        for (const row of rows) {
            if (!uniqueEndDates.has(row.auditEndDate)) {
                uniqueEndDates.add(row.auditEndDate);
                filteredRows.push(row)
            }
        }
        return filteredRows;
    }

    //Function to provide collection of AuditEndDates without the "ALL" sorting value
    //NOT WORKING AS INTENDED

    //function filterAllSortingValueDate(rows) {
    //    const uniqueEndDates = new Set(rows);
    //    const filteredRows = [];

    //    for (const row of rows) {
    //        if (!uniqueEndDates.has("12/12/1800")) {
    //            uniqueEndDates.add(row.auditEndDate);
    //            filteredRows.push(row)
    //        }
    //    }
    //    return filteredRows;
    //}


    const handleChange = (event) => {
        const {
            target: { value },
        } = event;

        console.log("passed in value from Select: ", value);
        let lastValue = value[value.length - 1];
        console.log("last value passed in from Select", lastValue)
        const newValue = { auditEndDate: lastValue };
        /*let existingActiveList = activeClientAuditEndDates;*/
       
        var uniqValues = {}

     

        let existingActiveList = activeClientAuditEndDates;
        existingActiveList = [...existingActiveList, newValue]

    

       

        

        const uniqueDates = filterUniqueAuditEndDates(existingActiveList)

      

        console.log("removeDuplicates result: ", uniqueDates)

        existingActiveList = [newValue]

        ///SUCCESSFULY WORKING WITHOUT DUPLICATES

        //handleUpdateActiveClientAuditEndDates(uniqueDates);
        

        ///SUCCESSFULY WORKING RESTRICTED TO LAST VALUE
        setApplySelectedDate(existingActiveList);
        //handleUpdateActiveClientAuditEndDates(existingActiveList);



        
        

     
    };

   

    

    return ( 
        <>
            <div>
                <FormControl variant="standard" sx={{ m: 1, minWidth: '5VW', width: 'fit-content' }}>
                    <InputLabel id="demo-multiple-chip-label">Active Date</InputLabel>

                    <Select
                        disableUnderline
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        multiple
                        value={activeClientAuditEndDates.map((value) => value?.auditEndDate)}
                        onChange={handleChange}
                        input={null}
                        renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {applySelectedDate.map((value) => (
                                    <Chip onMouseDown={e => {
                                        e.stopPropagation();
                                    }}
                                        key={value?.auditEndDate}
                                        label={renderDate(value?.auditEndDate)}
                                       
                                    />
                                ))}
                            </Box>
                        )}
                        MenuProps={MenuProps}

                    >
                        {availableClientAuditEndDates.map((item) => (
                            <MenuItem
                                key={item?.auditEndDate}
                                value={item?.auditEndDate}

                            >
                                {renderDate(item?.auditEndDate)}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Button variant="text" sx={{ mb: '-7VH' }} onClick={() => handleApplyUpdate()}>Apply</Button>
            </div>
        </>
    

       
    );
}

export default AuditEndDateMenu;

