
import React, { useState, useEffect, PureComponent, useContext, useCallback, useRef, forwardRef, createContext } from 'react';
import { useParams, useNavigate, Navigate, Link, redirect } from "react-router-dom";
import { Bar } from '@nivo/bar';
import { ResponsivePie } from '@nivo/pie';
import { ResponsiveCalendar } from '@nivo/calendar';
import { BsCheckLg, BsXLg, BsExclamationCircleFill } from 'react-icons/bs';
import bmilogo from '../assets/bmi-logo-cross-transparent.png';
import Avatar from '@mui/material/Avatar';
import { RxCross2 } from "react-icons/rx";
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';

import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro';
/*import { DataGrid, useGridApiRef } from '@mui/x-data-grid';*/
import Select from '@mui/material/Select';

import MenuItem from "@mui/material/MenuItem";
import { BsBoxArrowLeft } from "react-icons/bs";
import ListItem from '@mui/material/ListItem';
import MuiListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import TextField from "@mui/material/TextField";
import { debounce } from "@mui/material/utils";
import InputAdornment from '@mui/material/InputAdornment';
import { MdSearch } from "react-icons/md";
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

import Chip from '@mui/material/Chip';
import { format, formatISO } from 'date-fns'
import { IoMdArrowDropright, IoMdArrowDropdown } from "react-icons/io";

import Box from '@mui/material/Box';
import Container from "@mui/material/Container";
import Typography from '@mui/material/Typography';
import Grid from "@mui/material/Grid";
import ProjectCard from "../components/cards/ProjectCard";
import ProjectTable from "../components/tables/ProjectTable";
import MultiBarChart from "../components/graphs/MultiBarChart";
import Tooltip from "@mui/material/Tooltip";
import GraphCard from "../components/cards/GraphCard";
import PieGraph from "../components/graphs/PieGraph";
import IneligibleReasonBarChart from "../components/graphs/IneligibleReasonBarChart";
import IneligibleExtendedGraphCard from "../components/cards/IneligibleExtendedGraphCard";
import { RolesContext, SidebarContext, PersistentPreviousSearchContext } from "../App";
import GraphLegendCard from '../components/cards/GraphLegendCard';
import PercentageBarChart from "../components/graphs/PercentageBarChart";
import HomeIcon from '@mui/icons-material/Home';
import BarChartIcon from '@mui/icons-material/BarChart';
import GroupIcon from '@mui/icons-material/Group';
import { RoutesContext, PermissionsContext } from "../App";
import Button from '@mui/material/Button';
import { ExportToCsv } from 'export-to-csv';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { loginRequest, b2cPolicies } from "../authConfig";
import { useMsal, MsalAuthenticationTemplate, useIsAuthenticated } from '@azure/msal-react';
import { InteractionType, InteractionStatus, InteractionRequiredAuthError } from '@azure/msal-browser';
import useFetch from "../hooks/useFetch";
import Badge from '@mui/material/Badge';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';

import IconButton from '@mui/material/IconButton';

import { MdFilterList } from "react-icons/md";

import { styled, useTheme } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';


import Menu from '@mui/material/Menu';


import { CgOptions } from "react-icons/cg";
import { IoOptionsOutline } from "react-icons/io5";

import Switch from "@mui/material/Switch";

import FormGroup from "@mui/material/FormGroup";

import FormControlLabel from "@mui/material/FormControlLabel";

import FormLabel from "@mui/material/FormLabel";



import { DateField } from '@mui/x-date-pickers/DateField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionActions from '@mui/material/AccordionActions';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';

import SearchFiltersMobile from "../components/modals/SearchFiltersMobile";
import EmployeeSearchTable from '../components/tables/EmployeeSearchTable';

import EmployeeSearchTableMobile from '../components/tables/EmployeeSearchTableMobile';
import SearchIcon from '@mui/icons-material/Search';

export const EmployeeSearchContext = createContext();


//Employee Search page
function EmployeeSearchLocal({ onChange, children }) {

    const { userPersistentPreviousSearchLocal,
        setUserPersistentPreviousSearchLocal,
        handleUpdatePersistentPreviousSearchParameters
    } = useContext(PersistentPreviousSearchContext);

    const StyledGridOverlay = styled('div')(({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        '& .ant-empty-img-1': {
            fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
        },
        '& .ant-empty-img-2': {
            fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
        },
        '& .ant-empty-img-3': {
            fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
        },
        '& .ant-empty-img-4': {
            fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
        },
        '& .ant-empty-img-5': {
            fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
            fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
        },
    }));

    function CustomNoRowsOverlay() {
        return (
            <StyledGridOverlay>

                <Box sx={{ mt: 1, fontWeight: 'bold' }}>Employee Search Report</Box>
                <Box sx={{ mt: 1 }}>Use the form above to generate a report</Box>
            </StyledGridOverlay>
        );
    }

    function CustomNoResultsOverlay() {
        return (
            <StyledGridOverlay>

                <Box sx={{ mt: 1, fontWeight: 'bold' }}>Sorry, that search did not yield any results.</Box>
                <Box sx={{ mt: 1 }}>Please try again with different Search Parameters</Box>
            </StyledGridOverlay>
        );
    }

    const BootstrapDialog = styled(Dialog)(({ theme }) => ({
        '& .MuiDialogContent-root': {
            padding: theme.spacing(2),
        },
        '& .MuiDialogActions-root': {
            padding: theme.spacing(1),
        },
    }));

    function BootstrapDialogTitle(props) {
        const { children, onClose, ...other } = props;

        return (
            <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
                {children}
                {onClose ? (
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </DialogTitle>
        );
    }

    BootstrapDialogTitle.propTypes = {
        children: PropTypes.node,
        onClose: PropTypes.func.isRequired,
    };


    function onAccordionClick(e) {
        e.stopPropagation();

    }

    function renderDate(date) {
        if (!date) {
            return '';
        }
        return format(new Date(date), 'MM/dd/yyyy');
    }

    function convertUserProvidedDate(date) {
        if (!date) {
            return '';
        }
        return formatISO(new Date(date), { representation: 'date' })
    }

    const dataGridColumns = [
        {
            "field": "audit_family_id",
            "headerName": "Audit Family",
            "width": 90
        },
        {
            "field": "first_name",
            "headerName": "First Name",
            "width": 120,
            "editable": false
        },
        {
            "field": "last_name",
            "headerName": "Last Name",
            "width": 150,
            "editable": false
        },
        {
            "field": "relationship",
            "headerName": "Relationship",
            "width": 150,
            "editable": false
        },
        {
            "field": "date_of_birth",
            "headerName": "DOB",
            "width": 180,
            "editable": false,
            renderCell: (params) =>
                renderDate(params.row?.date_of_birth)
        },
        {
            "field": "address",
            "headerName": "Address",
            "width": 450,
            "editable": false
        },
        {
            "field": "email",
            "headerName": "Email",
            "width": 250,
            "editable": false
        },
        {
            "field": "audit_end_date",
            "headerName": "End Date",
            "width": 150,
            "editable": false,
            renderCell: (params) =>
                renderDate(params.row?.audit_end_date)

        },
        {
            "field": "other",
            "headerName": "_",
            "width": 260,
            valueGetter: (value, row) => {

                return JSON.stringify({
                    dependent_first_names: row.dependent_data.map((item) => item.dependent_first_name),
                    dependent_last_names: row.dependent_data.map((item) => item.dependent_last_name),
                    dependent_dates_of_birth: row.dependent_data.map((item) => item.dependent_date_of_birth),
                    dependent_relationships: row.dependent_data.map((item) => item.dependent_relationship),

                });
            },
        },
    ];

    const dataGridColumnsMediumOrSmall = [
        {
            "field": "audit_family_id",
            "headerName": "Audit Family",
            "width": 90
        },
        {
            "field": "first_name",
            "headerName": "First Name",
            "width": 120,
            "editable": false
        },
        {
            "field": "last_name",
            "headerName": "Last Name",
            "width": 150,
            "editable": false
        },
        {
            "field": "relationship",
            "headerName": "Relationship",
            "width": 100,
            "editable": false
        },
        {
            "field": "date_of_birth",
            "headerName": "DOB",
            "width": 160,
            "editable": false,
            renderCell: (params) =>
                renderDate(params.row?.date_of_birth)
        },
        {
            "field": "address",
            "headerName": "Address",
            "width": 450,
            "editable": false
        },
        {
            "field": "email",
            "headerName": "Email",
            "width": 230,
            "editable": false
        },
        {
            "field": "audit_end_date",
            "headerName": "End Date",
            "width": 150,
            "editable": false,
            renderCell: (params) =>
                renderDate(params.row?.audit_end_date)

        },
        {
            "field": "other",
            "headerName": "_",
            "width": 260,
            valueGetter: (value, row) => {

                return JSON.stringify({
                    dependent_first_names: row.dependent_data.map((item) => item.dependent_first_name),
                    dependent_last_names: row.dependent_data.map((item) => item.dependent_last_name),
                    dependent_dates_of_birth: row.dependent_data.map((item) => item.dependent_date_of_birth),
                    dependent_relationships: row.dependent_data.map((item) => item.dependent_relationship),

                });
            },
        },
    ];

    /* const dataGridRows = [{}]*/

    //const dataGridColumns = [{
    //    "field": "id",
    //    "hide": true
    //},
    //    {
    //        "field": "desk",
    //        "headerName": "Desk",
    //        "width": 110
    //    },
    //    {
    //        "field": "commodity",
    //        "headerName": "Commodity",
    //        "width": 180,
    //        "editable": false
    //    },
    //    {
    //        "field": "traderName",
    //        "headerName": "Trader Name",
    //        "width": 120,
    //        "editable": false
    //    },
    //    {
    //        "field": "traderEmail",
    //        "headerName": "Trader Email",
    //        "width": 150,
    //        "editable": false
    //    },
    //    {
    //        "field": "quantity",
    //        "headerName": "Quantity",
    //        "type": "number",
    //        "width": 140,
    //        "editable": false
    //    }];






    const [paginationModel, setPaginationModel] = React.useState({
        page: 0,
        pageSize: 10,
    });

    const apiRef = useGridApiRef();

    

    //const updateSearchSelectFilterValue = (newValue) => {
    //    console.log("newValue: ", newValue)
    //    if (newValue !== "") {
    //        apiRef.current.setFilterModel({
    //            items: [
    //                {
    //                    id: 1,
    //                    operator: 'contains',
    //                    field: "client",
    //                    value: newValue,
    //                },
    //            ],
    //        });
    //    }

    //};



    //const handleSelectFilterChange = (event) => {
    //    const newValue = event.target.value;
    //    setSelectFilterValue(newValue);
    //    updateSearchSelectFilterValue(newValue);

    //};

 

    const [previousSearchParameters, setPreviousSearchParameters] = useState(userPersistentPreviousSearchLocal?.length !== 0 ? userPersistentPreviousSearchLocal : []);

    function handleUpdatePreviousSearchParameters(updatedPreviousSearchParameters) {
        setPreviousSearchParameters(updatedPreviousSearchParameters);
    }

    const [previousSearchLogicalOperatorParameter, setPreviousSearchLogicalOperatorParameter] = useState("");

    function handleUpdatePreviousSearchLogicalOperatorParameter(updatedPreviousSearchLogicalOperatorParameter) {
        setPreviousSearchLogicalOperatorParameter(updatedPreviousSearchLogicalOperatorParameter);

    }

    const [employeeOnlySearch, setEmployeeOnlySearch] = React.useState(false);

    function handleUpdateEmployeeOnlySearch(newStatus) {
        setEmployeeOnlySearch(newStatus)
    }

    const [previousSearchWasEmployeeOnly, setPreviousSearchWasEmployeeOnly] = useState(false);

    function handleUpdatePreviousSearchWasEmployeeOnly(updatedWasEmployeeOnlyStatus) {

        setPreviousSearchWasEmployeeOnly(updatedWasEmployeeOnlyStatus);
    }




    const [mainSearchFilterModel, setMainSearchFilterModel] = React.useState([]);
    const [mainSearchFilterModelLogicalOperator, setMainSearchFilterModelLogicalOperator] = React.useState("");

    const [searchFirstNameExactMatch, setSearchFirstNameExactMatch] = React.useState(false);

    function handleUpdateSearchFirstNameExactMatch(newFirstExactMatchStatus) {
        setSearchFirstNameExactMatch(newFirstExactMatchStatus);
    }

    const [searchLastNameExactMatch, setSearchLastNameExactMatch] = React.useState(false);

    function handleUpdateSearchLastNameExactMatch(newLastExactMatchStatus) {
        setSearchLastNameExactMatch(newLastExactMatchStatus);
    }

    const [generateNewSearchFilterModel, setGenerateNewSearchFilterModel] = React.useState(false);

    const [searchValueAuditID, setSearchValueAuditID] = React.useState("");
    const [savedLastSearchValueAuditID, setSavedLastSearchValueAuditID] = React.useState("");

    function handleSearchValueAuditIDChange(event) {
        event.preventDefault();
        setSearchValueAuditID(event.target.value);

        //if (generateNewSearchFilterModel === true) {
        //    const newFilterParameterItem = {
        //        FieldType: "audit_id",
        //        SearchValue: searchValueAuditID,
        //        OperatorValue: 'contains'
        //    };


        //    handleUpdateMainSearchFilterModel(newFilterParameterItem);

        //}

    }


    const [searchValueClient, setSearchValueClient] = React.useState("");

    function handleSearchValueClientChange(event) {

        setSearchValueClient(event.target.value);

        //if (generateNewSearchFilterModel == true) {
        //    const newFilterParameterItem = {
        //        FieldType: "client",
        //        SearchValue: searchValueClient,
        //        OperatorValue: 'contains'
        //    };


        //    handleUpdateMainSearchFilterModel(newFilterParameterItem);
        //}
    }

    const [searchValueClientExactMatch, setSearchValueClientExactMatch] = React.useState(false);

    function handleUpdateSearchValueClientExactMatch(newExactMatchStatus) {
        setSearchValueClientExactMatch(newExactMatchStatus);
    }


    const [searchValueFirstName, setSearchValueFirstName] = React.useState("");

    function handleSearchValueFirstNameChange(event) {
        setSearchValueFirstName(event.target.value);
        //if (generateNewSearchFilterModel === true) {
        //    const newFilterParameterItem = {
        //        FieldType: "first_name",
        //        SearchValue: searchValueFirstName,
        //        OperatorValue: 'contains'
        //    };


        //    handleUpdateMainSearchFilterModel(newFilterParameterItem);
        //}
    }


    const [searchValueLastName, setSearchValueLastName] = React.useState("");

    function handleSearchValueLastNameChange(event) {

        setSearchValueLastName(event.target.value);

        //if (generateNewSearchFilterModel === true) {
        //    const newFilterParameterItem = {
        //        FieldType: "last_name",
        //        SearchValue: searchValueLastName,
        //        OperatorValue: 'contains'
        //    };


        //    handleUpdateMainSearchFilterModel(newFilterParameterItem);
        //}
    }


    const [searchValueDOB, setSearchValueDOB] = React.useState(null);

    function handleSearchValueDOBChange(value) {
        /* console.log("DOB CHANGE ------", event.target?.value)*/
        if (value !== null) {
            setSearchValueDOB(value);
        }


        //if (generateNewSearchFilterModel === true) {
        //    const newFilterParameterItem = {
        //        FieldType: "date_of_birth",
        //        SearchValue: searchValueDOB,
        //        OperatorValue: 'contains'
        //    };


        //    handleUpdateMainSearchFilterModel(newFilterParameterItem);
        //}
    }


    const [searchValueEmailAddress, setSearchValueEmailAddress] = React.useState("");


    function handleSearchValueEmailAddressChange(event) {

        setSearchValueEmailAddress(event.target.value);

        //if (generateNewSearchFilterModel === true) {
        //    const newFilterParameterItem = {
        //        FieldType: 'email',
        //        SearchValue: searchValueEmailAddress,
        //        OperatorValue: 'contains'
        //    };


        //    handleUpdateMainSearchFilterModel(newFilterParameterItem);
        //}
    }

    function handleGetAndSetAllFiltersForUpdate() {



        apiRef.current.setFilterModel({
            items: []

        });

        let newMainSearchFilterModel = [];
        let newLogicalSearchOperator = null;


        if (searchValueAuditID !== "") {
            let i = newMainSearchFilterModel?.length;
            let newItemID = i + 1;

            const newSearchFilterModelItem = {
                id: newItemID,
                field: "audit_family_id",
                value: searchValueAuditID,
                operator: 'equals'
            };
            newMainSearchFilterModel = [...newMainSearchFilterModel, newSearchFilterModelItem];
        }
        if (searchValueClient !== "") {
            if (searchValueClientExactMatch === true) {
                let i = newMainSearchFilterModel?.length;
                let newItemID = i + 1;

                const newSearchFilterModelItem = {
                    id: newItemID,
                    field: "client",
                    value: searchValueClient,
                    operator: 'equals'
                };
                newMainSearchFilterModel = [...newMainSearchFilterModel, newSearchFilterModelItem];

                if (employeeOnlySearch !== true) {

                    let j = newMainSearchFilterModel?.length;
                    let newOtherItemID = j + 1;

                    const newSearchFilterModelItemOther = {
                        id: newOtherItemID,
                        field: "other",
                        value: searchValueClient,
                        operator: 'equals'
                    };
                    newMainSearchFilterModel = [...newMainSearchFilterModel, newSearchFilterModelItemOther];
                    newLogicalSearchOperator = "or";
                }
            } else {
                let i = newMainSearchFilterModel?.length;
                let newItemID = i + 1;

                const newSearchFilterModelItem = {
                    id: newItemID,
                    field: "client",
                    value: searchValueClient,
                    operator: 'contains'
                };
                newMainSearchFilterModel = [...newMainSearchFilterModel, newSearchFilterModelItem];

                if (employeeOnlySearch !== true) {

                    let j = newMainSearchFilterModel?.length;
                    let newOtherItemID = j + 1;

                    const newSearchFilterModelItemOther = {
                        id: newOtherItemID,
                        field: "other",
                        value: searchValueClient,
                        operator: 'contains'
                    };
                    newMainSearchFilterModel = [...newMainSearchFilterModel, newSearchFilterModelItemOther];
                    newLogicalSearchOperator = "or";
                }
            }

        }

        if (searchValueFirstName !== "") {
            if (searchFirstNameExactMatch === true) {
                let i = newMainSearchFilterModel?.length;
                let newItemID = i + 1;

                const newSearchFilterModelItem = {
                    id: newItemID,
                    field: "first_name",
                    value: searchValueFirstName,
                    operator: 'equals'
                };
                newMainSearchFilterModel = [...newMainSearchFilterModel, newSearchFilterModelItem];

                if (employeeOnlySearch !== true) {

                    let j = newMainSearchFilterModel?.length;
                    let newOtherItemID = j + 1;

                    const newSearchFilterModelItemOther = {
                        id: newOtherItemID,
                        field: "other",
                        value: searchValueFirstName,
                        operator: 'equals'
                    };
                    newMainSearchFilterModel = [...newMainSearchFilterModel, newSearchFilterModelItemOther];
                    newLogicalSearchOperator = "or";

                }
            } else {
                let i = newMainSearchFilterModel?.length;
                let newItemID = i + 1;

                const newSearchFilterModelItem = {
                    id: newItemID,
                    field: "first_name",
                    value: searchValueFirstName,
                    operator: 'contains'
                };
                newMainSearchFilterModel = [...newMainSearchFilterModel, newSearchFilterModelItem];

                if (employeeOnlySearch !== true) {

                    let j = newMainSearchFilterModel?.length;
                    let newOtherItemID = j + 1;

                    const newSearchFilterModelItemOther = {
                        id: newOtherItemID,
                        field: "other",
                        value: searchValueFirstName,
                        operator: 'contains'
                    };
                    newMainSearchFilterModel = [...newMainSearchFilterModel, newSearchFilterModelItemOther];
                    newLogicalSearchOperator = "or";

                }
            }


        }
        if (searchValueLastName !== "") {
            if (searchLastNameExactMatch === true) {
                let i = newMainSearchFilterModel?.length;
                let newItemID = i + 1;

                const newSearchFilterModelItem = {
                    id: newItemID,
                    field: "last_name",
                    value: searchValueLastName,
                    operator: 'equals'
                };
                newMainSearchFilterModel = [...newMainSearchFilterModel, newSearchFilterModelItem];

                if (employeeOnlySearch !== true) {

                    let j = newMainSearchFilterModel?.length;
                    let newOtherItemID = j + 1;

                    const newSearchFilterModelItemOther = {
                        id: newOtherItemID,
                        field: "other",
                        value: searchValueLastName,
                        operator: 'equals'
                    };
                    newMainSearchFilterModel = [...newMainSearchFilterModel, newSearchFilterModelItemOther];
                    newLogicalSearchOperator = "or";
                }
            } else {
                let i = newMainSearchFilterModel?.length;
                let newItemID = i + 1;

                const newSearchFilterModelItem = {
                    id: newItemID,
                    field: "last_name",
                    value: searchValueLastName,
                    operator: 'contains'
                };
                newMainSearchFilterModel = [...newMainSearchFilterModel, newSearchFilterModelItem];

                if (employeeOnlySearch !== true) {

                    let j = newMainSearchFilterModel?.length;
                    let newOtherItemID = j + 1;

                    const newSearchFilterModelItemOther = {
                        id: newOtherItemID,
                        field: "other",
                        value: searchValueLastName,
                        operator: 'contains'
                    };
                    newMainSearchFilterModel = [...newMainSearchFilterModel, newSearchFilterModelItemOther];
                    newLogicalSearchOperator = "or";
                }
            }

        }
        if (searchValueDOB !== null) {
            let i = newMainSearchFilterModel?.length;
            let newItemID = i + 1;

            /*let convertedForSearchFilterDate = searchValueDOB;*/
            let convertedForSearchFilterDate = convertUserProvidedDate(searchValueDOB)
            console.log("ConvertedSearchDate: ", convertedForSearchFilterDate);

            const newSearchFilterModelItem = {
                id: newItemID,
                field: "date_of_birth",
                value: convertedForSearchFilterDate,
                operator: 'contains'
            };
            newMainSearchFilterModel = [...newMainSearchFilterModel, newSearchFilterModelItem];

            if (employeeOnlySearch !== true) {

                let j = newMainSearchFilterModel?.length;
                let newOtherItemID = j + 1;

                const newSearchFilterModelItemOther = {
                    id: newOtherItemID,
                    field: "other",
                    value: convertedForSearchFilterDate,
                    operator: 'contains'
                };
                newMainSearchFilterModel = [...newMainSearchFilterModel, newSearchFilterModelItemOther];
                newLogicalSearchOperator = "or";
            }
        }
        if (searchValueEmailAddress !== "") {
            let i = newMainSearchFilterModel?.length;
            let newItemID = i + 1;

            const newSearchFilterModelItem = {
                id: newItemID,
                field: 'email',
                value: searchValueEmailAddress,
                operator: 'equals'
            };
            newMainSearchFilterModel = [...newMainSearchFilterModel, newSearchFilterModelItem];
        }

        handleUpdateMainSearchFilterModel(newMainSearchFilterModel, newLogicalSearchOperator)





    }

    function handleClearSearchAndBackUpPreviousFilterModel() {
        console.log("CLEAR SEARCH AND BACK UP");
        //backup relevant values
        if (mainSearchFilterModel !== []) {
            setUserPersistentPreviousSearchLocal(mainSearchFilterModel)
            handleUpdatePersistentPreviousSearchParameters(mainSearchFilterModel)
            handleUpdatePreviousSearchParameters(mainSearchFilterModel)
            if (mainSearchFilterModelLogicalOperator !== null) {
                handleUpdatePreviousSearchLogicalOperatorParameter(mainSearchFilterModelLogicalOperator)
                console.log("BACKING UP PREV SEARCH LOGICAL OPERATOR:", previousSearchLogicalOperatorParameter);
            }
            console.log("BACKING UP PREV SEARCH MODEL:", previousSearchParameters);



        }
        //then clear out the existing
        handleUpdateMainSearchFilterModel(null);
    }

    function handleUpdateMainSearchFilterModel(newMainSearchFilterModel, newLogicalOperator) {

        setOpenMobile(false)

        if (newMainSearchFilterModel !== null) {
            //let newMainSearchFilterModel = mainSearchFilterModel;


            //let i = newMainSearchFilterModel?.length;
            //let newItemID = i + 1;

            //let newParameterFieldType = newFilterParameter?.FieldType;
            //let newParameterSearchValue = newFilterParameter?.SearchValue;
            //let newParameterOperatorValue = newFilterParameter?.OperatorValue;

            //let newSearchFilterModelItem = {
            //    id: newItemID,
            //    operator: newParameterOperatorValue,
            //    field: newParameterFieldType,
            //    value: newParameterSearchValue,
            //}
            if (newLogicalOperator !== null) {
                setMainSearchFilterModelLogicalOperator(newLogicalOperator)
                setMainSearchFilterModel(newMainSearchFilterModel);
                console.log("SETTING NEW APIREF - WITH LOGICAL OPERATOR: ", newMainSearchFilterModel)
                apiRef.current.setFilterModel({
                    items: newMainSearchFilterModel,
                    logicOperator: newLogicalOperator

                });
            } else {
                setMainSearchFilterModel(newMainSearchFilterModel);
                console.log("SETTING NEW APIREF: ", newMainSearchFilterModel)
                apiRef.current.setFilterModel({
                    items: newMainSearchFilterModel

                });
            }
            /////
            //if (newMainSearchFilterModel !== []) {
            //    handleUpdatePreviousSearchParameters(newMainSearchFilterModel)
            //    if (newLogicalOperator !== null) {
            //        handleUpdatePreviousSearchLogicalOperatorParameter(newLogicalOperator)
            //    }

            //}

            //newMainSearchFilterModel = [...newMainSearchFilterModel, newSearchFilterModelItem];
            //if (generateNewSearchFilterModel == true) {


        } else if (newMainSearchFilterModel == null) {
            setMainSearchFilterModel([]);
            apiRef.current.setFilterModel({
                items: []

            });
            console.log("SETTING NEW APIREF NULL: ", null)
            setSearchValueAuditID("")
            setSearchValueClient("")
            setSearchValueFirstName("")
            setSearchValueLastName("")
            setSearchValueDOB(null)
            setSearchValueEmailAddress("")



            setGenerateNewSearchFilterModel(false)
        }
        //console.log("PREV SEARCH MODEL:", previousSearchParameters);
        //console.log("PREV SEARCH LOGICAL OPERATOR:", previousSearchLogicalOperatorParameter);
    }




    function handleGenerateNewMainSearchFilterModel() {
        /* setGenerateNewSearchFilterModel(true)*/
        setMainSearchFilterModel([])
        apiRef.current.setFilterModel({
            items: []

        });



        handleGetAndSetAllFiltersForUpdate()
    }

    function handleGeneratePreviousMainSearchFilterModel() {

        setOpenMobile(false)

        apiRef.current.setFilterModel({
            items: []

        });
        if (previousSearchParameters.length !== 0) {

            if (previousSearchLogicalOperatorParameter !== "") {
                console.log("SETTING APIREF W/ PREV SEARCH PARAMS & OPERATOR: ", previousSearchParameters, previousSearchLogicalOperatorParameter)
                apiRef.current.setFilterModel({
                    items: previousSearchParameters,
                    logicOperator: previousSearchLogicalOperatorParameter

                });
            } else {
                console.log("SETTING APIREF W/ JUST PREV SEARCH PARAMS: ", previousSearchParameters)
                apiRef.current.setFilterModel({
                    items: previousSearchParameters

                });
            }


        }



    }

    const handleGoToPage1 = () => apiRef.current.setPage(1);

    //function CustomMobileInputAuditID(value, onChange) {
    //    return (
    //        <TextField

    //            label="Audit ID"
    //            variant="outlined"
    //            value={value}
    //            onChange={onChange}

    //            sx={{ ml: 3, mr: '1VW', mt: 1, width: '88VW', bgcolor: '#FFFFFF' }}
    //        />)
    //};

    ////

    //interface InputElement  {
    //    focus(): void;
    //    value?: string;
    //}


    //const NumericFormatCustom = React.forwardRef(
    //    function BirthDateFormatCustom(props, ref) {
    //        const { onChange, ...other } = props;

    //        return (

    //        );
    //    },
    //);

    //function DateInput(props) {
    //    return (
    //        <InputMask
    //            mask='99-99-9999'
    //            placeholder='MM/DD/YYYY'
    //            value={props.value}
    //            onChange={props.onChange}>
    //        </InputMask>
    //    );
    //}



    ////

    const themeMobile = useTheme();
    const [openMobile, setOpenMobile] = React.useState(false);

    const handleDrawerOpenMobile = () => {
        setOpenMobile(true);
    };

    const handleDrawerCloseMobile = () => {
        setOpenMobile(false);
    };

    const drawerWidth = '100VW';


    const openedMixin = (theme) => ({
        width: drawerWidth,
        zIndex: 1204,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        overflowX: 'hidden',
    });

    const closedMixin = (theme) => ({

        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: `calc(${theme.spacing(7)} + 1px)`,
        [theme.breakpoints.up('sm')]: {
            width: `calc(${theme.spacing(8)} + 1px)`,
        },
    });

    const DrawerHeader = styled('div')(({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),

        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    }));

    const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' || prop !== 'openMobile' })(
        ({ theme, open }) => ({

            width: drawerWidth,
            flexShrink: 0,
            whiteSpace: 'nowrap',
            boxSizing: 'border-box',

            ...(open && {
                ...openedMixin(theme),
                '& .MuiDrawer-paper': openedMixin(theme),
            }),
            ...(!open && {
                ...closedMixin(theme),
                '& .MuiDrawer-paper': closedMixin(theme),
            }),
        }),
    );

    const [responseRateData, setResponseRateData] = useState()
    const [employeeStatusData, setEmployeeStatusData] = useState()
    const [dependentStatusData, setDependentStatusData] = useState()
    const [ineligibleStatusData, setIneligibleStatusData] = useState()
    const [incompleteStatusData, setIncompleteStatusData] = useState()

    const { id, auditName } = useParams();

    const {
        setSidebarTitle,
        setSidebarActions,
        sidebarActiveClientID,
        setSidebarActiveClientID
    } = useContext(SidebarContext);



    console.log("ACTIVE-CLIENT-ID: ", sidebarActiveClientID)

    const failedFetchError = (status) => {

        toast.error(String(status) + " error: Please refresh and try again.", {
            position: "bottom-center",
            theme: "colored",
            autoClose: false,
            closeOnClick: true,
            toastId: "failedToFetch"
        });


    }

    let dataGridRows = null

    let apiData = useRef(null);
    const [fetchedInitialAPISearchData, setFetechedInitialAPISearchData] = useState([])
    const [haveAPISearchData, setHaveAPISearchData] = useState(false);

    useEffect(() => {
        fetchClientScopedDataGridRowData();
    }, [id])

    const fetchClientScopedDataGridRowData = async () => {

        const clientScopedRequestBody = {
            "auditFamilyId": "",
            "auditId": sidebarActiveClientID,
            "lastName": "",
            "lastNameExactMatch": true,
            "firstName": "",
            "firstNameExactMatch": true,
            "birthdate": null,
            "emailAddress": ""
        }

        if (accounts.length > 0) {
            await instance.acquireTokenSilent({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) })
                .then(result => {



                    const fetchData = async () => {
                        await fetch(process.env.REACT_APP_BACK_URL + '/api/Search/SearchAudit', {
                            method: 'POST',
                            mode: 'cors',
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + result.idToken,
                            },
                            body: JSON.stringify({
                                "auditFamilyId": "",
                                "auditId": parseInt(id),
                                "lastName": "",
                                "lastNameExactMatch": true,
                                "firstName": "",
                                "firstNameExactMatch": true,
                                "birthdate": null,
                                "emailAddress": ""
                            })
                        })
                            .then(

                                res => {
                                    if (!res.ok) {
                                        failedFetchError(res.status)
                                        apiData.current = [];
                                        console.log("RES.OK: STATUS:", res.status)
                                        setHaveAPISearchData(false);
                                       
                                       
                                        
                                      
                                        /*   failedFetchError(res.status)*/
                                        /* return res.json();*/
                                        //setPleaseTryAgain(true)
                                        //setDisplayTableLoadingAnimation(false)
                                        //haveAPISearchData(false)
                                        //failedFetchError(res.status)
                                    } else {
                                        return res.json();
                                    }



                                })
                           
                            .then((response) => {
                                if (response?.status === 400) {
                                    console.log("hitting 400 error")
                                    setHaveAPISearchData(false);
                                    fetchData();
                                    throw Error(response.status);

                                } else {
                                    console.log("DATAGRID FETCH RES: ", response)
                                    setHaveAPISearchData(true);
                                    console.log("API DATA: ", response);
                                    apiData.current = [...response];
                                    setFetechedInitialAPISearchData(apiData.current)
                                   
                                }
                                console.log("hitting neither case")


                            }).catch((error) => {
                                console.log(error)


                            })
                    }

                    fetchData();

                }).catch((error) => {
                    console.log(error) //token expired/is not valid => user is redirected to login page
                    if (error instanceof InteractionRequiredAuthError) {
                        instance.acquireTokenRedirect({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) });
                    }
                });

        }





    }


    const { permissions, setPermissions } = useContext(PermissionsContext);
    const { roles, setRoles } = useContext(RolesContext);
    const { accounts, instance } = useMsal();

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [isTwoLists, setIsTwoLists] = useState(false);
    const [firstIneligibleList, setFirstIneligibleList] = useState([]);
    const [secIneligibleList, setSecIneligibleList] = useState([]);
    const [finalIneligibleList, setFinalIneligibleLst] = useState([]);
    const [hideIneligibleChart, setHideIneligibleChart] = useState(false);
    const [validRoute, setValidRoute] = useState(null);
    const [showIneligibleChart, setShowIneligibleChart] = useState(true);
    const [showIncompleteChart, setShowIncompleteChart] = useState(true);
    const [popAlertToast, setPopAlertToast] = useState(false);
    const fetchDataRef = useRef(false);

    useEffect(() => {

        if (id !== sidebarActiveClientID) {
            setIsLoading(false);
        }

    }, [id, auditName]);

    //useEffect(() => {



    //    if (isLoading === false) {
    //        setSidebarActiveClientID(`${id}`)
    //        setSidebarTitle(`${auditName}`)
    //        console.log("setSideBar call - ", auditName)
    //        setPopAlertToast(false)
    //        setIsLoading(true);

    //    }



    //}, [id, auditName]);

    useEffect(() => {

        if (isLoading === false) {
            setSidebarActiveClientID(`${id}`)
            setSidebarTitle(`${auditName}`)
            console.log("setSideBar call - ", auditName)
            setPopAlertToast(false)
            setIsLoading(true);

        }

    }, [isLoading]);

    const { newRoutes, setRoutes } = useContext(RoutesContext);

    const [pageRoutes, setPageRoutes] = useState([
        {
            id: 0,
            label: "Home",
            path: "report",
            icon: <HomeIcon />,
            isActive: false,
            style: '',
            isNested: false
        },
        {
            id: 1,
            label: "Project",
            path: `report/${String(id)}/${auditName}`,
            icon: <BarChartIcon />,
            isActive: true,
            style: 'active-navLink border-bottom__thick-blue',
            isNested: true
        },
        {
            id: 2,
            label: "Users",
            path: `report/${String(id)}/${auditName}/users`,
            icon: <GroupIcon />,
            isActive: false,
            style: '',
            isNested: true
        },
        {
            id: 3,
            label: "Processing",
            path: "processing",
            icon: <AssignmentTurnedInIcon />,
            isActive: false,
            style: '',
            isNested: false
        }
    ]);

    const [alertNoteCount, setAlertNoteCount] = useState(0);
    const [reportBadge, setReportBadge] = useState(true);
    const [count, setCount] = useState(0);

    useEffect(() => {

        //console.log("valid Route --- ", validRoute)
        //if ((roles === "CLIENT") && (validRoute === false)) {

        //    navigate(`/report/${String(permissions[0]?.auditId)}/${permissions[0]?.auditName}`, { replace: true });
        //    setIsLoading(false);

        //}

        if ((roles === 'CLIENT') && (validRoute === null) && (count === permissions?.length)) {
            setValidRoute(false);
        }
        if ((roles === "SERVICES")) {
            navigate('/processing');
        }

        //if ((roles === "CLIENT")) {
           
        //    /*navigate(`/report/${String(permissions[0]?.auditId)}/${permissions[0]?.auditName}`, { replace: true });*/
        //    //setIsLoading(false);
        //}

        if (({ pageRoutes } != { newRoutes }) && (roles === "CLIENT") && (permissions.length <= 1)) {
            setRoutes([
                {
                    id: 1,
                    label: "Project",
                    path: `report/${String(id)}/${auditName}`,
                    icon: <BarChartIcon />,
                    isActive: false,
                    style: 'active-navLink border-bottom__thick-blue',
                    isNested: false
                },
                {
                    id: 3,
                    label: "Search",
                    path: `report/${String(id)}/${auditName}/search`,
                    icon: <SearchIcon />,
                    isActive: true,
                    style: '',
                    isNested: false
                },
            ]);
        }

        if (({ pageRoutes } != { newRoutes }) && (roles === "CLIENT") && (permissions.length > 1)) {
            setRoutes([
                {
                    id: 0,
                    label: "Home",
                    path: "report",
                    icon: <HomeIcon />,
                    isActive: false,
                    style: '',
                    isNested: false
                },
                {
                    id: 1,
                    label: "Project",
                    path: `report/${String(id)}/${auditName}`,
                    icon: <BarChartIcon />,
                    isActive: false,
                    style: 'active-navLink border-bottom__thick-blue',
                    isNested: true
                },
                {
                    id: 2,
                    label: "Search",
                    path: `report/${String(id)}/${auditName}/search`,
                    icon: <SearchIcon />,
                    isActive: true,
                    style: '',
                    isNested: true
                },
            ]);
        }

        if (({ pageRoutes } != { newRoutes }) && (roles == "PROJMGR")) {
            setRoutes([
                {
                    id: 0,
                    label: "Home",
                    path: "report",
                    icon: <HomeIcon />,
                    isActive: false,
                    style: '',
                    isNested: false
                },
                {
                    id: 1,
                    label: "Project",
                    path: `report/${String(id)}/${auditName}`,
                    icon: <BarChartIcon />,
                    isActive: false,
                    style: 'active-navLink border-bottom__thick-blue',
                    isNested: true
                },
                {
                    id: 2,
                    label: "Users",
                    path: `report/${String(id)}/${auditName}/users`,
                    icon: <GroupIcon />,
                    isActive: false,
                    style: '',
                    isNested: true
                },
                {
                    id: 3,
                    label: "Search",
                    path: `report/${String(id)}/${auditName}/search`,
                    icon: <SearchIcon />,
                    isActive: true,
                    style: '',
                    isNested: true
                },
                {
                    id: 4,
                    label: "Processing",
                    path: "processing",
                    icon: <AssignmentTurnedInIcon />,
                    isActive: false,
                    style: '',
                    isNested: false
                },
                {
                    id: 5,
                    label: "Search All",
                    path: "globalsearch",
                    icon: <TravelExploreIcon />,
                    isActive: false,
                    style: 'active-navLink border-bottom__thick-blue',
                    isNested: false
                }
            ])

        }


    }, [id, auditName, roles]);

    //const [count, setCount] = useState(0);

    //useEffect(() => {
    //    setIneligibleStatusData()
    //    setFirstIneligibleList([])
    //    setFinalIneligibleLst([])
    //    setSecIneligibleList([])
    //    permissions?.map((audit) => {
    //        if (audit?.auditId === parseInt(id)) {
    //            setValidRoute(true)
    //            setCount(count + 1);
    //        }

    //    });
    //    console.log("count -- ", count)
    //    console.log("permissions -- ", permissions)
    //    if ((validRoute === null) && (count === permissions.length)) {
    //        setValidRoute(false)
    //    }

    //}, []);


    useEffect(() => {

        permissions?.map((audit) => {
            if (audit?.auditId === parseInt(id)) {
                setValidRoute(true)

            }
            setCount(count + 1);
        });
        console.log("count -- ", count)
        console.log("permissions -- ", permissions)
        if ((validRoute === null || validRoute === false) && (count === permissions?.length)) {
            setValidRoute(false)
        }
        console.log("current id --- ", id);


    }, []);


    useEffect(() => {

        if ((roles === "SERVICES")) {
            navigate('/processing');
        }
        console.log("permissions - ", permissions);
        console.log("valid Route 2 --- ", validRoute)
        if ((roles === "CLIENT") && (validRoute === false)) {

            navigate(`/report/${String(permissions[0]?.auditId)}/${permissions[0]?.auditName}`, { replace: true });
            //setIsLoading(false);

        }


    }, [validRoute, setValidRoute, roles]);























    const [dependentReportData, setDependentReportData] = useState([]);


    function getDependentID(row) {
        return row.rowID;
    }

    function getEmployeeRowID(row) {
        return row.rowID;
    }

    function getDependentRelationship(row) {
        console.log("ROW -----", row);

        if (row.dependent_relationship === "S ") {
            return 'Spouse'
        } else if (row.dependent_relationship === "D ") {
            return 'Dependent'
        } else {
            return `DBValue: ${row.dependent_relationship}`
        }
    }

    const CustomMobileInputAuditID = (props) => {
        return (
            <TextField

                label="Audit ID"
                variant="outlined"
                {...props}

                sx={{ ml: 3, mr: '1VW', mt: 1, width: '88VW', bgcolor: '#FFFFFF' }}
            />
        )
    }

    //////

    let apiFamilyData = useRef(null);



    /////
    const [rowSelectedMemberData, setRowSelectedMemberData] = useState()

    function handleUpdateRowSelectedMemberData(updatedData) {
        let tempData = [];
        console.log("updatedRowSelectedMemberData: ", updatedData)
        tempData = [...tempData, updatedData]
        console.log("setting this tempData: ", tempData)
        setRowSelectedMemberData(tempData);
    }

    function handleNavigateToFamilyDetails(navFamId, navEmpId) {

        console.log("leaving, REAL RowSelectedMemberData: ", rowSelectedMemberData)

        navigate(`/report/${String(id)}/${auditName}/search/${String(navFamId)}/${String(navEmpId)}`);

    };

    const handleUpdateMemberRowDataAndNavigate = (params) => {

        handleUpdatePersistentPreviousSearchParameters(mainSearchFilterModel)

        let navigateAudtFamilyId = params.row.audit_family_id;
        let navigateEmployeeId = params.row.employeeID;

        let newRowSelectedMemberData = {
            "rowEmployeeID": params.row.employeeID,
            "rowAuditFamilyID": params.row.audit_family_id,
            "rowAuditEndDate": params.row.audit_end_date,
            "rowAddress": params.row.address,
            "rowClient": params.row.client,
            "rowEmail": params.row.email,
            "rowEmployeeFirstName": params.row.first_name,
            "rowEmployeeLastName": params.row.last_name,
            "rowEmployeeDateOfBirth": params.row.date_of_birth,
        }
        setRowSelectedMemberData([newRowSelectedMemberData]);
        handleUpdateRowSelectedMemberData(newRowSelectedMemberData);



        /* console.log(params);*/
        console.log("CLICK-ROW", params.row);
        console.log("leaving, newRowSelectedMemberData: ", newRowSelectedMemberData)


        handleNavigateToFamilyDetails(navigateAudtFamilyId, navigateEmployeeId)
    }

    const [familyTestData, setFamilyTestData] = useState("");

    function handleUpdateFamilyTestData(dataToUpdate) {
        setFamilyTestData(dataToUpdate);
    }

    const handleFamilyTest = (params) => {
        console.log("CLICK-ROW", params.row);

        let potentialAuditFamilyID = params.row.audit_family_id;
        let potentialEmployeeID = params.row.employeeID;
        /* handleUpdateFamilyTestData(params.row.employeeID)*/
        /* TEST of GET FAMILY MEMBERS, also need one for GetFamily*/
        const fetchFamilyScopedData = async () => {

            const familyScopedRequestBody = {
                "auditFamilyId": potentialAuditFamilyID,
                "employeeId": potentialEmployeeID,
            }

            if (accounts.length > 0) {
                await instance.acquireTokenSilent({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) })
                    .then(result => {



                        const fetchData = async () => {
                            await fetch(process.env.REACT_APP_BACK_URL + '/api/Search/GetFamilyMembers', {
                                method: 'POST',
                                mode: 'cors',
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Authorization': 'Bearer ' + result.idToken,
                                },
                                body: JSON.stringify(
                                    familyScopedRequestBody
                                )
                            })
                                .then(res => res.json())
                                .then((response) => {
                                    if (response.status === 400) {
                                        console.log("hitting 400 error")

                                        fetchData();
                                        throw Error(response.status);

                                    } else {
                                        console.log("FAMILY FETCH RES: ", response)

                                        apiFamilyData.current = [...response];
                                        setFamilyTestData(apiFamilyData.current)
                                    }
                                    console.log("hitting neither case -- family")


                                }).catch((error) => {
                                    console.log(error)


                                })
                        }

                        fetchData();

                    }).catch((error) => {
                        console.log(error) //token expired/is not valid => user is redirected to login page
                        if (error instanceof InteractionRequiredAuthError) {
                            instance.acquireTokenRedirect({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) });
                        }
                    });

            }





        }
        fetchFamilyScopedData();

    }

    const [anchorEl, setAnchorEl] = React.useState(null);
    const openSearchSettings = Boolean(anchorEl);

    const handleClickSearchSettings = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseSearchSettings = () => {
        setAnchorEl(null);
    };


    const EmployeeSearchContextValue = {
        openMobile,
        handleDrawerCloseMobile,
        handleDrawerOpenMobile,
        handleUpdateEmployeeOnlySearch,
        employeeOnlySearch,
        handleUpdateSearchValueClientExactMatch,
        searchValueClientExactMatch,
        handleUpdateSearchFirstNameExactMatch,
        searchFirstNameExactMatch,
        handleUpdateSearchLastNameExactMatch,
        searchLastNameExactMatch,
        handleSearchValueAuditIDChange,
        handleSearchValueClientChange,
        handleSearchValueFirstNameChange,
        handleSearchValueLastNameChange,
        handleSearchValueEmailAddressChange,
        handleSearchValueDOBChange,
        handleGeneratePreviousMainSearchFilterModel,
        previousSearchParameters,
        handleClearSearchAndBackUpPreviousFilterModel,
        handleGenerateNewMainSearchFilterModel,
        searchValueAuditID,
        searchValueClient,
        searchValueFirstName,
        searchValueLastName,
        searchValueEmailAddress,
        searchValueDOB,
        haveAPISearchData,
        getEmployeeRowID,
        handleUpdateMemberRowDataAndNavigate,
        getDependentID,
        getDependentRelationship

    }



    return (
        <>
            <EmployeeSearchContext.Provider value={EmployeeSearchContextValue}>

                <Box component="main" sx={{ bgcolor: '#FAFAFA', width: '100VW', minHeight: '92VH', height: 'fit-content', flexGrow: 1, paddingTop: 1, mt: 2.5, ml: '0', mr: 0, display: { xs: 'none', md: 'block', xl: 'block' } }} >

                    {/*  DESKTOP PAGE TITLES/HEADINGS*/}


                    <Container sx={{ mt: 4, ml: 0, display: { xs: 'none', md: 'block' } }}>
                        <div className="row-no-left-margin" style={{ width: "82.5VW" }}>
                            <div className="row-left">

                                <Typography textAlign="left" component="h1" gutterBottom={true} fontWeight="600" fontSize="1.5em" sx={{ mt: 0, }}>{`Search ${auditName}`}</Typography>
                                {/*{familyTestData !== "" ? (<>*/}
                                {/*    <Typography textAlign="left" component="h1" gutterBottom={true} fontWeight="600" fontSize="1.5em" sx={{ mt: 0, }}>{familyTestData}</Typography>*/}
                                {/*</>) : (<></>)}*/}

                            </div>
                            <div className="row-right">

                            </div>
                        </div>
                    </Container >
                  
                   

                    {/* DESKTOP TABLE*/}
                 
                    {/*{employeeOnlySearch === true ? (<>*/}
                    {/*    {haveAPISearchData !== true && apiData.length !== 0 ? (<>*/}
                    {/*        */}{/* DESKTOP TABLE - FALLBACK SKELETON*/}
                    {/*        <Box sx={{ width: '98VW', height: '2VH', bgcolor: '#FAFAFA', mt: 0, ml: 0, display: { xs: 'none', md: 'block', xl: 'block' } }}>*/}
                    {/*        </Box>*/}

                    {/*        <Box sx={{ ml: 3 }}>*/}
                    {/*            <SkeletonTheme baseColor="#EFF6FF" highlightColor="ffffff">*/}
                    {/*                <Skeleton height={510} width={'75VW'} />*/}
                    {/*            </SkeletonTheme>*/}
                    {/*        </Box>*/}

                    {/*    </>) : (<>*/}
                    {/*        <Box sx={{ height: 600, width: '86VW', ml: 3 }}>*/}
                    {/*            <DataGridPro*/}
                    {/*                    onRowClick={(row) => handleUpdateMemberRowDataAndNavigate(row)}*/}
                    {/*                getRowId={getEmployeeRowID}*/}
                    {/*                columnVisibilityModel={{*/}
                    {/*                    // Hide columns status and traderName, the other columns will remain visible*/}
                    {/*                    other: false,*/}
                    {/*                }}*/}

                    {/*                onFilterModelChange={(model) => console.log("NEW Filter MODEL - GEN: ", model)}*/}
                    {/*                columns={dataGridColumns}*/}
                    {/*                rows={apiData.current}*/}
                    {/*                sx={{*/}
                    {/*                    '& .MuiDataGrid-columnContainer': {*/}
                    {/*                        bgcolor: '#F1F5F9',*/}
                    {/*                        fontWeight: 800,*/}
                    {/*                    },*/}
                    {/*                }}*/}
                    {/*                slots={{*/}
                    {/*                    noResultsOverlay: CustomNoResultsOverlay*/}

                    {/*                }}*/}
                    {/*                apiRef={apiRef}*/}



                    {/*            />*/}


                    {/*        </Box>*/}
                    {/*    </>)}*/}
                    {/*</>) : (<>*/}
                    {/*    {haveAPISearchData !== true && apiData.length !== 0 ? (<>*/}
                    {/*        */}{/* DESKTOP TABLE - FALLBACK SKELETON*/}
                    {/*        <Box sx={{ ml: 3 }}>*/}
                    {/*            <SkeletonTheme baseColor="#EFF6FF" highlightColor="ffffff">*/}
                    {/*                <Skeleton height={510} width={'75VW'} />*/}
                    {/*            </SkeletonTheme>*/}
                    {/*        </Box>*/}
                    {/*    </>) : (<>*/}
                    {/*        <Box sx={{ height: 600, width: '86VW', ml: 3 }}>*/}
                    {/*            <DataGridPro*/}
                    {/*                        onRowClick={(row) => handleUpdateMemberRowDataAndNavigate(row)}*/}
                    {/*                getRowId={getEmployeeRowID}*/}
                    {/*                columnVisibilityModel={{*/}
                    {/*                    // Hide columns status and traderName, the other columns will remain visible*/}
                    {/*                    other: false,*/}
                    {/*                }}*/}
                    {/*                onFilterModelChange={(model) => console.log("NEW Filter MODEL - GEN: ", model)}*/}
                    {/*                columns={dataGridColumns}*/}
                    {/*                rows={apiData.current}*/}
                    {/*                sx={{*/}
                    {/*                    '& .MuiDataGrid-columnContainer': {*/}
                    {/*                        bgcolor: '#F1F5F9',*/}
                    {/*                        fontWeight: 800,*/}
                    {/*                    },*/}
                    {/*                }}*/}

                    {/*                apiRef={apiRef}*/}
                    {/*                slots={{*/}
                    {/*                    noResultsOverlay: CustomNoResultsOverlay,*/}
                    {/*                    detailPanelExpandIcon: IoMdArrowDropright,*/}
                    {/*                    detailPanelCollapseIcon: IoMdArrowDropdown,*/}
                    {/*                }}*/}
                    {/*                getDetailPanelHeight={() => 250}*/}
                    {/*                getDetailPanelContent={({ row }) => row.dependent_data != [] ? (*/}
                    {/*                    <Box sx={{ height: 'fit-content', bgcolor: '#ffffff' }}>*/}
                    {/*                        <DataGridPro*/}
                    {/*                            density="compact"*/}
                    {/*                            getRowId={getDependentID}*/}
                    {/*                            columns={[*/}
                    {/*                                { field: 'dependent_first_name', headerName: 'First Name', "width": 110 },*/}
                    {/*                                { field: 'dependent_last_name', headerName: 'Last Name', "width": 160 },*/}
                    {/*                                {*/}
                    {/*                                    field: "dependent_relationship",*/}
                    {/*                                    headerName: 'Relationship',*/}
                    {/*                                    "width": 150,*/}
                    {/*                                    renderCell: (params) =>*/}
                    {/*                                        getDependentRelationship(params.row)*/}


                    {/*                                },*/}
                    {/*                                {*/}
                    {/*                                    field: "dependent_date_of_birth", headerName: 'DOB', "width": 110, renderCell: (params) =>*/}
                    {/*                                        renderDate(params.row?.dependent_date_of_birth)*/}
                    {/*                                },*/}
                    {/*                            ]}*/}
                    {/*                            rows={row?.dependent_data}*/}
                    {/*                            sx={{*/}
                    {/*                                mt: '2.5VH',*/}
                    {/*                                ml: '2.5VW',*/}

                    {/*                                width: '80VW',*/}

                    {/*                                bgcolor: '#F8FAFC',*/}
                    {/*                                boxShadow: 2,*/}
                    {/*                                border: 2,*/}
                    {/*                                borderColor: 'primary.light',*/}
                    {/*                                '& .MuiDataGrid-columnContainer': {*/}
                    {/*                                    bgcolor: '#F1F5F9',*/}
                    {/*                                    fontWeight: 800,*/}
                    {/*                                },*/}
                    {/*                                '& .MuiDataGrid-cell:hover': {*/}
                    {/*                                    color: 'primary.main',*/}
                    {/*                                },*/}
                    {/*                            }}*/}
                    {/*                            hideFooter*/}
                    {/*                        />*/}
                    {/*                    </Box>*/}
                    {/*                ) : (<></>)*/}

                    {/*                }*/}

                    {/*            />*/}
                    {/*        </Box>*/}
                    {/*    </>)}*/}
                    {/*</>)}*/}

                    {haveAPISearchData !== true && apiData.length !== 0 ? (<>
                        {/* DESKTOP TABLE - FALLBACK SKELETON*/}
                        <Box sx={{ ml: 3, mb: 3 }}>
                            <SkeletonTheme baseColor="#EFF6FF" highlightColor="ffffff">
                                <Skeleton height={110} width={'75VW'} />
                            </SkeletonTheme>
                        </Box>                 <Box sx={{ ml: 3 }}>
                            <SkeletonTheme baseColor="#EFF6FF" highlightColor="ffffff">
                                <Skeleton height={510} width={'75VW'} />
                            </SkeletonTheme>
                        </Box>
                    </>) : (<>
                        <EmployeeSearchTable
                            tableData={apiData}
                            desiredWidth={'80VW'}
                            desiredHeight={'30VH'}
                        />
                    </>)}



                </Box>


                

                <Box component="main" sx={{ width: '100%', height: '100VH', flexGrow: 1, paddingTop: 1, mt: 2.5, ml: 0.5, display: { xs: 'block', md: 'none', xl: 'none' } }}  >
                   
                    {haveAPISearchData !== true && apiData.length !== 0 ? (<>
                        {/* DESKTOP TABLE - FALLBACK SKELETON*/}
                        <Container sx={{ mt: 9, ml: 0.75, mr: 2, display: { xs: 'block', md: 'none' } }}>
                            <div className="row-no-left-margin">
                                <div className="row-left">

                                    <Typography textAlign="left" component="h1" gutterBottom={true} fontWeight="600" fontSize="1.5em" sx={{ mt: 0, }}> {`Search ${auditName.substring(0, 3)}`}</Typography>
                                    {/*<Typography textAlign="left" component="h1" gutterBottom={true} fontWeight="600" fontSize="1.5em" sx={{ mt: 0, }}> {`${auditName.substring(0, 3)}`}</Typography>*/}


                                </div>
                            </div>
                        </Container> 
                        <Box sx={{ ml: 3 }}>
                            <SkeletonTheme baseColor="#EFF6FF" highlightColor="ffffff">
                                <Skeleton height={510} width={'75VW'} />
                            </SkeletonTheme>
                        </Box>
                    </>) : (<>
                            <EmployeeSearchTableMobile
                                tableData={apiData}
                                desiredWidth={'78VW'}
                                desiredHeight={600} />
                    </>)}


                   
                </Box>


               

                {/*{openMobile ? (<>*/}
                {/*    <SearchFiltersMobile*/}
                {/*        searchValueAuditID={searchValueAuditID }*/}
                {/*        searchValueClient={searchValueClient}*/}
                {/*        searchValueFirstName={searchValueFirstName }*/}
                {/*        searchValueLastName={searchValueLastName }*/}
                {/*        searchValueEmailAddress={searchValueEmailAddress }*/}
                {/*        searchValueDOB={searchValueDOB}*/}

                {/*        openUpdateModal={openMobile}*/}
                {/*    />*/}
                {/*</>) : (<></>)}*/}



                <ToastContainer
                    limit={1}
                    rtl={false}

                />
            </EmployeeSearchContext.Provider>
        </>
    )
}

export default EmployeeSearchLocal;
