

import React, { useContext } from 'react';

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from '@mui/material/Grid';
import { MdStar, MdStarBorder } from "react-icons/md";
import IconButton from '@mui/material/IconButton';
import Container from "@mui/material/Container";
import Tooltip from "@mui/material/Tooltip";



import { useParams, useNavigate, Navigate, Link } from "react-router-dom";

import QueryStatsIcon from '@mui/icons-material/QueryStats';
import SearchIcon from '@mui/icons-material/Search';

import { GraphDetailsContext } from '../../App';


function GraphCardWideBarChart({ graphName, graphComponent, graphLink, desiredCardWidth, desiredCardHeight, graphLegendComponent, graphLegendComponent2, graphData, graphTableData }) {

    const {
        graphComponentData,
        handleUpdateGraphComponentData,
        graphTableComponentData,
        handleUpdateGraphTableComponentData
    } = useContext(GraphDetailsContext);

    const navigate = useNavigate();

    let stateObj = { graphname: graphName, graphcomponent: graphComponent, graphlegend: graphLegendComponent }

    /*let stringedStateObj = JSON.stringify(stateObj)*/

    const handleNavigateToProject = (params) => {
        if (graphData !== null) {
            handleUpdateGraphComponentData(graphData)
        }
        if (graphTableData !== null) {
            handleUpdateGraphTableComponentData(graphTableData)
        }
        /* console.log(params);*/

        if (graphLink !== null) {
            navigate(graphLink, { state: { graph_title: graphName } });
        }

        /* navigate(`report/:id/:auditName/search/`);*/

    };

    return (
        <>
            <Card style={{ height: '90%' }} sx={{ mr: 1.5, minWidth: desiredCardWidth ? (desiredCardWidth) : (75), minHeight: desiredCardHeight ? (desiredCardHeight) : (400), width: desiredCardWidth ? (desiredCardWidth) : (400) }}>



                <Typography sx={{}} textAlign="center" variant="h6" fontWeight="600" fontSize="1.55em" component="div">
                    {graphName} {graphLink !== null ? (<>
                        <Tooltip title={"Search Graph Details"}
                            placement="top">
                            <IconButton
                                sx={{ mt: 0, bgcolor: "#EFF6FF" }}
                                onClick={() => handleNavigateToProject()}
                            >
                                <SearchIcon />
                            </IconButton>
                        </Tooltip>
                    </>) : (<></>)}
                </Typography>
                {/*<div className="row-no-left-margin">*/}

                {/*    <div className="row-right">*/}




                {/*    </div>*/}

                {/*</div>*/}
                <Container sx={{ ml: '-4.5VW', mb: '-0.5VH', mt: '-6.5VH' }}>
                    <Grid container spacing={3} sx={{ mt: 0, mb: 0, display: { xs: 'none', md: 'flex', xl: 'flex' } }}>
                        {/*<Grid item xs={4} >*/}

                        {/*    {graphComponent ? (<>{graphComponent}</>) : (<></>)}*/}

                        {/*</Grid>*/}
                        <Grid item xs={2.75} >

                            

                        </Grid>
                        <Grid item xs={4} >

                            {graphLegendComponent ? (<Box sx={{ mt: 6, mb: 6,  }}>
                                {graphLegendComponent}
                            </Box>) : (<></>)}

                        </Grid>
                        <Grid item xs={4} >

                            {graphLegendComponent2 ? (<Box sx={{ mt: 6, mb: 6,  }}>
                                {graphLegendComponent2}
                            </Box>) : (<></>)}

                        </Grid>
                    </Grid>
                </Container>













            </Card>
        </>
    );
}

export default GraphCardWideBarChart;

