import React, { useState, useContext } from 'react';

import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { BsBoxArrowLeft } from "react-icons/bs";
import ListItem from '@mui/material/ListItem';
import MuiListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Logout from '@mui/icons-material/Logout';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import HomeIcon from '@mui/icons-material/Home';
import BarChartIcon from '@mui/icons-material/BarChart';
import GroupIcon from '@mui/icons-material/Group';
//import { RoutesContext } from "../App";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import '../App.css'
import Popper from "@mui/material/Popper";
import Container from "@mui/material/Container";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { MdMenu, MdDarkMode, MdLightMode, MdKeyboardDoubleArrowLeft, MdKeyboardDoubleArrowRight } from "react-icons/md";
import { BsSearch } from "react-icons/bs"
import { VscTriangleDown } from "react-icons/vsc";
import { PiArrowLeftBold } from "react-icons/pi";
import { useNavigate, Link, NavLink, useLocation, useParams } from "react-router-dom";
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';


import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';


import { VscLayoutSidebarLeft } from "react-icons/vsc";

import bmilogo from '../assets/bmi-logo-cross-transparent.png';
import Avatar from '@mui/material/Avatar';

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import { SidebarContext, BatchContext, DocViewerContext, ProjectDashboardContext } from "../App";

import { LuLogOut } from "react-icons/lu";


import { loginRequest, b2cPolicies } from "../authConfig";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";

//const { newRoutes, setRoutes } = useContext(RoutesContext);

const ListItemButton = styled(MuiListItemButton)({
    '&.active .MuiTypography-root': {
        fontWeight: 'bold',
        color: '#1E40AF',
        backgroundColor: '#EFF6FF',
    },
});


const drawerWidth = 220;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),

    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open' || prop !== 'openMobile',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' || prop !== 'openMobile' })(
    ({ theme, open }) => ({

        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',

        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

///MOBILE MUI THEMES







function Sidebar({ routes, accountRoutes }) {

    const { instance, accounts } = useMsal();

    const [logoutMode, setLogoutMode ] = useState(false)

    const {
        sidebarTitle,
        setSidebarTitle,
        sidebarActions,
        setSidebarActions,
        sidebarUserRole,
        setSidebarUserRole,
        clientSearchData,
        setSidebarActiveUser,
        sidebarActiveUser,
        sidebarCurrentUserTheme,
        sidebarLogoutMode,
        setSidebarLogoutMode,
        sidebarAvatarInitials,
        setSidebarAvatarInitials
    } = useContext(SidebarContext);

    const {

        availableClientAuditEndDates, setAvailableClientAuditEndDates, handleUpdateAvailableClientAuditEndDates,
        activeClientAuditEndDates, setActiveClientAuditEndDates, handleUpdateActiveClientAuditEndDates, callFilter, setCallFilter,

    } = useContext(ProjectDashboardContext);

    const {
        docNum, numDocs, prevDocument, nextDocument, fetchBatch,
        documentDependentProgress, setDocumentDependentProgress,
        handleUpdateDocumentDependentProgressIndicator,
        handleProvideDocumentProcessStatusByDependentID,
        batch, setBatch, setDocNum, setNumDocs, isDocSwitchDisabled, documentTypes, relationshipTypes, auditDocData
    } = useContext(DocViewerContext);


    const { documentListState, batchCount } = useContext(BatchContext);

    const activeUserData = sidebarActiveUser;

    function getAccountBadge() {

        //const account = accounts[0]?.idTokenClaims;
        const account = instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn)?.idTokenClaims;

        const firstName = account?.given_name;
        const lastName = account?.family_name;

        const initials = firstName?.slice(0, 1) + lastName?.slice(0, 1);
        //const initials = "CT";
        /* console.log("initials " + initials);*/

        return {
            sx: {
                fontSize: '14px',
                bgcolor: sidebarCurrentUserTheme,
                color: '#FFFFFF',
            },
            children: `${sidebarAvatarInitials}`,
        };

    }

    //ClientSearchPopper functions
   /* console.log("Within Sidebar: ClientSearchData:", clientSearchData);*/



    const [clientSearchFilterInput, setClientSearchFilterInput] = useState('');
    const [filteredClients, setFilteredClients] = useState([])
    const [displayFileredClients, setDisplayFilteredClients] = useState(false);



    function handleResetAllClientFilters() {
        setClientSearchFilterInput('');
        setFilteredClients([]);



    }

    //function processingAppBar() {
    //    //TODO: RENDER APP BAR
    //    return (
    //        <>
    //            <Box display="flex" alignItems="center" sx={{ width: '100VW', height: '5VH', bgcolor: '#ffffff', shadowColor: '#E5E5E5', borderBottom: '2px solid #e5e5e5', }}>

    //                <Box src={bmilogo} component="img" aria-label="BMI Audit Services Logo" alt="BMI Audit Services Logo" role="img" variant="square" sx={{
    //                    ml: '2VW',
    //                    mr: '0.5rem',
    //                    height: '32px',
    //                    minWidth: '32px',
    //                    padding: 0,
    //                    color: '#23549F',


    //                }} ></Box>

    //                <Typography variant="h6" component="div" sx={{ fontWeight: 700, fontSize: '16px', color: '1c1c1c', ml: 0 }}>Document Processing</Typography>
    //                <Divider orientation="vertical" variant="middle" flexItem sx={{ ml: 'auto' }} />
    //                <IconButton onClick={() => prevDocument()} disabled={docNum === 0} sx={{ ml: '0.5VW' }}><NavigateBeforeIcon /></IconButton>
                    
    //                <Typography variant="h3" sx={{ color: '1c1c1c' }}>{docNum + 1}/{numDocs}</Typography>
    //                <IconButton onClick={() => nextDocument()} disabled={docNum >= numDocs - 1}><NavigateNextIcon /></IconButton>
    //                <Box sx={{ width: '24VW' }}></Box>
    //                <Button variant="contained" onClick={() => fetchBatch()} sx={{ bgcolor: '#0059a8' }} sx={{ mr: '2rem' }}>
    //                    Next Family
    //                </Button>

    //            </Box>
    //        </>
    //    )
    //}

    //function dashboardAppBar() {

    //}



    const searchClients = (searchValue) => {
        setClientSearchFilterInput(searchValue)



        if (clientSearchFilterInput !== '') {
            const filteredData = clientSearchData.filter((item) => {
                return item.auditName.toLowerCase().includes(clientSearchFilterInput.toLowerCase())
            })
           /* console.log("FOUND in Search Filtered Clients: ", filteredData);*/
            setFilteredClients(filteredData);


        } else {
            setFilteredClients(clientSearchData)
            
        }



    }



    const { id, auditName, auditFamilyId, employeeId} = useParams();



  /*  console.log("Sidebar Current PARAMS: ", params);*/

   

    const location = useLocation();

    const currentRouteLocation = location.pathname;

    let trimmedCurrentRouteLocation = currentRouteLocation.slice(1)
    const capitalizedTrimmedCurrentRouteLocation = trimmedCurrentRouteLocation.charAt(0).toUpperCase() + trimmedCurrentRouteLocation.slice(1);
    /*  console.log("currentRouteLocation,", currentRouteLocation)*/

    const [topbarLocation, setTopbarLocation] = useState("")

    

    //from NavigationBar.jsx

    const isAuthenticated = useIsAuthenticated();

    const [visible, setVisible] = useState(false);
    const [isActive, setActive] = useState(false);





    const handleLogout = () => {
        setSidebarLogoutMode(true);
        instance.logoutRedirect()
    }


    // Material UI provided hooks
    const navigate = useNavigate();



    const [accountMenuAnchorEl, setAccountMenuAnchorEl] = React.useState(null);
    const openAccountMenu = Boolean(accountMenuAnchorEl);
    const handleOpenAccountMenu = (event) => {
        setAccountMenuAnchorEl(event.currentTarget);

    };
    const handleCloseAccountMenu = () => {
        setAccountMenuAnchorEl(null);

    };

    const handleDrawerToggleListItemDesktop = () => {
        setOpen(!open);
    }

    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);

    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    //const [open, setOpen] = React.useState(false);

    const [openMobile, setOpenMobile] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };


    const handleDrawerOpenMobile = () => {
        setOpenMobile(true);
    };

    const handleDrawerCloseMobile = () => {
        setOpenMobile(false);
    };


    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    //custom NavLink component wrapper to utilize active class styling within MUI ListItem component
    const NavLinkWrapper = React.forwardRef((props, ref) => (
        <NavLink ref={ref} key={props.item?.id} {...props} className={props.item?.isActive ? ('active-navLink border-bottom__thick-blue') : (" ")} tag={Link} to={props.item?.path} style={{ textDecoration: 'none' }} />

    ));

    //For SwitchClient popper
    const [switchClientAnchorEl, setSwitchClientAnchorEl] = React.useState(null);

    const handleOpenSwitchClientPopper = (event) => {
        setSwitchClientAnchorEl(switchClientAnchorEl ? null : event.currentTarget);
    };

    const handleCloseSwitchClientPopper = () => {
        setSwitchClientAnchorEl(null);
        setDisplayFilteredClients(false);
    };

    const handleCloseSwitchClientAndResetDateFilterPopper = () => {
        setSwitchClientAnchorEl(null);
        setDisplayFilteredClients(false);
        setActiveClientAuditEndDates([{
            auditEndDate: "12/12/1800"
        }])

    };

    //processing nextFamilyButton lock
    function getNextFamilyDisabled() {
        //if (!(docNum >= numDocs - 1) == true) {
        //    console.log("TEST gNF: docNum >= numDocs - 1 CASE: TRUE")
        //    return true;
        //} else if (!(pageNum >= numPages) == true) {
        //    console.log("TEST gNF: pageNum >= numPages CASE: TRUE")
        //    return true;
        //} else {
        //    console.log("TEST gNF: FALSE")
        //    return false;
        //}
       
        console.log('sidebar context relationshipTypes - ', relationshipTypes);
        console.log('sidebar context documentTypes - ', documentTypes);
        console.log('sidebar context auditDocData - ', auditDocData);
        //batch count and number documents are the same (both 0 so no documents fetched, or n=n so all documents worked)
        if (batchCount === numDocs && documentTypes?.documents?.length > 0 && relationshipTypes?.length > 0 && auditDocData?.length > 0 && typeof documentTypes !== "undefined" && typeof auditDocData !== "undefined" && typeof relationshipTypes !== "undefined") { //verify all documents haven't been worked and form data is populated
            console.log('sidebar context relationshipTypes2 - ', relationshipTypes);
            console.log('sidebar context documentTypes2 - ', documentTypes);
            console.log('sidebar context auditDocData2 - ', auditDocData);
            return false;
        } else if (batchCount !== numDocs) {
            console.log('sidebar context relationshipTypes4 - ', relationshipTypes);
            console.log('sidebar context documentTypes4 - ', documentTypes);
            console.log('sidebar context auditDocData4 - ', auditDocData);
            return true;
        }  else {
            console.log('sidebar context relationshipTypes3 - ', relationshipTypes);
            console.log('sidebar context documentTypes3 - ', documentTypes);
            console.log('sidebar context auditDocData3 - ', auditDocData);
            return true;
        }

       
    }

    const openSwitchClientPopper = Boolean(switchClientAnchorEl);

    return (
        <>
            <Box sx={{ display: { xs: "none", md: "flex" } }}>
                <AppBar elevation={1} position="fixed" open={open} sx={{ backgroundColor: '#FFFFFF', shadowColor: '#E5E5E5' }}>
                    <Toolbar position="sticky">
                      
                        <Avatar src={bmilogo}
                            alt="BMI Audit Services logo"
                            aria-label="BMI Audit Services logo"
                            variant="square"
                            sx={{
                                ml: '-1rem',
                                mr: '2rem',
                                height: 42,
                                minWidth: 42,
                                padding: 0,
                                color: '#23549F',
                                marginRight: 0,
                                ...(open && { display: 'none' }),
                            }} />

                            {open ? (<></>) : (<Divider orientation="vertical" variant="middle" flexItem sx={{ ml: '0.9rem' }} />)}



                        { (sidebarUserRole === 'PROJMGR' || sidebarUserRole === 'SERVICES') && currentRouteLocation === '/processing' ? (<>
                            <Typography variant="h6" noWrap component="div" sx={{
                                fontWeight: 700,
                                fontSize: '16px',
                                color: '#262626', mr: 60, ml: '1.5rem', display: { xs: "none", md: "flex", },
                                ...(open && { ml: 0 })
                            }}>
                            Processing
                            </Typography>

                            <Typography variant="h6" noWrap component="div" sx={{ fontWeight: 700, fontSize: '16px', color: '#262626', mr: 15, display: { xs: "flex", md: "none" } }}>
                            Processing
                            </Typography>
                            {currentRouteLocation === '/processing' ? (<>
                                <Box
                                    style={{ marginRight: "auto" }}
                                    justifyContent="flex-end"
                                    sx={{ flexGrow: 1, display: { xs: "none", md: "flex" }, mr: 5 }}
                                >

                                    {isAuthenticated ? (<>
                                        <Tooltip title="Account settings">
                                            <IconButton
                                                onClick={handleOpenAccountMenu}
                                                size="small"
                                                sx={{ ml: 2 }}
                                                aria-controls={openAccountMenu ? 'account-menu' : undefined}
                                                aria-haspopup="true"
                                                aria-expanded={openAccountMenu ? 'true' : undefined}
                                            >
                                                {sidebarLogoutMode ? (<>
                                                    <SkeletonTheme baseColor="#EFF6FF" highlightColor="ffffff">
                                                        <Skeleton circle={true} height={40} width={40} style={{ marginLeft: '0', marginRight: 'auto' }} />
                                                    </SkeletonTheme>
                                                </>) : (<>
                                                    <Avatar {...getAccountBadge()} />
                                                </>)}

                                                {/*  <Typography sx={{ fontSize: '14px' }} >*/}

                                                {/* </Typography>*/}
                                                {/*  </Avatar>*/}
                                            </IconButton>
                                        </Tooltip></>) : (<></>)}

                                </Box>
                            </>) : (<></>)}
                            <Divider orientation="vertical" variant="middle" flexItem sx={{ ml: 'auto' }} />
                            <IconButton onClick={() => prevDocument()} disabled={(docNum === 0) || (isDocSwitchDisabled === true)} sx={{ ml: '0.5VW' }}><NavigateBeforeIcon /></IconButton>
                            <Typography sx={{ color: '#262626'}}>{docNum + 1}/{numDocs}</Typography>
                            <IconButton onClick={() => nextDocument()} disabled={(docNum >= numDocs - 1) || (isDocSwitchDisabled === true)}><NavigateNextIcon /></IconButton>
                            <Box sx={{ width: '23.5VW' }}></Box>
                            <Button variant="contained" onClick={() => fetchBatch()} disabled={getNextFamilyDisabled() ? true : false} sx={{ bgcolor: '#0059a8', height: '40px', width: '150px' }}>
                                Next Family
                            </Button>
    
                        </>) : (<></>) } 
                            
                          
                        {(sidebarUserRole === 'PROJMGR' || sidebarUserRole === 'SERVICES') && (currentRouteLocation === '/globalsearch' || currentRouteLocation === `/globalsearch/${String(auditFamilyId)}/${String(employeeId)}`) ? (<>
                            <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon fontSize="small" />}>
                                <div className="spacer-margin-left">
                                    <NavLink underline="hover" color="inherit" to="/report" style={{ textDecoration: 'none', color: '#525252', fontSize: '16px', fontWeight: 700, }}>
                                        Clients
                                    </NavLink>
                                </div>
 
                            </Breadcrumbs>

                        </>) : (<></>)}
                        {sidebarUserRole === 'PROJMGR' && currentRouteLocation === '/report' ? (<>
                            <Breadcrumbs aria-label="breadcrumb">
                                <div className="spacer-margin-left">
                                    <NavLink underline="hover" color="inherit" to="/report" style={{ textDecoration: 'none', color: '#525252', fontSize: '16px', fontWeight: 700, }}>
                                        Clients
                                    </NavLink>
                                </div>

                              
                            </Breadcrumbs>

                        </>) : (<></>)}

                        {sidebarUserRole == 'PROJMGR' && currentRouteLocation !== '/report' && currentRouteLocation !== '/processing' && currentRouteLocation !== '/globalsearch' && currentRouteLocation !== `/globalsearch/${String(auditFamilyId)}/${String(employeeId)}` ? (<>
                            <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon fontSize="small" />}>
                                <div className="spacer-margin-left">
                                    <NavLink underline="hover" color="inherit" to="/report" style={{ textDecoration: 'none', color: '#525252', fontSize: '16px', fontWeight: 700, }}>
                                        Clients
                                    </NavLink>
                                </div>

                                <Typography variant="h6" noWrap component="div" sx={{
                                    fontWeight: 700,
                                    fontSize: '16px',
                                    color: '#262626', mr: 1, ml: '0', display: { xs: "none", md: "flex", },
                                    ...(open && { ml: 0 })
                                }}>
                                    {sidebarTitle}
                                </Typography>

                            </Breadcrumbs>
                            <Tooltip title="Switch Client">
                                <IconButton sx={{ mt: '-0.25rem' }} type="button" onClick={handleOpenSwitchClientPopper}>
                                    <VscTriangleDown style={{ height: '1rem', width: '1rem' }} />
                                </IconButton>
                            </Tooltip>
                        </>) : (<></>)}
                        {/*{sidebarUserRole == 'PROJMGR' && currentRouteLocation == '/report' ? (<>*/}
                        {/*    <Breadcrumbs aria-label="breadcrumb">*/}
                        {/*        <div className="spacer-margin-left">*/}
                        {/*            <NavLink underline="hover" color="inherit" to="/report" style={{ textDecoration: 'none', color: '#525252', fontSize: '16px', fontWeight: 700, }}>*/}
                        {/*                Clients*/}
                        {/*            </NavLink>*/}
                        {/*        </div>*/}


                        {/*    </Breadcrumbs>*/}

                        {/*</>) : (<></>)}*/}



                        {sidebarUserRole == 'CLIENT' ? (<>
                           
                            <Typography variant="h6" noWrap component="div" sx={{
                                fontWeight: 700,
                                fontSize: '16px',
                                color: '#262626', mr: 108, ml: '1.5rem', display: { xs: "none", md: "flex", },
                                ...(open && { ml: 0 })
                            }}>
                                {sidebarTitle}
                            </Typography>
                          
                            <Typography variant="h6" noWrap component="div" sx={{ fontWeight: 700, fontSize: '16px', color: '#262626', mr: 15, display: { xs: "flex", md: "none" } }}>
                                {sidebarTitle}
                            </Typography>
                        </>) : (<></>)}
                       
                        {currentRouteLocation !== '/processing' ? (<>
                        <Box
                            style={{ marginRight: "auto" }}
                            justifyContent="flex-end"
                            sx={{ flexGrow: 1, display: { xs: "none", md: "flex" }, mr: 5 }}
                        >

                            {isAuthenticated ? (<>
                                <Tooltip title="Account settings">
                                    <IconButton
                                        onClick={handleOpenAccountMenu}
                                        size="small"
                                        sx={{ ml: 2 }}
                                        aria-controls={openAccountMenu ? 'account-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={openAccountMenu ? 'true' : undefined}
                                    >
                                        {sidebarLogoutMode ? (<>
                                            <SkeletonTheme baseColor="#EFF6FF" highlightColor="ffffff">
                                                <Skeleton circle={true} height={40} width={40} style={{ marginLeft: '0', marginRight: 'auto' }} />
                                            </SkeletonTheme>
                                        </>) : (<>
                                                <Avatar {...getAccountBadge()} />
                                        </>)}
                                       
                                          {/*  <Typography sx={{ fontSize: '14px' }} >*/}
                                              
                                           {/* </Typography>*/}
                                      {/*  </Avatar>*/}
                                    </IconButton>
                                </Tooltip></>) : (<></>)}

                            </Box>
                        </>) : (<></>)}
                       
                        <Box
                            style={{ marginLeft: "auto", position: 'sticky' }}
                            justifyContent="flex-end"
                            sx={{ flexGrow: 0, display: { xs: "flex", md: "none" }, mr: 5 }}>
                            <Tooltip title="Open Navigation menu">
                                <IconButton
                                    onClick={handleOpenUserMenu}
                                    sx={{ p: 0, color: "#23549F", fontWeight: "bold", }}
                                >
                                    <MdMenu />
                                </IconButton>

                            </Tooltip>
                            <Menu
                                sx={{ mt: "45px" }}
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={handleCloseUserMenu}
                            >

                              

                                <MenuItem>
                                    <Typography textAlign="center" color="#23549F">
                                        BTN A
                                    </Typography>
                                </MenuItem>

                                <MenuItem>
                                    <Typography textAlign="center" color="#23549F">
                                        BTN B
                                    </Typography>
                                </MenuItem>




                               
                            </Menu>
                        </Box>
                    </Toolbar>
                </AppBar>
                {/*<Drawer variant="permanent" open={open} PaperProps={{*/}
                {/*    sx: {*/}
                {/*        backgroundColor: '#FFFFFF'*/}
                {/*    }*/}
                {/*}} >*/}
            
                <Drawer variant={open ? ("temporary"): ("permanent")} open={open} PaperProps={{
                        sx: {
                            backgroundColor: '#FFFFFF'
                        }
                    }} >
                    <DrawerHeader>
                        <Avatar src={bmilogo}
                            alt="BMI Audit Services logo"
                            aria-label="BMI Audit Services logo"
                            variant="square" sx={{ mr: 'auto' }} />
                       
                    </DrawerHeader>
                    <Divider style={{ background: '#E5E5E5' }} />
                    <List>
                        {routes.map((item, index) => (
                            <Tooltip title={open ? ('') : (item.label) } placement="right">
                            <ListItem
                                key={`${item?.id}-DesktopListItem`}
                                disablePadding sx={{
                                    display: 'block',
                                    pl: open ? (item?.isNested ? 2 : 1) : (1),
                                }}
                                component={NavLinkWrapper}
                                item={item}
                                activeclassname={'active-navLink border-bottom__thick-blue'}
                            >

                                <ListItemButton
                                    key={`${item?.id}-DesktopButton`}
                                    selected={item?.isActive /*item?.isActive ? 'active-navLink border-bottom__thick-blue' : ""*/}
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: open ? 'initial' : 'center',
                                        px: 2.5,
                                        pl: open ? (item?.isNested ? 2 : 1) : (1),
                                        "&.Mui-selected": {
                                            backgroundColor: '#EFF6FF',
                                            //borderRight: 1,
                                            //borderColor: '#1E40AF',
                                            //borderWidth: "2px",
                                           

                                        },
                                        "&.Mui-focusVisible": {
                                            backgroundColor: '#EFF6FF'
                                        },
                                        ":hover": {
                                            backgroundColor: '#EFF6FF'
                                        },
                                        ":active": {
                                            backgroundColor: '#EFF6FF'
                                        }
                                    }}
                                >
                                    <ListItemIcon
                                        key={`${item?.id}-DesktopIcon`}
                                        sx={{
                                            color: item?.isActive ? '#1E40AF' : 'inherit',
                                            minWidth: 0,
                                            mr: open ? 3 : 'auto',
                                            justifyContent: 'center',
                                            pl: open ? (item?.isNested ? 2 : 1) : (1),
                                        }}
                                    >
                                        {item.icon}
                                    </ListItemIcon>
                                    <ListItemText key={`${item?.id}-DesktopText`} primary={item.label} sx={{ color: item?.isActive ? '#1E40AF' : 'inherit', opacity: open ? 1 : 0 }} />
                                </ListItemButton>

                            </ListItem>
                            </Tooltip>
                        ))}
                    </List>
                  
                    <List
                        style={{ marginTop: 'auto' }}
                    >
                        <ListItem key={'vNumberDesktop'} disablePadding sx={{ display: 'block' }}>

                           
                            <ListItemText
                                primaryTypographyProps={{ fontSize: '12px' }} 
                                primary={"Version: 1.2.0"} sx={{ ml: '1rem', fontWeight: '400', color: '#525252', justifyContent: open ? 'initial' : 'center', opacity: open ? 1 : 0 }} />
                           

                        </ListItem>
                       
                        <Divider style={{ background: '#FFFFFF' }} />
                        <Tooltip title={open ? ('Close Sidebar') : ('Open Sidebar')} placement="right">
                            <ListItem disablePadding sx={{
                                display: 'block',
                            }}
                            >
                                <ListItemButton
                                    aria-label={open ? ('Close Sidebar') : ('Open Sidebar')}
                                    onClick={handleDrawerToggleListItemDesktop}
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: open ? 'initial' : 'center',
                                        px: 2.5,
                                        "&.Mui-selected": {
                                            backgroundColor: '#EFF6FF'
                                        },
                                        "&.Mui-focusVisible": {
                                            backgroundColor: '#EFF6FF'
                                        },
                                        ":hover": {
                                            backgroundColor: '#EFF6FF'
                                        },
                                        ":active": {
                                            backgroundColor: '#EFF6FF'
                                        }
                                    }}
                                >
                                    <ListItemIcon
                                        sx={{

                                            color: 'inherit',
                                            minWidth: 0,
                                            ml: open ? 'auto' : 'auto',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        {open ? (<MdKeyboardDoubleArrowLeft style={{
                                            height: '24px',
                                            width: '24px',
                                            color: '#1E40AF'
                                        }} />) : (<MdKeyboardDoubleArrowRight style={{
                                            height: '24px',
                                            width: '24px',
                                        }} />)}

                                    </ListItemIcon>
                                </ListItemButton>
                            </ListItem>
                        </Tooltip>
                        {accountRoutes.map((item, index) => (
                            <ListItem key={item} disablePadding sx={{ display: 'block' }}>
                                <NavLink tag={Link} to={item.path} >
                                    <ListItemButton
                                        sx={{
                                            minHeight: 48,
                                            justifyContent: open ? 'initial' : 'center',
                                            px: 2.5,
                                        }}
                                    >
                                        <ListItemIcon
                                            sx={{
                                                color: '#ffffff',
                                                minWidth: 0,
                                                mr: open ? 3 : 'auto',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            {item.icon}
                                        </ListItemIcon>
                                        <ListItemText primary={item.label} sx={{ color: '#ffffff', opacity: open ? 1 : 0 }} />
                                    </ListItemButton>
                                </NavLink>
                            </ListItem>
                        ))}
                       
                    </List>
                </Drawer>
            </Box>
           
  
            {/* //DESKTOP - Profile Badge Menu for Logout and My Account access*/}

            <Menu
                anchorEl={accountMenuAnchorEl}
                id="account-menu"
                open={openAccountMenu}
                onClose={handleCloseAccountMenu}
                onClick={handleCloseAccountMenu}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >



                <MenuItem onClick={handleLogout}>
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>

            {/* SWITCH CLIENT MENU POPPER*/}

            <Popper
                style={{ zIndex: 1203 }}
                open={openSwitchClientPopper}
                anchorEl={switchClientAnchorEl}
                placement={"bottom-start"}
                sx={{ border: '1px solid #E5E5E5', borderRadius: '12px' }}
            >
                <Box
                    sx={{
                        width: "290px",
                        height: "400px",
                        p: 1,
                        bgcolor: "background.paper",
                        border: '1px solid #E5E5E5', borderRadius: '12px'
                    }}
                >
                    <Container sx={{ ml: '-0.5rem' }}>
                        <Box
                            sx={{
                                width: "258px",
                                height: "48px",
                                bgcolor: "transparent",
                                mr: 0,
                                mb: 1,
                            }}
                        >
                            <IconButton
                                onClick={handleCloseSwitchClientPopper}
                                sx={{}}
                                aria-haspopup="true"
                            >
                                <PiArrowLeftBold style={{ height: '16px', width: '16px' }} />
                            </IconButton>
                            <Typography fontWeight="600" fontSize="0.8rem" align="center" sx={{ mt: '-1.75rem', lineHeight: '20px' }}>
                                Switch client
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                width: "258px",
                                height: "48px",
                                bgcolor: "transparent",
                                mr: 0,
                                mb: 1,
                            }}
                        >
                            <TextField
                                onFocus={() => setDisplayFilteredClients(true)}
                                placeholder="Search for client"
                                value={clientSearchFilterInput}
                                onChange={(e) => searchClients(e.target.value)}
                                sx={{ width: '100%', bgcolor: '#FAFAFA', border: 'none', "& fieldset": { border: 'none' } }}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><BsSearch /></InputAdornment>,
                                }}
                            />
                        </Box>
                        {displayFileredClients == true ? (<>
                            <Box
                                sx={{
                                    width: "258px",
                                    height: "28px",
                                    bgcolor: "transparent",
                                    mr: 0,
                                    mb: 1,
                                }}
                            >
                                <Typography fontWeight="600" fontSize="0.75rem" align="left" sx={{ ml: 2, mt: 1.5, color: '#737373', lineHeight: '20px' }}>
                                    Client Results
                                </Typography>
                            </Box>
                            {filteredClients?.slice(0, 5)?.map((item) => {
                                return (
                                    <Box
                                        sx={{
                                            width: "258px",
                                            height: "48px",
                                            bgcolor: "transparent",
                                            mr: 0,
                                            mb: '6px',
                                            ml: '1.25rem',
                                        }}
                                    >
                                        <Box
                                            component="div"
                                            sx={{
                                                width: "215px",
                                                height: "48px",
                                                bgcolor: "transparent",
                                                mr: 0,
                                                wordWrap: "break-word !important",
                                                whiteSpace: 'normal',
                                            }}
                                        >
                                            <NavLink
                                                onClick={handleCloseSwitchClientAndResetDateFilterPopper}
                                                underline="hover" color="inherit" to={`/report/${String(item?.auditId)}/${item?.auditName}`}
                                                style={{ textDecoration: 'none', color: '#000000', fontSize: '12px', fontWeight: 700, textWrap: 'wrap', overflowWrap: 'anywhere', whiteSpace: 'normal' }}>
                                                {item?.clientName}
                                            </NavLink>
                                        </Box>
                                        <Box
                                            sx={{
                                                width: "32px",
                                                height: "32px",
                                                bgcolor: "transparent",
                                                mr: 0,
                                                mt: '-2.75rem',
                                                marginLeft: 'auto',
                                            }}
                                        >
                                            <ChevronRightIcon style={{
                                                color: '#A3A3A3',
                                                height: '16px',
                                                width: '16px',



                                            }} />
                                        </Box>
                                       
                                    </Box>
                                )
                            })}
                        </>) : (<>
                            <Box
                                sx={{
                                    width: "258px",
                                    height: "28px",
                                    bgcolor: "transparent",
                                    mr: 0,
                                    mb: 1,
                                }}
                            >
                                <Typography fontWeight="600" fontSize="0.75rem" align="left" sx={{ ml: 2, mt: 1.5, color: '#737373', lineHeight: '20px' }}>
                                    Recent Clients
                                </Typography>

                            </Box>
                            {clientSearchData?.slice(0, 5)?.map((item) => {
                                return (
                                    <Box
                                        key={item?.auditName}
                                        component="div"
                                        sx={{
                                            width: "258px",
                                            height: "48px",
                                            bgcolor: "transparent",
                                            mr: 0,
                                            mb: '6px',
                                            ml: '1.25rem',

                                        }}
                                    >
                                        <Box
                                            component="div"
                                            sx={{
                                                width: "215px",
                                                height: "48px",
                                                bgcolor: "transparent",
                                                mr: 0,
                                                wordWrap: "break-word !important",
                                                whiteSpace: 'normal',
                                            }}
                                        >
                                            <NavLink
                                                key={item?.auditName}
                                                onClick={handleCloseSwitchClientPopper}
                                                underline="hover" color="inherit" to={`/report/${String(item?.auditId)}/${item?.auditName}`}
                                                style={{ textDecoration: 'none', color: '#000000', fontSize: '12px', fontWeight: 700, textWrap: 'wrap', overflowWrap: 'anywhere', whiteSpace: 'normal' }}>
                                                {item?.clientName}
                                            </NavLink>
                                        </Box>
                                        <Box
                                            sx={{
                                                width: "32px",
                                                height: "32px",
                                                bgcolor: "transparent",
                                                mr: 0,
                                                mt: '-2.75rem',
                                                marginLeft: 'auto',
                                            }}
                                        >
                                            <ChevronRightIcon style={{
                                                color: '#A3A3A3',
                                                height: '16px',
                                                width: '16px',

                                            }} />
                                        </Box>
                                       
                                    </Box>
                                )
                            })}
                        </>)}
                    </Container>
                </Box>
            </Popper>
        </>
    );
}

export default Sidebar;

