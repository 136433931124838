

import React, { useContext, useEffect } from 'react';

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from '@mui/material/Grid';
import { MdStar, MdStarBorder } from "react-icons/md";
import IconButton from '@mui/material/IconButton';
import Container from "@mui/material/Container";
import Tooltip from "@mui/material/Tooltip";



import { useParams, useNavigate, Navigate, Link } from "react-router-dom";

import QueryStatsIcon from '@mui/icons-material/QueryStats';
import SearchIcon from '@mui/icons-material/Search';




import { GraphDetailsContext } from '../../App';
import IneligibleReasonBarChart from '../graphs/IneligibleReasonBarChart';


function GraphCardWideIneligibleBarChart({ graphName, graphComponent, graphLink, desiredCardWidth, desiredCardHeight, graphLegendComponent, graphLegendComponent2, graphData, graphTableData, graphLegendStyle }) {

    const {
        graphComponentData,
        handleUpdateGraphComponentData,
        graphTableComponentData,
        handleUpdateGraphTableComponentData
    } = useContext(GraphDetailsContext);

    let copyOfGraphData = graphData;

    let splitLegend1 = [];
    let splitLegend2 = [];
    let splitLegend3 = [];
    let splitLegend4 = [];

    let legendLength = graphData.length;
    let i = 0;
    ///////SPLITS into 4 sets of 4
    while (i < legendLength) {
        if (i <= 3) {
            splitLegend1 = [...splitLegend1, copyOfGraphData[i]]
        } else if (i >= 4 && i <= 7) {
            splitLegend2 = [...splitLegend2, copyOfGraphData[i]]
        } else if (i >= 8 && i <= 11) {
            splitLegend3 = [...splitLegend3, copyOfGraphData[i]]
        } else if (i >= 12 && i <= 14) {
            splitLegend4 = [...splitLegend4, copyOfGraphData[i]]
        }
        i++;

    }
   

    //function getSplitLegends() {
    //    console.log("RUN getSplitLegends")
       
    //}


    //useEffect(() => {
    //    getSplitLegends();
    //}, [graphData])
    

   
    console.log("Split1: ", splitLegend1)
    console.log("Split2: ", splitLegend2)
    console.log("Split3: ", splitLegend3)
    console.log("Split4: ", splitLegend4)

    const navigate = useNavigate();

    let stateObj = { graphname: graphName, graphcomponent: graphComponent, graphlegend: graphLegendComponent }

    /*let stringedStateObj = JSON.stringify(stateObj)*/

    const handleNavigateToProject = (params) => {
        if (graphData !== null) {
            handleUpdateGraphComponentData(graphData)
        }
        if (graphTableData !== null) {
            handleUpdateGraphTableComponentData(graphTableData)
        }
        /* console.log(params);*/

        if (graphLink !== null) {
            navigate(graphLink, { state: { graph_title: graphName } });
        }

        /* navigate(`report/:id/:auditName/search/`);*/

    };

    return (
        <>
            <Card style={{ height: '90%' }} sx={{ mr: 1.5, minWidth: desiredCardWidth ? (desiredCardWidth) : (75), minHeight: desiredCardHeight ? (desiredCardHeight) : (400), width: desiredCardWidth ? (desiredCardWidth) : (400) }}>



                <Typography sx={{}} textAlign="center" variant="h6" fontWeight="600" fontSize="1.55em" component="div">
                    {graphName} {graphLink !== null ? (<>
                        <Tooltip title={"Search Graph Details"}
                            placement="top">
                            <IconButton
                                sx={{ mt: 0, bgcolor: "#EFF6FF" }}
                                onClick={() => handleNavigateToProject()}
                            >
                                <SearchIcon />
                            </IconButton>
                        </Tooltip>
                    </>) : (<></>)}
                </Typography>
                {/*<div className="row-no-left-margin">*/}

                {/*    <div className="row-right">*/}




                {/*    </div>*/}

                {/*</div>*/}
                <Container sx={{ ml: '-0.5VW', mb: '-0.5VH', mt: '-6.5VH' }}>
                
                        

                    {/*        {splitLegend1.length !== 0 ? (*/}
                    {/*            <Grid item xs={3} >*/}
                    {/*                <Box sx={{ mt: 6, mb: 6, }}>*/}
                    {/*                    <IneligibleReasonBarChart*/}
                    {/*                        percentageBarChartData={ //needs to be dynamic list*/}
                    {/*                            splitLegend1*/}
                    {/*                        }*/}
                    {/*                    />*/}
                    {/*                </Box>*/}
                    {/*            </Grid>) : (<></>)}*/}
                    {/*        {splitLegend2.length !== 0 ? (*/}
                    {/*            <Grid item xs={3} >*/}
                    {/*                <Box sx={{ mt: 6, mb: 6, }}>*/}
                    {/*                    <IneligibleReasonBarChart*/}
                    {/*                        percentageBarChartData={ //needs to be dynamic list*/}
                    {/*                            splitLegend2*/}
                    {/*                        }*/}
                    {/*                    />*/}
                    {/*                </Box>*/}
                    {/*            </Grid>) : (<></>)}*/}
                    {/*        {splitLegend3.length !== 0 ? (*/}
                    {/*            <Grid item xs={3} >*/}
                    {/*                <Box sx={{ mt: 6, mb: 6, }}>*/}
                    {/*                    <IneligibleReasonBarChart*/}
                    {/*                        percentageBarChartData={ //needs to be dynamic list*/}
                    {/*                            splitLegend3*/}
                    {/*                        }*/}
                    {/*                    />*/}
                    {/*                </Box>*/}
                    {/*            </Grid>) : (<></>)}*/}


                    {/*        {splitLegend4.length !== 0 ? (*/}
                    {/*            <Grid item xs={3} >*/}
                    {/*                <Box sx={{ mt: 6, mb: 6, }}>*/}
                    {/*                    <IneligibleReasonBarChart*/}
                    {/*                        percentageBarChartData={ //needs to be dynamic list*/}
                    {/*                            splitLegend4*/}
                    {/*                        }*/}
                    {/*                    />*/}
                    {/*                </Box>*/}
                    {/*            </Grid>) : (<></>)}*/}


                    {/*    </Grid>*/}
                    {/*</>) : (<></>)}*/}

                   
                        <Grid container spacing={4} sx={{ mt: 0, mb: 0, display: { xs: 'none', md: 'flex', xl: 'flex' } }}>

                            {splitLegend1.length !== 0 && splitLegend2.length == 0  ? (
                                <Grid item xs={12} >
                                    <Box sx={{ mt: 6, mb: 6, }}>
                                        <IneligibleReasonBarChart
                                            percentageBarChartData={ //needs to be dynamic list
                                                splitLegend1
                                            }
                                        />
                                    </Box>
                                </Grid>) : (<></>)}
                            {splitLegend2.length !== 0 && splitLegend3.length == 0 ? (<>
                                <Grid item xs={6} >
                                    <Box sx={{ mt: 6, mb: 6, }}>
                                        <IneligibleReasonBarChart
                                            percentageBarChartData={ //needs to be dynamic list
                                                splitLegend1
                                            }
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={6} >
                                    <Box sx={{ mt: 6, mb: 6, }}>
                                        <IneligibleReasonBarChart
                                            percentageBarChartData={ //needs to be dynamic list
                                                splitLegend2
                                            }
                                        />
                                    </Box>
                                </Grid>
                                </>
                               ) : (<></>)}
                            {splitLegend3.length !== 0 && splitLegend4.length == 0 ? (
                                <>
                                    <Grid item xs={4} >
                                        <Box sx={{ mt: 6, mb: 6, }}>
                                            <IneligibleReasonBarChart
                                                percentageBarChartData={ //needs to be dynamic list
                                                    splitLegend1
                                                }
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={4} >
                                        <Box sx={{ mt: 6, mb: 6, }}>
                                            <IneligibleReasonBarChart
                                                percentageBarChartData={ //needs to be dynamic list
                                                    splitLegend2
                                                }
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={4} >
                                        <Box sx={{ mt: 6, mb: 6, }}>
                                            <IneligibleReasonBarChart
                                                percentageBarChartData={ //needs to be dynamic list
                                                    splitLegend3
                                                }
                                            />
                                        </Box>
                                    </Grid>
                                </>
                              ) : (<></>)}


                            {splitLegend4.length !== 0 ? (
                                <>
                                 <Grid item xs={3} >
                                        <Box sx={{ mt: 6, mb: 6, }}>
                                            <IneligibleReasonBarChart
                                                percentageBarChartData={ //needs to be dynamic list
                                                    splitLegend1
                                                }
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={3} >
                                        <Box sx={{ mt: 6, mb: 6, }}>
                                            <IneligibleReasonBarChart
                                                percentageBarChartData={ //needs to be dynamic list
                                                    splitLegend2
                                                }
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={3} >
                                        <Box sx={{ mt: 6, mb: 6, }}>
                                            <IneligibleReasonBarChart
                                                percentageBarChartData={ //needs to be dynamic list
                                                    splitLegend3
                                                }
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={3} >
                                        <Box sx={{ mt: 6, mb: 6, }}>
                                            <IneligibleReasonBarChart
                                                percentageBarChartData={ //needs to be dynamic list
                                                    splitLegend4
                                                }
                                            />
                                        </Box>
                                    </Grid>
                                </>
                                ) : (<></>)}


                        </Grid>
                


                
                </Container>













            </Card>
        </>
    );
}

export default GraphCardWideIneligibleBarChart;


