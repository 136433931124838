
import React, { useState, useEffect, PureComponent, useContext, useCallback, useRef, forwardRef } from 'react';
import { useParams, useNavigate, Navigate, Link, redirect } from "react-router-dom";
import { Bar } from '@nivo/bar';
import { ResponsivePie } from '@nivo/pie';
import { ResponsiveCalendar } from '@nivo/calendar';
import { BsCheckLg, BsXLg, BsExclamationCircleFill } from 'react-icons/bs';
import bmilogo from '../assets/bmi-logo-cross-transparent.png';
import Avatar from '@mui/material/Avatar';
import { RxCross2 } from "react-icons/rx";
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';

import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro';
/*import { DataGrid, useGridApiRef } from '@mui/x-data-grid';*/
import Select from '@mui/material/Select';

import MenuItem from "@mui/material/MenuItem";
import { BsBoxArrowLeft } from "react-icons/bs";
import ListItem from '@mui/material/ListItem';
import MuiListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import TextField from "@mui/material/TextField";
import { debounce } from "@mui/material/utils";
import InputAdornment from '@mui/material/InputAdornment';
import { MdSearch } from "react-icons/md";
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

import Chip from '@mui/material/Chip';
import { format, formatISO } from 'date-fns'
import { IoMdArrowDropright, IoMdArrowDropdown } from "react-icons/io";

import Box from '@mui/material/Box';
import Container from "@mui/material/Container";
import Typography from '@mui/material/Typography';
import Grid from "@mui/material/Grid";
import ProjectCard from "../components/cards/ProjectCard";
import ProjectTable from "../components/tables/ProjectTable";
import MultiBarChart from "../components/graphs/MultiBarChart";
import Tooltip from "@mui/material/Tooltip";
import GraphCard from "../components/cards/GraphCard";
import PieGraph from "../components/graphs/PieGraph";
import IneligibleReasonBarChart from "../components/graphs/IneligibleReasonBarChart";
import IneligibleExtendedGraphCard from "../components/cards/IneligibleExtendedGraphCard";
import { RolesContext, SidebarContext, PersistentPreviousSearchContext } from "../App";
import GraphLegendCard from '../components/cards/GraphLegendCard';
import PercentageBarChart from "../components/graphs/PercentageBarChart";
import HomeIcon from '@mui/icons-material/Home';
import BarChartIcon from '@mui/icons-material/BarChart';
import GroupIcon from '@mui/icons-material/Group';
import { RoutesContext, PermissionsContext } from "../App";
import Button from '@mui/material/Button';
import { ExportToCsv } from 'export-to-csv';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { loginRequest, b2cPolicies } from "../authConfig";
import { useMsal, MsalAuthenticationTemplate, useIsAuthenticated } from '@azure/msal-react';
import { InteractionType, InteractionStatus, InteractionRequiredAuthError } from '@azure/msal-browser';
import useFetch from "../hooks/useFetch";
import Badge from '@mui/material/Badge';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';

import IconButton from '@mui/material/IconButton';

import { MdFilterList } from "react-icons/md";

import { styled, useTheme } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';


import Menu from '@mui/material/Menu';


import { CgOptions } from "react-icons/cg";
import { IoOptionsOutline } from "react-icons/io5";

import Switch from "@mui/material/Switch";

import FormGroup from "@mui/material/FormGroup";

import FormControlLabel from "@mui/material/FormControlLabel";

import FormLabel from "@mui/material/FormLabel";



import { DateField } from '@mui/x-date-pickers/DateField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

//import { TbWorldSearch } from "react-icons/tb";
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionActions from '@mui/material/AccordionActions';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';










//Employee Search page
function GlobalEmployeeSearch({ onChange, children }) {

    const { userPersistentPreviousSearchGlobal,
        setUserPersistentPreviousSearchGlobal,
        handleUpdatePersistentPreviousSearchParametersGlobal,
        userPrintablePersistentPreviousGlobalString,
        setUserPrintablePersistentPreviousGlobalString,
        handleUpdatePrintablePersistentPreviousGlobalString
    } = useContext(PersistentPreviousSearchContext);

    function getPreviousSearchFieldName(field) {


        if (field === "last_name") {
            return 'LAST NAME: '
        } else if (field === "first_name") {
            return 'FIRST NAME: '
        } else if (field === "audit_family_id") {
            return 'AUDIT FAMILY ID: '
        } else if (field === "date_of_birth") {
            return 'DATE OF BIRTH: '
        } else if (field === "email") {
            return 'EMAIL: '
        } else if (field === "client") {
            return 'CLIENT NAME: '
        } else {
            return field;
        }
    }

    const [pleaseTryAgain, setPleaseTryAgain] = useState(false)

    function StyledLoadingOverlay() {
        return (
            <Box sx={{ ml: 3 }}>
                <SkeletonTheme baseColor="#EFF6FF" highlightColor="ffffff">
                    <Skeleton height={510} width={'75VW'} />
                </SkeletonTheme>
            </Box>
        );
    }

    const StyledGridOverlay = styled('div')(({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        '& .ant-empty-img-1': {
            fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
        },
        '& .ant-empty-img-2': {
            fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
        },
        '& .ant-empty-img-3': {
            fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
        },
        '& .ant-empty-img-4': {
            fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
        },
        '& .ant-empty-img-5': {
            fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
            fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
        },
    }));

    function CustomNoRowsOverlay() {
        if (pleaseTryAgain !== true) {
            return (
                <StyledGridOverlay>

                    <Box sx={{ mt: 1, fontWeight: 'bold' }}>Member/Employee Search Report</Box>
                    <Box sx={{ mt: 1 }}>Use the form above to generate a report</Box>
                </StyledGridOverlay>
            );
        } else {
            return (
                <StyledGridOverlay>

                    <Box sx={{ mt: 1, fontWeight: 'bold' }}>Sorry, that search did not yield any results.</Box>
                    <Box sx={{ mt: 1 }}>Please try again with different Search Parameters</Box>
                </StyledGridOverlay>
            );
        }

    }

    function CustomNoResultsOverlay() {
        return (
            <StyledGridOverlay>

                <Box sx={{ mt: 1, fontWeight: 'bold' }}>Sorry, that search didn�t yield any results.</Box>
                <Box sx={{ mt: 1 }}>Please try again with different Search Parameters</Box>
            </StyledGridOverlay>
        );
    }

    function renderDate(date) {
        if (!date) {
            return '';
        }
        return format(new Date(date), 'MM/dd/yyyy');
    }

    function convertUserProvidedDate(date) {
        if (!date) {
            return '';
        }
        return formatISO(new Date(date), { representation: 'date' })
    }

    const failedFetchError = (status) => {

        toast.error(String(status) + " error: Please refresh and try again.", {
            position: "bottom-center",
            theme: "colored",
            autoClose: false,
            closeOnClick: true,
            toastId: "failedToFetch"
        });


    }




    const dataGridColumns = [
       
        {
            "field": "audit_family_id",
            "headerName": "Audit ID",
            "width": 170
        },
       
        {
            "field": "first_name",
            "headerName": "Member/Employee First Name",
            "width": 250,
            "editable": false
        },
        {
            "field": "last_name",
            "headerName": "Member/Employee Last Name",
            "width": 250,
            "editable": false
        },
        {
            "field": "date_of_birth",
            "headerName": "Date of Birth",
            "width": 180,
            "editable": false,
            renderCell: (params) =>
                renderDate(params.row?.date_of_birth)
        },
        {
            "field": "email",
            "headerName": "Email",
            "width": 250,
            "editable": false
        },
        {
            "field": "relationship",
            "headerName": "Relationship",
            "width": 150,
            "editable": false
        },
       
        {
            "field": "address",
            "headerName": "Address",
            "width": 475,
            "editable": false
        },
       
        {
            "field": "audit_end_date",
            "headerName": "End Date",
            "width": 150,
            "editable": false,
            renderCell: (params) =>
                renderDate(params.row?.audit_end_date)

        },
        {
            "field": "client",
            "headerName": "Client",
            "width": 280,
            "editable": false
        },
        {
            "field": "other",
            "headerName": "_",
            "width": 260,
            valueGetter: (value, row) => {
                console.log('row', row);
                return JSON.stringify({
                    dependent_first_names: row.dependent_data.map((item) => item.dependent_first_name),
                    dependent_last_names: row.dependent_data.map((item) => item.dependent_last_name),
                    dependent_dates_of_birth: row.dependent_data.map((item) => item.dependent_date_of_birth),
                    dependent_relationships: row.dependent_data.map((item) => item.dependent_relationship),

                });
            },
        },
    ];

    const dataGridColumnsMediumOrSmall = [
        {
            "field": "audit_family_id",
            "headerName": "Audit Family",
            "width": 90
        },
        {
            "field": "first_name",
            "headerName": "Member/Employee First Name",
            "width": 120,
            "editable": false
        },
        {
            "field": "last_name",
            "headerName": "Member/Employee Last Name",
            "width": 150,
            "editable": false
        },
        {
            "field": "relationship",
            "headerName": "Relationship",
            "width": 100,
            "editable": false
        },
        {
            "field": "date_of_birth",
            "headerName": "DOB",
            "width": 160,
            "editable": false,
            renderCell: (params) =>
                renderDate(params.row?.date_of_birth)
        },
        {
            "field": "address",
            "headerName": "Address",
            "width": 450,
            "editable": false
        },
        {
            "field": "email",
            "headerName": "Email",
            "width": 230,
            "editable": false
        },
        {
            "field": "audit_end_date",
            "headerName": "End Date",
            "width": 150,
            "editable": false,
            renderCell: (params) =>
                renderDate(params.row?.audit_end_date)

        },
        {
            "field": "other",
            "headerName": "_",
            "width": 260,
            valueGetter: (value, row) => {
                console.log('row', row);
                return JSON.stringify({
                    dependent_first_names: row.dependent_data.map((item) => item.dependent_first_name),
                    dependent_last_names: row.dependent_data.map((item) => item.dependent_last_name),
                    dependent_dates_of_birth: row.dependent_data.map((item) => item.dependent_date_of_birth),
                    dependent_relationships: row.dependent_data.map((item) => item.dependent_relationship),

                });
            },
        },
    ];

    /* const dataGridRows = [{}]*/

    //const dataGridColumns = [{
    //    "field": "id",
    //    "hide": true
    //},
    //    {
    //        "field": "desk",
    //        "headerName": "Desk",
    //        "width": 110
    //    },
    //    {
    //        "field": "commodity",
    //        "headerName": "Commodity",
    //        "width": 180,
    //        "editable": false
    //    },
    //    {
    //        "field": "traderName",
    //        "headerName": "Trader Name",
    //        "width": 120,
    //        "editable": false
    //    },
    //    {
    //        "field": "traderEmail",
    //        "headerName": "Trader Email",
    //        "width": 150,
    //        "editable": false
    //    },
    //    {
    //        "field": "quantity",
    //        "headerName": "Quantity",
    //        "type": "number",
    //        "width": 140,
    //        "editable": false
    //    }];






    const [paginationModel, setPaginationModel] = React.useState({
        page: 0,
        pageSize: 10,
    });

    const apiRef = useGridApiRef();

    //const updateSearchSelectFilterValue = (newValue) => {
    //    console.log("newValue: ", newValue)
    //    if (newValue !== "") {
    //        apiRef.current.setFilterModel({
    //            items: [
    //                {
    //                    id: 1,
    //                    operator: 'contains',
    //                    field: "client",
    //                    value: newValue,
    //                },
    //            ],
    //        });
    //    }

    //};



    //const handleSelectFilterChange = (event) => {
    //    const newValue = event.target.value;
    //    setSelectFilterValue(newValue);
    //    updateSearchSelectFilterValue(newValue);

    //};
    const [displayTableLoadingAnimation, setDisplayTableLoadingAnimation] = useState(false);

    const [previousSearchParameters, setPreviousSearchParameters] = useState(userPersistentPreviousSearchGlobal?.length !== 0 ? userPersistentPreviousSearchGlobal : []);

    function handleUpdatePreviousSearchParameters(updatedPreviousSearchParameters) {
        setPreviousSearchParameters(updatedPreviousSearchParameters);
    }

    const [printablePersistentPreviousSearch, setPrintablePersistentPreviousSearch] = useState(userPrintablePersistentPreviousGlobalString?.length !== 0 ? userPrintablePersistentPreviousGlobalString : []);

    const [previousSearchLogicalOperatorParameter, setPreviousSearchLogicalOperatorParameter] = useState("");

    function handleUpdatePreviousSearchLogicalOperatorParameter(updatedPreviousSearchLogicalOperatorParameter) {
        setPreviousSearchLogicalOperatorParameter(updatedPreviousSearchLogicalOperatorParameter);

    }

    const [employeeOnlySearch, setEmployeeOnlySearch] = React.useState(false);

    function handleUpdateEmployeeOnlySearch(newStatus) {
        setEmployeeOnlySearch(newStatus)
    }

    const [previousSearchWasEmployeeOnly, setPreviousSearchWasEmployeeOnly] = useState(false);

    function handleUpdatePreviousSearchWasEmployeeOnly(updatedWasEmployeeOnlyStatus) {

        setPreviousSearchWasEmployeeOnly(updatedWasEmployeeOnlyStatus);
    }




    const [mainSearchFilterModel, setMainSearchFilterModel] = React.useState([]);
    const [mainSearchFilterModelLogicalOperator, setMainSearchFilterModelLogicalOperator] = React.useState("");

    const [mainSearchFilterModelNetworkData, setMainSearchFilterModelNetworkData] = useState([]);

    const [searchFirstNameExactMatch, setSearchFirstNameExactMatch] = React.useState(false);

    function handleUpdateSearchFirstNameExactMatch(newFirstExactMatchStatus) {
        setSearchFirstNameExactMatch(newFirstExactMatchStatus);
    }

    const [searchLastNameExactMatch, setSearchLastNameExactMatch] = React.useState(false);

    function handleUpdateSearchLastNameExactMatch(newLastExactMatchStatus) {
        setSearchLastNameExactMatch(newLastExactMatchStatus);
    }

    const [generateNewSearchFilterModel, setGenerateNewSearchFilterModel] = React.useState(false);

    const [searchValueAuditID, setSearchValueAuditID] = React.useState("");
    const [savedLastSearchValueAuditID, setSavedLastSearchValueAuditID] = React.useState("");

    function handleSearchValueAuditIDChange(event) {

        setSearchValueAuditID(event.target.value);

        //if (generateNewSearchFilterModel === true) {
        //    const newFilterParameterItem = {
        //        FieldType: "audit_id",
        //        SearchValue: searchValueAuditID,
        //        OperatorValue: 'contains'
        //    };


        //    handleUpdateMainSearchFilterModel(newFilterParameterItem);

        //}

    }


    const [searchValueClient, setSearchValueClient] = React.useState("");

    function handleSearchValueClientChange(event) {

        setSearchValueClient(event.target.value);

        //if (generateNewSearchFilterModel == true) {
        //    const newFilterParameterItem = {
        //        FieldType: "client",
        //        SearchValue: searchValueClient,
        //        OperatorValue: 'contains'
        //    };


        //    handleUpdateMainSearchFilterModel(newFilterParameterItem);
        //}
    }


    const [searchValueClientExactMatch, setSearchValueClientExactMatch] = React.useState(false);

    function handleUpdateSearchValueClientExactMatch(newExactMatchStatus) {
        setSearchValueClientExactMatch(newExactMatchStatus);
    }


    const [searchValueFirstName, setSearchValueFirstName] = React.useState("");

    function handleSearchValueFirstNameChange(event) {
        setSearchValueFirstName(event.target.value);
        //if (generateNewSearchFilterModel === true) {
        //    const newFilterParameterItem = {
        //        FieldType: "first_name",
        //        SearchValue: searchValueFirstName,
        //        OperatorValue: 'contains'
        //    };


        //    handleUpdateMainSearchFilterModel(newFilterParameterItem);
        //}
    }


    const [searchValueLastName, setSearchValueLastName] = React.useState("");

    function handleSearchValueLastNameChange(event) {

        setSearchValueLastName(event.target.value);

        //if (generateNewSearchFilterModel === true) {
        //    const newFilterParameterItem = {
        //        FieldType: "last_name",
        //        SearchValue: searchValueLastName,
        //        OperatorValue: 'contains'
        //    };


        //    handleUpdateMainSearchFilterModel(newFilterParameterItem);
        //}
    }

    /*const [newPreviousSarchState, setNewPreviousSearchState] = useState([]);*/


    const [searchValueDOB, setSearchValueDOB] = React.useState(null);

    function handleSearchValueDOBChange(value) {
        /* console.log("DOB CHANGE ------", event.target?.value)*/
        if (value !== null) {
            setSearchValueDOB(value);
        }


        //if (generateNewSearchFilterModel === true) {
        //    const newFilterParameterItem = {
        //        FieldType: "date_of_birth",
        //        SearchValue: searchValueDOB,
        //        OperatorValue: 'contains'
        //    };


        //    handleUpdateMainSearchFilterModel(newFilterParameterItem);
        //}
    }


    const [searchValueEmailAddress, setSearchValueEmailAddress] = React.useState("");


    function handleSearchValueEmailAddressChange(event) {

        setSearchValueEmailAddress(event.target.value);

        //if (generateNewSearchFilterModel === true) {
        //    const newFilterParameterItem = {
        //        FieldType: 'email',
        //        SearchValue: searchValueEmailAddress,
        //        OperatorValue: 'contains'
        //    };


        //    handleUpdateMainSearchFilterModel(newFilterParameterItem);
        //}
    }

    function backupPrintablePreviousSearch(searchValueAuditID,
        searchValueClient,
        searchValueClientExactMatch,
        searchValueFirstName,
        searchValueLastName,
        searchFirstNameExactMatch,
        searchLastNameExactMatch,
        searchValueDOB,
        searchValueEmailAddress,
        employeeOnlySearch
    ) {

        let normalizedPreviousSearchValueStructure = [];

        if (searchValueAuditID !== "") {
            let i = normalizedPreviousSearchValueStructure?.length;
            let newItemID = i + 1;

            const newSearchFilterModelItem = {
                id: newItemID,
                field: "audit_family_id",
                value: searchValueAuditID,
            };
            normalizedPreviousSearchValueStructure = [...normalizedPreviousSearchValueStructure, newSearchFilterModelItem];
        }
        if (searchValueClient !== "") {
            let i = normalizedPreviousSearchValueStructure?.length;
            let newItemID = i + 1;

            const newSearchFilterModelItem = {
                id: newItemID,
                field: "client",
                value: searchValueClient,
            };
            normalizedPreviousSearchValueStructure = [...normalizedPreviousSearchValueStructure, newSearchFilterModelItem];
        }
        if (searchValueFirstName !== "") {
            let i = normalizedPreviousSearchValueStructure?.length;
            let newItemID = i + 1;

            const newSearchFilterModelItem = {
                id: newItemID,
                field: "first_name",
                value: searchValueFirstName,
            };
            normalizedPreviousSearchValueStructure = [...normalizedPreviousSearchValueStructure, newSearchFilterModelItem];
        }
        if (searchValueLastName !== "") {
            let i = normalizedPreviousSearchValueStructure?.length;
            let newItemID = i + 1;

            const newSearchFilterModelItem = {
                id: newItemID,
                field: "last_name",
                value: searchValueLastName,
            };
            normalizedPreviousSearchValueStructure = [...normalizedPreviousSearchValueStructure, newSearchFilterModelItem];
        }
        if (searchValueDOB !== null) {
            let i = normalizedPreviousSearchValueStructure?.length;
            let newItemID = i + 1;



            const newSearchFilterModelItem = {
                id: newItemID,
                field: "date_of_birth",
                value: searchValueDOB,
            };
            normalizedPreviousSearchValueStructure = [...normalizedPreviousSearchValueStructure, newSearchFilterModelItem];
        }
        if (searchValueEmailAddress !== "") {
            let i = normalizedPreviousSearchValueStructure?.length;
            let newItemID = i + 1;



            const newSearchFilterModelItem = {
                id: newItemID,
                field: 'email',
                value: searchValueEmailAddress,
            };
            normalizedPreviousSearchValueStructure = [...normalizedPreviousSearchValueStructure, newSearchFilterModelItem];
        }

        handleUpdatePrintablePersistentPreviousGlobalString(normalizedPreviousSearchValueStructure)


    }

    function handleGetAndSetAllFiltersForUpdate() {

        let placeholderAuditFamilyID = "";
        let placeholderAuditID = null;

        let placeholderClientName = "";
        let placeholderClientNameExactMatch = false;
        let placeholderLastName = "";
        let placeholderLastNameExactMatch = false;
        let placeholderFirstName = "";
        let placeholderFirstNameExactMatch = false;
        let placeholderBirthdate = null;
        let placeholderEmailAddress = "";
        let placeholderEmployeeOnlySearch = false;






        apiRef.current.setFilterModel({
            items: []

        });

        let newMainSearchFilterModel = [];
        let newLogicalSearchOperator = null;


        if (searchValueAuditID !== "") {

            placeholderAuditID = searchValueAuditID;


        }
        if (searchValueClient !== "") {
            placeholderClientName = searchValueClient;
        }

        if (searchValueClientExactMatch !== false) {
            placeholderClientNameExactMatch = searchValueClientExactMatch;
        }

        if (searchValueFirstName !== "") {

            placeholderFirstName = searchValueFirstName;


        }
        if (searchValueLastName !== "") {

            placeholderLastName = searchValueLastName;

        }
        if (searchValueDOB !== null) {


            /*let convertedForSearchFilterDate = searchValueDOB;*/
            let convertedForSearchFilterDate = convertUserProvidedDate(searchValueDOB)
            console.log("ConvertedSearchDate: ", convertedForSearchFilterDate);
            placeholderBirthdate = convertedForSearchFilterDate;

        }

        if (searchValueEmailAddress !== "") {

            placeholderEmailAddress = searchValueEmailAddress;
        }

        if (searchFirstNameExactMatch !== false) {
            placeholderFirstNameExactMatch = searchFirstNameExactMatch;
        }

        if (searchLastNameExactMatch !== false) {
            placeholderLastNameExactMatch = searchLastNameExactMatch;
        }

        if (employeeOnlySearch !== false) {
            placeholderEmployeeOnlySearch = employeeOnlySearch;
        }

        let potentialRequestBody = {
          
            "auditNumber": placeholderAuditID,
            "auditId": null,
            "clientName": placeholderClientName,
            "clientNameExactMatch": placeholderClientNameExactMatch,
            "lastName": placeholderLastName,
            "lastNameExactMatch": placeholderLastNameExactMatch,
            "firstName": placeholderFirstName,
            "firstNameExactMatch": placeholderFirstNameExactMatch,
            "birthdate": placeholderBirthdate,
            "emailAddress": placeholderEmailAddress,
            "employeeOnlySearch": placeholderEmployeeOnlySearch
        }

        console.log("POTENTIAL-REQUEST-BODY ---", potentialRequestBody)

        setMainSearchFilterModel(potentialRequestBody);

        const fetchGlobalDataGridRowData = async () => {
            if (accounts.length > 0) {
                await instance.acquireTokenSilent({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) })
                    .then(result => {
                        setDisplayTableLoadingAnimation(true)
                        const fetchData = async () => {
                            await fetch(process.env.REACT_APP_BACK_URL + '/api/Search/SearchAll', {
                                method: 'POST',
                                mode: 'cors',
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Authorization': 'Bearer ' + result.idToken,
                                },
                                body: JSON.stringify(
                                    potentialRequestBody
                                )
                            })

                                .then(

                                    res => {
                                        if (!res.ok) {
                                            console.log("RES.OK: STATUS:", res.status)
                                            failedFetchError(res.status)
                                            setPleaseTryAgain(true)
                                            setDisplayTableLoadingAnimation(false)
                                            setHaveAPISearchData(false);
                                         /*   failedFetchError(res.status)*/
                                            /* return res.json();*/
                                            //setPleaseTryAgain(true)
                                            //setDisplayTableLoadingAnimation(false)
                                            //haveAPISearchData(false)
                                            //failedFetchError(res.status)
                                        } else {
                                            return res.json();
                                        }
                                       

                                        
                                    })
                        
                                .then(data => {
                                    console.log("RETURNED DATA from global fetch: ", data)
                                    console.log("RETURNED DATA from global fetch LENGTH: ", data?.length)
                                    if (data.length === 0 || data == [] || data == undefined || data == null) {
                                        console.log("Hitting response weirdness")


                                        setPleaseTryAgain(true)
                                        setDisplayTableLoadingAnimation(false)
                                        setHaveAPISearchData(false);
                                    } else {
                                        return data;
                                    }

                                })
                                .then((response) => {
                                   
                                    if (response.status === 400) {
                                        console.log("hitting 400 error")
                                        setHaveAPISearchData(false);
                                        fetchData();
                                        throw Error(response.status);

                                    } else {
                                        console.log("DATAGRID FETCH RES: ", response)
                                        console.log("RESPONSE TY: ", response)

                                        if ((response == []) == true || Object.is(response, null) == true || Object.is(response, undefined) == true || Object.is(response, []) == true) {
                                            console.log("Hitting response weirdness")


                                            setPleaseTryAgain(true)
                                            setDisplayTableLoadingAnimation(false)
                                            setHaveAPISearchData(false);
                                        } else {
                                            setHaveAPISearchData(true);
                                            console.log("API DATA: ", response);
                                            apiData.current = [...response];
                                            setDisplayTableLoadingAnimation(false)
                                        }

                                    }
                                    console.log("hitting neither case")


                                })

                                .catch((error) => {
                                    console.log(error)


                                })
                        }

                        fetchData();

                    }).catch((error) => {
                        console.log(error) //token expired/is not valid => user is redirected to login page
                        if (error instanceof InteractionRequiredAuthError) {
                            instance.acquireTokenRedirect({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) });
                        }
                    });

            }

        }
        fetchGlobalDataGridRowData();
    }

    function handleClearSearchAndBackUpPreviousFilterModel() {
        setPleaseTryAgain(false)
        console.log("CLEAR SEARCH AND BACK UP");
        //backup relevant values
        if (mainSearchFilterModel !== []) {
            backupPrintablePreviousSearch(searchValueAuditID,
                searchValueClient,
                searchValueClientExactMatch,
                searchValueFirstName,
                searchValueLastName,
                searchFirstNameExactMatch,
                searchLastNameExactMatch,
                searchValueDOB,
                searchValueEmailAddress,
                employeeOnlySearch)
            handleUpdatePersistentPreviousSearchParametersGlobal(mainSearchFilterModel)
            handleUpdatePreviousSearchParameters(mainSearchFilterModel)

            console.log("BACKING UP PREV SEARCH MODEL:", previousSearchParameters);
        }


        //then clear out the existing

        console.log("SETTING NEW APIREF NULL: ", null)
        setMainSearchFilterModel([]);
        apiRef.current.setFilterModel({
            items: []

        });
        setHaveAPISearchData(false);
        setSearchValueAuditID("")
        setSearchValueClient("")
        setSearchValueFirstName("")
        setSearchValueLastName("")
        setSearchValueDOB(null)
        setSearchValueEmailAddress("")
        /*handleUpdateMainSearchFilterModel(null);*/
    }

    function handleUpdateMainSearchFilterModel(newMainSearchFilterModel, newLogicalOperator) {


        if (newMainSearchFilterModel !== null) {
            //let newMainSearchFilterModel = mainSearchFilterModel;


            //let i = newMainSearchFilterModel?.length;
            //let newItemID = i + 1;

            //let newParameterFieldType = newFilterParameter?.FieldType;
            //let newParameterSearchValue = newFilterParameter?.SearchValue;
            //let newParameterOperatorValue = newFilterParameter?.OperatorValue;

            //let newSearchFilterModelItem = {
            //    id: newItemID,
            //    operator: newParameterOperatorValue,
            //    field: newParameterFieldType,
            //    value: newParameterSearchValue,
            //}
            if (newLogicalOperator !== null) {
                setMainSearchFilterModelLogicalOperator(newLogicalOperator)
                setMainSearchFilterModel(newMainSearchFilterModel);
                console.log("SETTING NEW APIREF - WITH LOGICAL OPERATOR: ", newMainSearchFilterModel)
                apiRef.current.setFilterModel({
                    items: newMainSearchFilterModel,
                    logicOperator: newLogicalOperator

                });
            } else {
                setMainSearchFilterModel(newMainSearchFilterModel);
                console.log("SETTING NEW APIREF: ", newMainSearchFilterModel)
                apiRef.current.setFilterModel({
                    items: newMainSearchFilterModel

                });
            }
            /////
            //if (newMainSearchFilterModel !== []) {
            //    handleUpdatePreviousSearchParameters(newMainSearchFilterModel)
            //    if (newLogicalOperator !== null) {
            //        handleUpdatePreviousSearchLogicalOperatorParameter(newLogicalOperator)
            //    }

            //}

            //newMainSearchFilterModel = [...newMainSearchFilterModel, newSearchFilterModelItem];
            //if (generateNewSearchFilterModel == true) {


        } else if (newMainSearchFilterModel == null) {
            setMainSearchFilterModel([]);
            apiRef.current.setFilterModel({
                items: []

            });
            console.log("SETTING NEW APIREF NULL: ", null)
            setSearchValueAuditID("")
            setSearchValueClient("")
            setSearchValueFirstName("")
            setSearchValueLastName("")
            setSearchValueDOB(null)
            setSearchValueEmailAddress("")



            setGenerateNewSearchFilterModel(false)
        }
        //console.log("PREV SEARCH MODEL:", previousSearchParameters);
        //console.log("PREV SEARCH LOGICAL OPERATOR:", previousSearchLogicalOperatorParameter);
    }




    function handleGenerateNewMainSearchFilterModel() {
        /* setGenerateNewSearchFilterModel(true)*/
        handleDrawerCloseMobile()
        setMainSearchFilterModel([])
        apiRef.current.setFilterModel({
            items: []

        });



        handleGetAndSetAllFiltersForUpdate()
    }

    ///Believe this is the troublemaker
    //function handleGeneratePreviousMainSearchFilterModel() {


    //    apiRef.current.setFilterModel({
    //        items: []

    //    });
    //    if (previousSearchParameters.length !== 0) {

    //        if (previousSearchLogicalOperatorParameter !== "") {
    //            console.log("SETTING APIREF W/ PREV SEARCH PARAMS & OPERATOR: ", previousSearchParameters, previousSearchLogicalOperatorParameter)
    //            apiRef.current.setFilterModel({
    //                items: previousSearchParameters,
    //                logicOperator: previousSearchLogicalOperatorParameter

    //            });
    //        } else {
    //            console.log("SETTING APIREF W/ JUST PREV SEARCH PARAMS: ", previousSearchParameters)
    //            apiRef.current.setFilterModel({
    //                items: previousSearchParameters

    //            });
    //        }


    //    }



    //}

    function handleGetAndSetPreviousSearch(previousSearchRequest) {

        handleDrawerCloseMobile()
        apiRef.current.setFilterModel({
            items: []

        });
        apiData.current = [];
        setDisplayTableLoadingAnimation(true)


        console.log("PREV POTENTIAL-REQUEST-BODY ---", previousSearchRequest)

        setMainSearchFilterModel(previousSearchRequest);

        const fetchPreviousGlobalDataGridRowData = async () => {
            if (accounts.length > 0) {
                await instance.acquireTokenSilent({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) })
                    .then(result => {

                        const fetchData = async () => {
                            await fetch(process.env.REACT_APP_BACK_URL + '/api/Search/SearchAll', {
                                method: 'POST',
                                mode: 'cors',
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Authorization': 'Bearer ' + result.idToken,
                                },
                                body: JSON.stringify(
                                    previousSearchRequest
                                )
                            })
                                .then(res => res.json())
                                .then(data => {
                                    console.log("RETURNED DATA from global fetch: ", data)
                                    console.log("RETURNED DATA from global fetch LENGTH: ", data?.length)
                                    if (data.length === 0 || data == [] || data == undefined || data == null) {
                                        console.log("Hitting response weirdness")


                                        setPleaseTryAgain(true)
                                        setDisplayTableLoadingAnimation(false)
                                        setHaveAPISearchData(false);
                                    } else {
                                        return data;
                                    }

                                })
                                .then((response) => {
                                    if (response.status === 400) {
                                        console.log("hitting 400 error")
                                        setHaveAPISearchData(false);
                                        fetchData();
                                        throw Error(response.status);

                                    } else {
                                        console.log("DATAGRID FETCH RES: ", response)

                                        setHaveAPISearchData(true);
                                        console.log("API DATA: ", response);
                                        apiData.current = [...response];
                                        setDisplayTableLoadingAnimation(false)


                                    }
                                    console.log("hitting neither case")


                                }).catch((error) => {
                                    console.log(error)


                                })
                        }

                        fetchData();

                    }).catch((error) => {
                        console.log(error) //token expired/is not valid => user is redirected to login page
                        if (error instanceof InteractionRequiredAuthError) {
                            instance.acquireTokenRedirect({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) });
                        }
                    });

            }

        }
        fetchPreviousGlobalDataGridRowData();
    }

    const handleGoToPage1 = () => apiRef.current.setPage(1);
    ////

    //interface InputElement  {
    //    focus(): void;
    //    value?: string;
    //}


    //const NumericFormatCustom = React.forwardRef(
    //    function BirthDateFormatCustom(props, ref) {
    //        const { onChange, ...other } = props;

    //        return (

    //        );
    //    },
    //);

    //function DateInput(props) {
    //    return (
    //        <InputMask
    //            mask='99-99-9999'
    //            placeholder='MM/DD/YYYY'
    //            value={props.value}
    //            onChange={props.onChange}>
    //        </InputMask>
    //    );
    //}



    ////

    const themeMobile = useTheme();
    const [openMobile, setOpenMobile] = React.useState(false);

    const handleDrawerOpenMobile = () => {
        setOpenMobile(true);
    };

    const handleDrawerCloseMobile = () => {
        setOpenMobile(false);
    };

    const drawerWidth = '100VW';


    const openedMixin = (theme) => ({
        width: drawerWidth,
        zIndex: 1204,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        overflowX: 'hidden',
    });

    const closedMixin = (theme) => ({

        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: `calc(${theme.spacing(7)} + 1px)`,
        [theme.breakpoints.up('sm')]: {
            width: `calc(${theme.spacing(8)} + 1px)`,
        },
    });

    const DrawerHeader = styled('div')(({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),

        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    }));

    const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' || prop !== 'openMobile' })(
        ({ theme, open }) => ({

            width: drawerWidth,
            flexShrink: 0,
            whiteSpace: 'nowrap',
            boxSizing: 'border-box',

            ...(open && {
                ...openedMixin(theme),
                '& .MuiDrawer-paper': openedMixin(theme),
            }),
            ...(!open && {
                ...closedMixin(theme),
                '& .MuiDrawer-paper': closedMixin(theme),
            }),
        }),
    );

    const [responseRateData, setResponseRateData] = useState()
    const [employeeStatusData, setEmployeeStatusData] = useState()
    const [dependentStatusData, setDependentStatusData] = useState()
    const [ineligibleStatusData, setIneligibleStatusData] = useState()
    const [incompleteStatusData, setIncompleteStatusData] = useState()

    const { id, auditName } = useParams();

    const {
        setSidebarTitle,
        setSidebarActions,
        sidebarActiveClientID,
        setSidebarActiveClientID
    } = useContext(SidebarContext);

    console.log("ACTIVE-CLIENT-ID: ", sidebarActiveClientID)

    let dataGridRows = null

    let apiData = useRef(null);
    const [haveAPISearchData, setHaveAPISearchData] = useState(false);

    //useEffect(() => {
    //    fetchClientScopedDataGridRowData();
    //}, [id])



    const { permissions, setPermissions } = useContext(PermissionsContext);
    const { roles, setRoles } = useContext(RolesContext);
    const { accounts, instance } = useMsal();

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [isTwoLists, setIsTwoLists] = useState(false);
    const [firstIneligibleList, setFirstIneligibleList] = useState([]);
    const [secIneligibleList, setSecIneligibleList] = useState([]);
    const [finalIneligibleList, setFinalIneligibleLst] = useState([]);
    const [hideIneligibleChart, setHideIneligibleChart] = useState(false);
    const [validRoute, setValidRoute] = useState(null);
    const [showIneligibleChart, setShowIneligibleChart] = useState(true);
    const [showIncompleteChart, setShowIncompleteChart] = useState(true);
    const [popAlertToast, setPopAlertToast] = useState(false);
    const fetchDataRef = useRef(false);

    useEffect(() => {

        if (id !== sidebarActiveClientID) {
            setIsLoading(false);
        }

    }, [id, auditName]);

    //useEffect(() => {



    //    if (isLoading === false) {
    //        setSidebarActiveClientID(`${id}`)
    //        setSidebarTitle(`${auditName}`)
    //        console.log("setSideBar call - ", auditName)
    //        setPopAlertToast(false)
    //        setIsLoading(true);

    //    }



    //}, [id, auditName]);

    useEffect(() => {

        if (isLoading === false) {
            setSidebarActiveClientID(`${id}`)
            setSidebarTitle(`${auditName}`)
            console.log("setSideBar call - ", auditName)
            setPopAlertToast(false)
            setIsLoading(true);

        }

    }, [isLoading]);

    const { newRoutes, setRoutes } = useContext(RoutesContext);

    const [pageRoutes, setPageRoutes] = useState([
        {
            id: 0,
            label: "Home",
            path: "report",
            icon: <HomeIcon />,
            isActive: false,
            style: '',
            isNested: false
        },
        {
            id: 1,
            label: "Search All",
            path: "globalsearch",
            icon: <TravelExploreIcon />,
            isActive: true,
            style: 'active-navLink border-bottom__thick-blue',
            isNested: false
        }
    ]);

    if ((roles === "CLIENT")) {
        /* <Navigate to={`/report/${String(permissions[0]?.auditId)}/${permissions[0]?.auditName}`} replace />*/

        navigate(`/report/${String(permissions[0]?.auditId)}/${permissions[0]?.auditName}`, { replace: true });

    }


    useEffect(() => {
       
      
        if ({ pageRoutes } !== { newRoutes } && (roles === "SERVICES")) {
            setRoutes([
                {
                    id: 0,
                    label: "Processing",
                    path: "processing",
                    icon: <AssignmentTurnedInIcon />,
                    isActive: false,
                    style: 'active-navLink border-bottom__thick-blue',
                    isNested: false
                },

             {
                    id: 1,
                    label: "Search All",
                    path: "globalsearch",
                    icon: <TravelExploreIcon />,
                    isActive: true,
                    style: 'active-navLink border-bottom__thick-blue',
                    isNested: false
                }
            ])

        }

        if ({ pageRoutes } !== { newRoutes } && (roles === "PROJMGR")) {
            setRoutes([
                {
                    id: 0,
                    label: "Home",
                    path: "report",
                    icon: <HomeIcon />,
                    isActive: false,
                    style: '',
                    isNested: false
                },
                {
                    id: 1,
                    label: "Processing",
                    path: "processing",
                    icon: <AssignmentTurnedInIcon />,
                    isActive: false,
                    style: 'active-navLink border-bottom__thick-blue',
                    isNested: false
                },
                {
                    id: 2,
                    label: "Search All",
                    path: "globalsearch",
                    icon: <TravelExploreIcon />,
                    isActive: true,
                    style: 'active-navLink border-bottom__thick-blue',
                    isNested: false
                }
            ])

        }

    }, []);

    const [alertNoteCount, setAlertNoteCount] = useState(0);
    const [reportBadge, setReportBadge] = useState(true);

    

    const [count, setCount] = useState(0);

    useEffect(() => {
        setIneligibleStatusData()
        setFirstIneligibleList([])
        setFinalIneligibleLst([])
        setSecIneligibleList([])
        permissions?.map((audit) => {
            if (audit?.auditId === parseInt(id)) {
                setValidRoute(true)
                setCount(count + 1);
            }

        });
        console.log("count -- ", count)
        console.log("permissions -- ", permissions)
        if ((validRoute === null) && (count === permissions.length)) {
            setValidRoute(false)
        }

    }, []);























    const [dependentReportData, setDependentReportData] = useState([]);


    function getDependentID(row) {
        return row.rowID;
    }

    function getEmployeeRowID(row) {
        return row.rowID;
    }

    function getDependentRelationship(row) {
        console.log("ROW -----", row);

        if (row.dependent_relationship === "S ") {
            return 'Spouse'
        } else if (row.dependent_relationship === "D ") {
            return 'Dependent'
        } else {
            return `DBValue: ${row.dependent_relationship}`
        }
    }

    const [anchorEl, setAnchorEl] = React.useState(null);
    const openSearchSettings = Boolean(anchorEl);

    const handleClickSearchSettings = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseSearchSettings = () => {
        setAnchorEl(null);
    };

   /* let printablePersistentPreviousSearch = JSON.stringify(userPersistentPreviousSearchGlobal)*/

    //function makeUserReadablePreviousSearchFilters({ printablePersistentPreviousSearch }) {
    //    let passedIn = printablePersistentPreviousSearch;
    //    console.log("passedIn:", passedIn)

    //    let newCollection = [];
    //    let newPrintable = {
    //        AuditNumber: passedIn?.auditNumber,

    //    }
    //    console.log("newPrintable: ", newPrintable)


    //}


   /* console.log("PrintablePersistent Prev Search: ", printablePersistentPreviousSearch);*/


    const handleNavigateToFamilyDetails = (params) => {
       
      
        backupPrintablePreviousSearch(searchValueAuditID,
            searchValueClient,
            searchValueClientExactMatch,
            searchValueFirstName,
            searchValueLastName,
            searchFirstNameExactMatch,
            searchLastNameExactMatch,
            searchValueDOB,
            searchValueEmailAddress,
            employeeOnlySearch
        )
        handleUpdatePersistentPreviousSearchParametersGlobal(mainSearchFilterModel)
        /* console.log(params);*/
        console.log("CLICK-ROW", params.row);

        let potentialAuditFamilyID = params.row.audit_family_id;
        let potentialEmployeeID = params.row.employeeID;

        //if (printablePersistentPreviousSearch !== undefined) {
        //    makeUserReadablePreviousSearchFilters(printablePersistentPreviousSearch)
        //}

       
        navigate(`/globalsearch/${String(params.row.audit_family_id)}/${String(params.row.employeeID)}`);

    };

    

   




    return (
        <>
            <Box component="main" sx={{ bgcolor: '#FAFAFA', width: '100VW', minHeight: '92VH', height: 'fit-content', flexGrow: 1, paddingTop: 1, mt: 2.5, ml: '0', mr: 0, display: { xs: 'none', md: 'none', xl: 'block' } }} >

                {/*  DESKTOP PAGE TITLES/HEADINGS*/}


                <Container sx={{ mt: 4, ml: 0, display: { xs: 'none', md: 'block' } }}>
                    <div className="row-no-left-margin" style={{ width: "82.5VW" }}>
                        <div className="row-left">

                            <Typography textAlign="left" component="h1" gutterBottom={true} fontWeight="600" fontSize="1.5em" sx={{ mt: 0, }}>Search All Clients</Typography>


                        </div>
                        <div className="row-right">

                        </div>
                    </div>
                </Container >



                {/*<Box sx={{ width: '98VW', height: '2VH', bgcolor: '#F1F5F9', mt: 0, ml: 0, display: { xs: 'none', md: 'block', xl: 'block' } }}>*/}

                {/*</Box>*/}

                {/* DESKTOP TABLE - FILTER*/}
                <Box sx={{ width: '98VW', bgcolor: '#FAFAFA', mt: 0, ml: 0, display: { xs: 'none', md: 'block', xl: 'block' } }}>
                    {/* SKELETON LOADER - DESKTOP TABLE FILTER */}

                    <Box sx={{ width: '98VW', bgcolor: '#F1F5F9', mt: 0, ml: 0, display: { xs: 'none', md: 'block', xl: 'block' } }}>
                        <Box sx={{ width: '98VW', height: '2VH', bgcolor: '#F1F5F9', mt: 0, ml: 0, display: { xs: 'none', md: 'block', xl: 'block' } }}>
                        </Box>
                        <TextField
                            label="Audit ID"
                            variant="outlined"
                            value={searchValueAuditID}
                            onChange={handleSearchValueAuditIDChange}

                            sx={{ ml: 3, mr: '1VW', width: '10VW', bgcolor: '#FFFFFF' }}


                        />

                      

                        <TextField
                            label="Member/Employee First Name"
                            variant="outlined"
                            value={searchValueFirstName}
                            onChange={handleSearchValueFirstNameChange}

                            sx={{ mr: '1VW', width: '10VW', bgcolor: '#FFFFFF' }}


                        />

                        <TextField
                            label="Member/Employee Last Name"
                            variant="outlined"
                            value={searchValueLastName}
                            onChange={handleSearchValueLastNameChange}

                            sx={{ mr: '1VW', width: '10VW', bgcolor: '#FFFFFF' }}


                        />

                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateField
                                label="Date of Birth"
                                format="MM/DD/YYYY"
                                value={searchValueDOB}
                                onChange={(value) => handleSearchValueDOBChange(value)}
                                sx={{ ml: 0, mr: '1VW', width: '10VW', bgcolor: '#FFFFFF' }}

                            />
                        </LocalizationProvider>



                        <TextField
                            label="Email Address"
                            variant="outlined"
                            value={searchValueEmailAddress}
                            onChange={handleSearchValueEmailAddressChange}

                            sx={{ mr: '1VW', width: '10VW', bgcolor: '#FFFFFF' }}


                        />
                        <TextField
                            label="Client"
                            variant="outlined"
                            value={searchValueClient}
                            onChange={handleSearchValueClientChange}

                            sx={{ mr: '1VW', width: '10VW', bgcolor: '#FFFFFF' }}


                        />
                      
                        <Button
                            variant="contained"
                            label="GENERATE SEARCH"
                            sx={{ mr: '1VW', height: '6VH', bgcolor: '#3B82F6' }}
                            onClick={() => handleGenerateNewMainSearchFilterModel()}
                        >
                            GENERATE SEARCH
                        </Button>
                        <>
                            <Button
                                sx={{ maxWidth: '1VW', height: '6VH', bgcolor: '#DBEAFE' }}
                                aria-label="more"
                                id="long-button"
                                aria-controls={openSearchSettings ? 'long-menu' : undefined}
                                aria-expanded={openSearchSettings ? 'true' : undefined}
                                aria-haspopup="true"
                                onClick={handleClickSearchSettings}
                            >

                                <CgOptions />
                            </Button>
                            <Menu
                                id="long-menu"
                                MenuListProps={{
                                    'aria-labelledby': 'long-button',
                                }}
                                anchorEl={anchorEl}
                                open={openSearchSettings}
                                onClose={handleCloseSearchSettings}

                            >
                                <MenuItem onClick={() => handleUpdateEmployeeOnlySearch(!employeeOnlySearch)} >
                                    Employee-Only Search
                                    <Switch
                                        checked={employeeOnlySearch}


                                    />

                                </MenuItem>

                                <MenuItem onClick={() => handleUpdateSearchValueClientExactMatch(!searchValueClientExactMatch)} >
                                    Client Name Exact
                                    <Switch
                                        checked={searchValueClientExactMatch}


                                    />

                                </MenuItem>

                                <MenuItem onClick={() => handleUpdateSearchFirstNameExactMatch(!searchFirstNameExactMatch)} >
                                    First Name Exact
                                    <Switch
                                        checked={searchFirstNameExactMatch}


                                    />

                                </MenuItem>

                                <MenuItem onClick={() => handleUpdateSearchLastNameExactMatch(!searchLastNameExactMatch)} >
                                    Last Name Exact
                                    <Switch
                                        checked={searchLastNameExactMatch}


                                    />

                                </MenuItem>

                                <Tooltip title={userPrintablePersistentPreviousGlobalString !== [] ? userPrintablePersistentPreviousGlobalString?.map((item) => {
                                  
                                        return (<>
                                            {getPreviousSearchFieldName(item?.field.toString())}{item?.value.toString()} < br />
                                        </>)
                                  
                                }) : null} placement="left">
                                <MenuItem onClick={() => handleGetAndSetPreviousSearch(previousSearchParameters)} disabled={(previousSearchParameters.length === 0) ? true : false} >
                                    Previous Search
                                    </MenuItem>
                                </Tooltip>
                                <Divider />
                                <MenuItem onClick={() => handleClearSearchAndBackUpPreviousFilterModel()} >
                                    Clear Search
                                </MenuItem>






                            </Menu>
                        </>
                    </Box>
                    <Box sx={{ width: '98VW', height: '2VH', bgcolor: '#F1F5F9', mt: 0, ml: 0, display: { xs: 'none', md: 'block', xl: 'block' } }}>

                    </Box>

                    <Box sx={{ width: '98VW', bgcolor: '#F1F5F9', mt: 0, mb: 0, ml: 0, display: { xs: 'none', md: 'block', xl: 'block' } }}>





                    </Box>





                </Box>



                {/*<Box sx={{ width: '98VW', height: '2VH', bgcolor: '#F1F5F9', mt: 0, ml: 0, display: { xs: 'none', md: 'block', xl: 'block' } }}>*/}

                {/*</Box>*/}

                {/* DESKTOP TABLE*/}
                {/* <Container sx={{ mt: 1, mb: 0.5, ml: 0, display: { xs: 'none', md: 'block', xl: 'block' } }}>*/}

                {/* <Button onClick={handleGoToPage1}>Go to page 1</Button>*/}


                {employeeOnlySearch === true ? (<>
                    {haveAPISearchData !== true && apiData?.length !== 0 ? (<>

                        <Box sx={{ height: 600, width: '86VW', ml: 3 }}>
                            <DataGridPro
                                onRowClick={(row) => handleNavigateToFamilyDetails(row)}
                                getRowId={getEmployeeRowID}
                                columnVisibilityModel={{
                                    // Hide columns status and traderName, the other columns will remain visible
                                    other: false,
                                }}
                                onFilterModelChange={(model) => console.log("NEW Filter MODEL - GEN: ", model)}
                                columns={dataGridColumns}
                                loading={displayTableLoadingAnimation === true ? true : false}
                                rows={[]}
                                sx={{
                                    '& .MuiDataGrid-columnContainer': {
                                        bgcolor: '#F1F5F9',
                                        fontWeight: 800,
                                    },
                                }}
                                apiRef={apiRef}
                                localeText={{ noRowsLabel: "No results were found during the Search. Please try again with differtn Search Parameters." }}
                                slots={{
                                    noResultsOverlay: CustomNoResultsOverlay,
                                    noRowsOverlay: CustomNoRowsOverlay,
                                    detailPanelExpandIcon: IoMdArrowDropright,
                                    detailPanelCollapseIcon: IoMdArrowDropdown,
                                }}
                                getDetailPanelHeight={() => 250}

                            />
                        </Box>

                    </>) : (<>
                        <Box sx={{ height: 600, width: '86VW', ml: 3 }}>
                                <DataGridPro
                                    onRowClick={(row) => handleNavigateToFamilyDetails(row)}
                                getRowId={getEmployeeRowID}
                                columnVisibilityModel={{
                                    // Hide columns status and traderName, the other columns will remain visible
                                    other: false,
                                }}
                                onFilterModelChange={(model) => console.log("NEW Filter MODEL - GEN: ", model)}
                                columns={dataGridColumns}
                                rows={apiData.current}
                                sx={{
                                    '& .MuiDataGrid-columnContainer': {
                                        bgcolor: '#F1F5F9',
                                        fontWeight: 800,
                                    },
                                }}
                                apiRef={apiRef}



                            />


                        </Box>
                    </>)}
                </>) : (<>
                    {haveAPISearchData !== true && apiData?.length !== 0 ? (<>

                        <Box sx={{ height: 600, width: '86VW', ml: 3 }}>
                                <DataGridPro
                                    onRowClick={(row) => handleNavigateToFamilyDetails(row)}
                                getRowId={getEmployeeRowID}
                                columnVisibilityModel={{
                                    // Hide columns status and traderName, the other columns will remain visible
                                    other: false,
                                }}
                                onFilterModelChange={(model) => console.log("NEW Filter MODEL - GEN: ", model)}
                                columns={dataGridColumns}
                                loading={displayTableLoadingAnimation === true ? true : false}
                                rows={[]}
                                sx={{
                                    '& .MuiDataGrid-columnContainer': {
                                        bgcolor: '#F1F5F9',
                                        fontWeight: 800,
                                    },
                                }}
                                localeText={{ noRowsLabel: "No results were found during the Search. Please try again with differtn Search Parameters." }}
                                apiRef={apiRef}
                                slots={{
                                    noResultsOverlay: CustomNoResultsOverlay,
                                    noRowsOverlay: CustomNoRowsOverlay,
                                    detailPanelExpandIcon: IoMdArrowDropright,
                                    detailPanelCollapseIcon: IoMdArrowDropdown,
                                }}
                                getDetailPanelHeight={() => 250}
                                getDetailPanelContent={({ row }) => row.dependent_data != [] ? (
                                    <Box sx={{ height: 'fit-content', bgcolor: '#ffffff' }}>
                                        <DataGridPro
                                            density="compact"
                                            getRowId={getDependentID}
                                            columns={[
                                                { field: 'dependent_first_name', headerName: 'First Name', "width": 250 },
                                                { field: 'dependent_last_name', headerName: 'Last Name', "width": 250 },
                                                {
                                                    field: "dependent_relationship",
                                                    headerName: 'Relationship',
                                                    "width": 150,
                                                    renderCell: (params) =>
                                                        getDependentRelationship(params.row)


                                                },
                                                {
                                                    field: "dependent_date_of_birth", headerName: 'Date Of Birth', "width": 250, renderCell: (params) =>
                                                        renderDate(params.row?.dependent_date_of_birth)
                                                },
                                            ]}
                                            rows={row?.dependent_data}
                                            sx={{
                                                mt: '2.5VH',
                                                ml: '2.5VW',

                                                width: '80VW',

                                                bgcolor: '#F8FAFC',
                                                boxShadow: 2,
                                                border: 2,
                                                borderColor: 'primary.light',
                                                '& .MuiDataGrid-columnContainer': {
                                                    bgcolor: '#F1F5F9',
                                                    fontWeight: 800,
                                                },
                                                '& .MuiDataGrid-cell:hover': {
                                                    color: 'primary.main',
                                                },
                                            }}
                                            hideFooter
                                        />
                                    </Box>
                                ) : (<></>)

                                }

                            />
                        </Box>
                    </>) : (<>
                        <Box sx={{ height: 600, width: '86VW', ml: 3 }}>
                                    <DataGridPro
                                        onRowClick={(row) => handleNavigateToFamilyDetails(row)}
                                getRowId={getEmployeeRowID}
                                columnVisibilityModel={{
                                    // Hide columns status and traderName, the other columns will remain visible
                                    other: false,
                                }}
                                onFilterModelChange={(model) => console.log("NEW Filter MODEL - GEN: ", model)}
                                columns={dataGridColumns}
                                rows={apiData.current}
                                sx={{
                                    '& .MuiDataGrid-columnContainer': {
                                        bgcolor: '#F1F5F9',
                                        fontWeight: 800,
                                    },
                                }}
                                apiRef={apiRef}
                                slots={{
                                    detailPanelExpandIcon: IoMdArrowDropright,
                                    detailPanelCollapseIcon: IoMdArrowDropdown,
                                }}
                                getDetailPanelHeight={() => 250}
                                getDetailPanelContent={({ row }) => row.dependent_data != [] ? (
                                    <Box sx={{ height: 'fit-content', bgcolor: '#ffffff' }}>
                                        <DataGridPro
                                            density="compact"
                                            getRowId={getDependentID}
                                            columns={[
                                                { field: 'dependent_first_name', headerName: 'First Name', "width": 250 },
                                                { field: 'dependent_last_name', headerName: 'Last Name', "width": 250 },
                                                {
                                                    field: "dependent_relationship",
                                                    headerName: 'Relationship',
                                                    "width": 150,
                                                    renderCell: (params) =>
                                                        getDependentRelationship(params.row)


                                                },
                                                {
                                                    field: "dependent_date_of_birth", headerName: 'Date Of Birth', "width": 250, renderCell: (params) =>
                                                        renderDate(params.row?.dependent_date_of_birth)
                                                },
                                            ]}
                                            rows={row?.dependent_data}
                                            sx={{
                                                mt: '2.5VH',
                                                ml: '2.5VW',

                                                width: '80VW',

                                                bgcolor: '#F8FAFC',
                                                boxShadow: 2,
                                                border: 2,
                                                borderColor: 'primary.light',
                                                '& .MuiDataGrid-columnContainer': {
                                                    bgcolor: '#F1F5F9',
                                                    fontWeight: 800,
                                                },
                                                '& .MuiDataGrid-cell:hover': {
                                                    color: 'primary.main',
                                                },
                                            }}
                                            hideFooter
                                        />
                                    </Box>
                                ) : (<></>)

                                }

                            />
                        </Box>
                    </>)}
                </>)}



                {/* DESKTOP TABLE - FALLBACK SKELETON*/}
                {/*<div className="row-no-left-margin">*/}
                {/*    <div className="center">*/}
                {/*        <SkeletonTheme baseColor="#EFF6FF" highlightColor="ffffff">*/}
                {/*            <Skeleton height={510} width={'75VW'} />*/}
                {/*        </SkeletonTheme>*/}
                {/*    </div>*/}
                {/*</div>*/}

                {/*{haveUserData ?*/}
                {/*    <UserManagementTable*/}
                {/*        tableData={users}*/}
                {/*        desiredHeight={500}*/}
                {/*    />*/}
                {/*    : <>*/}
                {/*        <div className="row-no-left-margin">*/}
                {/*            <div className="center">*/}
                {/*                <SkeletonTheme baseColor="#EFF6FF" highlightColor="ffffff">*/}
                {/*                    <Skeleton height={360} width={'75VW'} />*/}
                {/*                </SkeletonTheme>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </>*/}
                {/*}*/}

                {/* </Container>*/}





                {/*<Container sx={{ ml: 0, mr: 0, mt: 2.5, height: '4rem' }}>*/}

                {/*</Container>*/}
            </Box>

            {/* BEGIN SM and MED Variants */}

            <Box component="main" sx={{ bgcolor: '#FAFAFA', width: '100VW', minHeight: '92VH', height: 'fit-content', flexGrow: 1, paddingTop: 1, mt: 2.5, ml: '0', mr: 0, display: { xs: 'none', md: 'block', lg: 'block', xl: 'none' } }} >

                {/*  DESKTOP PAGE TITLES/HEADINGS*/}


                <Container sx={{ mt: 4, ml: 0, display: { xs: 'none', md: 'block' } }}>
                    <div className="row-no-left-margin" style={{ width: "82.5VW" }}>
                        <div className="row-left">

                            <Typography textAlign="left" component="h1" gutterBottom={true} fontWeight="600" fontSize="1.5em" sx={{ mt: 0, }}>Search All Clients</Typography>


                        </div>
                        <div className="row-right">

                        </div>
                    </div>
                </Container >



                {/*<Box sx={{ width: '98VW', height: '2VH', bgcolor: '#F1F5F9', mt: 0, ml: 0, display: { xs: 'none', md: 'block', xl: 'block' } }}>*/}

                {/*</Box>*/}

                {/* DESKTOP TABLE - FILTER*/}
                <Box sx={{ width: '96VW', bgcolor: '#FAFAFA', mt: 0, ml: 0, display: { xs: 'none', md: 'block', xl: 'block' } }}>
                    {/* SKELETON LOADER - DESKTOP TABLE FILTER */}

                    <Box sx={{ width: '96VW', bgcolor: '#F1F5F9', mt: 0, ml: 0, display: { xs: 'none', md: 'block', xl: 'block' } }}>
                        <Box sx={{ width: '98VW', height: '2VH', bgcolor: '#F1F5F9', mt: 0, ml: 0, display: { xs: 'none', md: 'block', xl: 'block' } }}>
                        </Box>
                        <TextField
                            label="Audit ID"
                            variant="outlined"
                            value={searchValueAuditID}
                            onChange={handleSearchValueAuditIDChange}

                            sx={{ ml: 3, mr: '1VW', width: '7VW', bgcolor: '#FFFFFF' }}


                        />

                      

                        <TextField
                            label="Member/Employee First Name"
                            variant="outlined"
                            value={searchValueFirstName}
                            onChange={handleSearchValueFirstNameChange}

                            sx={{ mr: '1VW', width: '10VW', bgcolor: '#FFFFFF' }}


                        />

                        <TextField
                            label="Member/Employee Last Name"
                            variant="outlined"
                            value={searchValueLastName}
                            onChange={handleSearchValueLastNameChange}

                            sx={{ mr: '1VW', width: '10VW', bgcolor: '#FFFFFF' }}


                        />

                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateField
                                label="Date of Birth"
                                format="MM/DD/YYYY"
                                value={searchValueDOB}
                                onChange={(value) => handleSearchValueDOBChange(value)}
                                sx={{ ml: 0, mr: '1VW', width: '10VW', bgcolor: '#FFFFFF' }}

                            />
                        </LocalizationProvider>



                        <TextField
                            label="Email Address"
                            variant="outlined"
                            value={searchValueEmailAddress}
                            onChange={handleSearchValueEmailAddressChange}

                            sx={{ mr: '1VW', width: '10VW', bgcolor: '#FFFFFF' }}


                        />
                   

                        <TextField
                            label="Client"
                            variant="outlined"
                            value={searchValueClient}
                            onChange={handleSearchValueClientChange}

                            sx={{ mr: '1VW', width: '10VW', bgcolor: '#FFFFFF' }}


                        />
                        <Button
                            variant="contained"
                            label="GENERATE SEARCH"
                            sx={{ mr: '1VW', height: '6VH', bgcolor: '#3B82F6' }}
                            onClick={() => handleGenerateNewMainSearchFilterModel()}
                        >
                            GENERATE SEARCH
                        </Button>
                        <>
                            <Button
                                sx={{ maxWidth: '0.5VW', height: '6VH', bgcolor: '#DBEAFE' }}
                                aria-label="more"
                                id="long-button"
                                aria-controls={openSearchSettings ? 'long-menu' : undefined}
                                aria-expanded={openSearchSettings ? 'true' : undefined}
                                aria-haspopup="true"
                                onClick={handleClickSearchSettings}
                            >

                                <CgOptions />
                            </Button>
                            <Menu
                                id="long-menu"
                                MenuListProps={{
                                    'aria-labelledby': 'long-button',
                                }}
                                anchorEl={anchorEl}
                                open={openSearchSettings}
                                onClose={handleCloseSearchSettings}

                            >
                                <MenuItem onClick={() => handleUpdateEmployeeOnlySearch(!employeeOnlySearch)} >
                                    Employee-Only Search
                                    <Switch
                                        checked={employeeOnlySearch}


                                    />

                                </MenuItem>

                                <MenuItem onClick={() => handleUpdateSearchValueClientExactMatch(!searchValueClientExactMatch)} >
                                    Client Name Exact
                                    <Switch
                                        checked={searchValueClientExactMatch}


                                    />

                                </MenuItem>

                                <MenuItem onClick={() => handleUpdateSearchFirstNameExactMatch(!searchFirstNameExactMatch)} >
                                    First Name Exact
                                    <Switch
                                        checked={searchFirstNameExactMatch}


                                    />

                                </MenuItem>

                                <MenuItem onClick={() => handleUpdateSearchLastNameExactMatch(!searchLastNameExactMatch)} >
                                    Last Name Exact
                                    <Switch
                                        checked={searchLastNameExactMatch}


                                    />

                                </MenuItem>

                                <Tooltip title={userPrintablePersistentPreviousGlobalString !== [] ? userPrintablePersistentPreviousGlobalString?.map((item) => {

                                    return (<>
                                        {getPreviousSearchFieldName(item?.field.toString())}{item?.value.toString()} < br />
                                    </>)

                                }) : null} placement="left">
                                <MenuItem onClick={() => handleGetAndSetPreviousSearch(previousSearchParameters)} disabled={(previousSearchParameters.length === 0) ? true : false} >
                                    Previous Search
                                    </MenuItem>
                              </Tooltip>
                                <Divider />
                                <MenuItem onClick={() => handleClearSearchAndBackUpPreviousFilterModel()} >
                                    Clear Search
                                </MenuItem>






                            </Menu>
                        </>
                    </Box>
                    <Box sx={{ width: '96VW', height: '2VH', bgcolor: '#F1F5F9', mt: 0, ml: 0, display: { xs: 'none', md: 'block', xl: 'block' } }}>

                    </Box>

                    <Box sx={{ width: '96VW', bgcolor: '#F1F5F9', mt: 0, mb: 0, ml: 0, display: { xs: 'none', md: 'block', xl: 'block' } }}>





                    </Box>





                </Box>



                {/*<Box sx={{ width: '98VW', height: '2VH', bgcolor: '#F1F5F9', mt: 0, ml: 0, display: { xs: 'none', md: 'block', xl: 'block' } }}>*/}

                {/*</Box>*/}

                {/* DESKTOP TABLE*/}
                {/* <Container sx={{ mt: 1, mb: 0.5, ml: 0, display: { xs: 'none', md: 'block', xl: 'block' } }}>*/}

                {/* <Button onClick={handleGoToPage1}>Go to page 1</Button>*/}


                {employeeOnlySearch === true ? (<>
                    {haveAPISearchData !== true && apiData?.length !== 0 ? (<>

                        <Box sx={{ height: 600, width: '78VW', ml: 3 }}>
                            <DataGridPro
                                onRowClick={(row) => handleNavigateToFamilyDetails(row)}
                                getRowId={getEmployeeRowID}
                                columnVisibilityModel={{
                                    // Hide columns status and traderName, the other columns will remain visible
                                    other: false,
                                }}
                                onFilterModelChange={(model) => console.log("NEW Filter MODEL - GEN: ", model)}
                                columns={dataGridColumns}
                                loading={displayTableLoadingAnimation === true ? true : false}
                                rows={[]}
                                sx={{
                                    '& .MuiDataGrid-columnContainer': {
                                        bgcolor: '#F1F5F9',
                                        fontWeight: 800,
                                    },
                                }}
                                apiRef={apiRef}
                                localeText={{ noRowsLabel: "No results were found during the Search. Please try again with differtn Search Parameters." }}
                                slots={{
                                    noResultsOverlay: CustomNoResultsOverlay,
                                    noRowsOverlay: CustomNoRowsOverlay,
                                    detailPanelExpandIcon: IoMdArrowDropright,
                                    detailPanelCollapseIcon: IoMdArrowDropdown,
                                }}
                                getDetailPanelHeight={() => 250}

                            />
                        </Box>

                    </>) : (<>
                        <Box sx={{ height: 600, width: '78VW', ml: 3 }}>
                                <DataGridPro
                                    onRowClick={(row) => handleNavigateToFamilyDetails(row)}
                                getRowId={getEmployeeRowID}
                                columnVisibilityModel={{
                                    // Hide columns status and traderName, the other columns will remain visible
                                    other: false,
                                }}
                                onFilterModelChange={(model) => console.log("NEW Filter MODEL - GEN: ", model)}
                                columns={dataGridColumns}
                                rows={apiData.current}
                                sx={{
                                    '& .MuiDataGrid-columnContainer': {
                                        bgcolor: '#F1F5F9',
                                        fontWeight: 800,
                                    },
                                }}
                                apiRef={apiRef}



                            />


                        </Box>
                    </>)}
                </>) : (<>
                    {haveAPISearchData !== true && apiData?.length !== 0 ? (<>

                        <Box sx={{ height: 500, width: '78VW', ml: 3 }}>
                                <DataGridPro
                                    onRowClick={(row) => handleNavigateToFamilyDetails(row)}
                                getRowId={getEmployeeRowID}
                                columnVisibilityModel={{
                                    // Hide columns status and traderName, the other columns will remain visible
                                    other: false,
                                }}
                                onFilterModelChange={(model) => console.log("NEW Filter MODEL - GEN: ", model)}
                                columns={dataGridColumns}
                                loading={displayTableLoadingAnimation === true ? true : false}
                                rows={[]}
                                sx={{
                                    '& .MuiDataGrid-columnContainer': {
                                        bgcolor: '#F1F5F9',
                                        fontWeight: 800,
                                    },
                                }}
                                localeText={{ noRowsLabel: "No results were found during the Search. Please try again with differtn Search Parameters." }}
                                apiRef={apiRef}
                                slots={{
                                    noResultsOverlay: CustomNoResultsOverlay,
                                    noRowsOverlay: CustomNoRowsOverlay,
                                    detailPanelExpandIcon: IoMdArrowDropright,
                                    detailPanelCollapseIcon: IoMdArrowDropdown,
                                }}
                                getDetailPanelHeight={() => 250}
                                getDetailPanelContent={({ row }) => row.dependent_data != [] ? (
                                    <Box sx={{ height: 'fit-content', bgcolor: '#ffffff' }}>
                                        <DataGridPro
                                            density="compact"
                                            getRowId={getDependentID}
                                            columns={[
                                                { field: 'dependent_first_name', headerName: 'First Name', "width": 250 },
                                                { field: 'dependent_last_name', headerName: 'Last Name', "width": 250 },
                                                {
                                                    field: "dependent_relationship",
                                                    headerName: 'Relationship',
                                                    "width": 150,
                                                    renderCell: (params) =>
                                                        getDependentRelationship(params.row)


                                                },
                                                {
                                                    field: "dependent_date_of_birth", headerName: 'Date Of Birth', "width": 250, renderCell: (params) =>
                                                        renderDate(params.row?.dependent_date_of_birth)
                                                },
                                            ]}
                                            rows={row?.dependent_data}
                                            sx={{
                                                mt: '2.5VH',
                                                ml: '2.5VW',

                                                width: '80VW',

                                                bgcolor: '#F8FAFC',
                                                boxShadow: 2,
                                                border: 2,
                                                borderColor: 'primary.light',
                                                '& .MuiDataGrid-columnContainer': {
                                                    bgcolor: '#F1F5F9',
                                                    fontWeight: 800,
                                                },
                                                '& .MuiDataGrid-cell:hover': {
                                                    color: 'primary.main',
                                                },
                                            }}
                                            hideFooter
                                        />
                                    </Box>
                                ) : (<></>)

                                }

                            />
                        </Box>
                    </>) : (<>
                        <Box sx={{ height: 500, width: '78VW', ml: 3 }}>
                                    <DataGridPro
                                        onRowClick={(row) => handleNavigateToFamilyDetails(row)}
                                getRowId={getEmployeeRowID}
                                columnVisibilityModel={{
                                    // Hide columns status and traderName, the other columns will remain visible
                                    other: false,
                                }}
                                onFilterModelChange={(model) => console.log("NEW Filter MODEL - GEN: ", model)}
                                columns={dataGridColumns}
                                rows={apiData.current}
                                sx={{
                                    '& .MuiDataGrid-columnContainer': {
                                        bgcolor: '#F1F5F9',
                                        fontWeight: 800,
                                    },
                                }}
                                apiRef={apiRef}
                                slots={{
                                    detailPanelExpandIcon: IoMdArrowDropright,
                                    detailPanelCollapseIcon: IoMdArrowDropdown,
                                }}
                                getDetailPanelHeight={() => 250}
                                getDetailPanelContent={({ row }) => row.dependent_data != [] ? (
                                    <Box sx={{ height: 'fit-content', bgcolor: '#ffffff' }}>
                                        <DataGridPro
                                            density="compact"
                                            getRowId={getDependentID}
                                            columns={[
                                                { field: 'dependent_first_name', headerName: 'First Name', "width": 250 },
                                                { field: 'dependent_last_name', headerName: 'Last Name', "width": 250 },
                                                {
                                                    field: "dependent_relationship",
                                                    headerName: 'Relationship',
                                                    "width": 150,
                                                    renderCell: (params) =>
                                                        getDependentRelationship(params.row)


                                                },
                                                {
                                                    field: "dependent_date_of_birth", headerName: 'Date Of Birth', "width": 250, renderCell: (params) =>
                                                        renderDate(params.row?.dependent_date_of_birth)
                                                },
                                            ]}
                                            rows={row?.dependent_data}
                                            sx={{
                                                mt: '2.5VH',
                                                ml: '2.5VW',

                                                width: '80VW',

                                                bgcolor: '#F8FAFC',
                                                boxShadow: 2,
                                                border: 2,
                                                borderColor: 'primary.light',
                                                '& .MuiDataGrid-columnContainer': {
                                                    bgcolor: '#F1F5F9',
                                                    fontWeight: 800,
                                                },
                                                '& .MuiDataGrid-cell:hover': {
                                                    color: 'primary.main',
                                                },
                                            }}
                                            hideFooter
                                        />
                                    </Box>
                                ) : (<></>)

                                }

                            />
                        </Box>
                    </>)}
                </>)}



                {/* DESKTOP TABLE - FALLBACK SKELETON*/}
                {/*<div className="row-no-left-margin">*/}
                {/*    <div className="center">*/}
                {/*        <SkeletonTheme baseColor="#EFF6FF" highlightColor="ffffff">*/}
                {/*            <Skeleton height={510} width={'75VW'} />*/}
                {/*        </SkeletonTheme>*/}
                {/*    </div>*/}
                {/*</div>*/}

                {/*{haveUserData ?*/}
                {/*    <UserManagementTable*/}
                {/*        tableData={users}*/}
                {/*        desiredHeight={500}*/}
                {/*    />*/}
                {/*    : <>*/}
                {/*        <div className="row-no-left-margin">*/}
                {/*            <div className="center">*/}
                {/*                <SkeletonTheme baseColor="#EFF6FF" highlightColor="ffffff">*/}
                {/*                    <Skeleton height={360} width={'75VW'} />*/}
                {/*                </SkeletonTheme>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </>*/}
                {/*}*/}

                {/* </Container>*/}





                {/*<Container sx={{ ml: 0, mr: 0, mt: 2.5, height: '4rem' }}>*/}

                {/*</Container>*/}
            </Box>

            {/* END SM and MED Variants */}

            <Box component="main" sx={{ width: '100%', height: '100VH', flexGrow: 1, paddingTop: 1, mt: 2.5, ml: 0.5, display: { xs: 'block', md: 'none', xl: 'none' } }}  >
                {/* //MOBILE LAYOUT/COMPONENTS*/}
                {openMobile ? (<>
                    <Box

                        sx={{
                            mt: 6,
                            bgcolor: '#F8FAFC',
                            width: '100VW',
                            zIndex: 1500,
                            height: '88VH',


                        }}>
                        <div className="row-no-left-margin">
                            <div className="row-left">
                                <Typography textAlign="left" component="h1" gutterBottom={true} fontWeight="600" fontSize="1.5em" sx={{ mt: 0, ml: 3 }}> Search Filters</Typography>
                            </div>

                            <Tooltip title="Close Sidebar">
                                <IconButton onClick={handleDrawerCloseMobile} sx={{ color: '#5b5b5b', mr: 8 }} aria-label="Close Sidebar">
                                    <RxCross2 />
                                </IconButton>
                            </Tooltip>

                        </div>
                        <Box sx={{ mt: 2, ml: 3 }}>
                            <Accordion sx={{ width: '78VW', }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"

                                >
                                    Filter Options
                                </AccordionSummary>
                                <AccordionActions

                                >
                                    <Button
                                        onClick={() => handleUpdateEmployeeOnlySearch(!employeeOnlySearch)}

                                        sx={{ bgcolor: '#ffffff', }}
                                    >
                                        Employee-Only Search
                                        <Switch
                                            checked={employeeOnlySearch}


                                        />

                                    </Button>
                                </AccordionActions>
                                <AccordionActions

                                >
                                    <Button
                                        onClick={() => handleUpdateSearchValueClientExactMatch(!searchValueClientExactMatch)}
                                        sx={{ bgcolor: '#ffffff', mt: '0.25VH' }}
                                    >
                                        Client Name Exact
                                        <Switch
                                            checked={searchValueClientExactMatch}


                                        />

                                    </Button>
                                </AccordionActions>
                                {/*<AccordionActions>*/}
                                {/*    <Button*/}
                                {/*                onClick={() => handleUpdateEmployeeOnlySearch(!employeeOnlySearch)}*/}

                                {/*                sx={{ bgcolor: '#ffffff', }}*/}
                                {/*            >*/}
                                {/*                Employee-Only Search*/}
                                {/*                <Switch*/}
                                {/*                    checked={employeeOnlySearch}*/}


                                {/*                />*/}

                                {/*            </Button>*/}
                                {/*</AccordionActions>*/}
                                {/*<AccordionActions>*/}
                                {/*            <Button*/}
                                {/*                onClick={() => handleUpdateSearchValueClientExactMatch(!searchValueClientExactMatch)}*/}
                                {/*                sx={{ bgcolor: '#ffffff', mt: '0.25VH' }}*/}
                                {/*            >*/}
                                {/*                Client Name Exact*/}
                                {/*                <Switch*/}
                                {/*                    checked={searchValueClientExactMatch}*/}


                                {/*                />*/}

                                {/*            </Button>*/}
                                {/*</AccordionActions>*/}
                                <AccordionActions

                                >
                                    <Button onClick={() => handleUpdateSearchFirstNameExactMatch(!searchFirstNameExactMatch)}
                                        sx={{ bgcolor: '#ffffff', mt: '0.25VH' }}
                                    >
                                        First Name Exact
                                        <Switch
                                            checked={searchFirstNameExactMatch}


                                        />

                                    </Button>
                                </AccordionActions>
                                <AccordionActions

                                >
                                    <Button onClick={() => handleUpdateSearchLastNameExactMatch(!searchLastNameExactMatch)}

                                        sx={{ bgcolor: '#ffffff', mt: '0.25VH' }}>
                                        Last Name Exact
                                        <Switch
                                            checked={searchLastNameExactMatch}


                                        />

                                    </Button>
                                </AccordionActions>
                                {/*<AccordionDetails>*/}
                                {/*    <List*/}
                                {/*        style={{ marginTop: '0.5VH' }}*/}
                                {/*    >*/}
                                {/*        <Button*/}
                                {/*            onClick={() => handleUpdateEmployeeOnlySearch(!employeeOnlySearch)}*/}

                                {/*            sx={{ bgcolor: '#ffffff', }}*/}
                                {/*        >*/}
                                {/*            Employee-Only Search*/}
                                {/*            <Switch*/}
                                {/*                checked={employeeOnlySearch}*/}


                                {/*            />*/}

                                {/*        </Button>*/}
                                {/*    </List>*/}
                                {/*    <List*/}
                                {/*        style={{ marginTop: '0.5VH' }}*/}
                                {/*    >*/}
                                {/*        <Button*/}
                                {/*            onClick={() => handleUpdateSearchValueClientExactMatch(!searchValueClientExactMatch)}*/}
                                {/*            sx={{ bgcolor: '#ffffff', mt: '0.25VH' }}*/}
                                {/*        >*/}
                                {/*            Client Name Exact*/}
                                {/*            <Switch*/}
                                {/*                checked={searchValueClientExactMatch}*/}


                                {/*            />*/}

                                {/*        </Button>*/}
                                {/*    </List>*/}
                                {/*    <List*/}
                                {/*        style={{ marginTop: '0.5VH' }}*/}
                                {/*    >*/}
                                {/*        <Button onClick={() => handleUpdateSearchFirstNameExactMatch(!searchFirstNameExactMatch)}*/}
                                {/*            sx={{ bgcolor: '#ffffff', mt: '0.25VH' }}*/}
                                {/*        >*/}
                                {/*            First Name Exact*/}
                                {/*            <Switch*/}
                                {/*                checked={searchFirstNameExactMatch}*/}


                                {/*            />*/}

                                {/*        </Button>*/}
                                {/*    </List>*/}
                                {/*    <List*/}
                                {/*        style={{ marginTop: '0.5VH' }}*/}
                                {/*    >*/}
                                {/*        <Button onClick={() => handleUpdateSearchLastNameExactMatch(!searchLastNameExactMatch)}*/}

                                {/*            sx={{ bgcolor: '#ffffff', mt: '0.25VH' }}>*/}
                                {/*            Last Name Exact*/}
                                {/*            <Switch*/}
                                {/*                checked={searchLastNameExactMatch}*/}


                                {/*            />*/}

                                {/*        </Button>*/}
                                {/*    </List>*/}
                                {/*</AccordionDetails>*/}
                                {/*<AccordionDetails>*/}


                                {/*</AccordionDetails>*/}
                            </Accordion>
                        </Box>

                        <List
                            style={{ marginTop: '0.5VH' }}
                        >

                            {/* <CustomMobileInputAuditID value={searchValueAuditID} onChange={(e) => handleSearchValueAuditIDChange(e)} />*/}

                            <TextField

                                label="Audit ID"
                                variant="outlined"
                                value={searchValueAuditID}
                                onChange={handleSearchValueAuditIDChange}

                                sx={{ ml: 3, mr: '1VW', mt: 1, width: '77VW', bgcolor: '#FFFFFF' }}
                            />


                        </List>


                      

                        <List
                            style={{ marginTop: '0.5VH' }}
                        >


                            <TextField
                                label="Member/Employee First Name"
                                variant="outlined"
                                value={searchValueFirstName}
                                onChange={handleSearchValueFirstNameChange}

                                sx={{ ml: 3, mr: '1VW', mt: 1, width: '77VW', bgcolor: '#FFFFFF' }}


                            />


                        </List>

                        <List
                            style={{ marginTop: '0.5VH' }}
                        >


                            <TextField
                                label="Member/Employee Last Name"
                                variant="outlined"
                                value={searchValueLastName}
                                onChange={handleSearchValueLastNameChange}

                                sx={{ ml: 3, mr: '1VW', mt: 1, width: '77VW', bgcolor: '#FFFFFF' }}


                            />


                        </List>

                        <List
                            style={{ marginTop: '0.5VH' }}
                        >


                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateField
                                    label="Date of Birth"
                                    format="MM/DD/YYYY"
                                    value={searchValueDOB}
                                    onChange={(value) => handleSearchValueDOBChange(value)}
                                    sx={{ ml: 3, mr: '1VW', mt: 1, width: '77VW', bgcolor: '#FFFFFF' }}

                                />
                            </LocalizationProvider>


                        </List>

                        <List
                            style={{ marginTop: '0.5VH' }}
                        >


                            <TextField
                                label="Email Address"
                                variant="outlined"
                                value={searchValueEmailAddress}
                                onChange={handleSearchValueEmailAddressChange}

                                sx={{ ml: 3, mr: '1VW', mt: 1, width: '77VW', bgcolor: '#FFFFFF' }}


                            />


                        </List>

                       

                        <List
                            style={{ marginTop: '0.5VH' }}
                        >

                            <TextField
                                label="Client"
                                variant="outlined"
                                value={searchValueClient}
                                onChange={handleSearchValueClientChange}

                                sx={{ ml: 3, mr: '1VW', mt: 1, width: '77VW', bgcolor: '#FFFFFF' }}


                            />

                        </List>

                        <Tooltip title={userPrintablePersistentPreviousGlobalString !== [] ? userPrintablePersistentPreviousGlobalString?.map((item) => {

                            return (<>
                                {getPreviousSearchFieldName(item?.field.toString())}{item?.value.toString()} < br />
                            </>)

                        }) : null} placement="top">
                        <Button
                            variant="contained"
                            label="PREVIOUS SEARCH"
                            sx={{ ml: 3, mr: '1VW', width: '77VW', height: '5VH', bgcolor: '#3B82F6', mt: 3, }}
                            onClick={() => handleGetAndSetPreviousSearch(previousSearchParameters)}
                            disabled={(previousSearchParameters.length === 0) ? true : false}
                        >
                            PREVIOUS SEARCH
                        </Button>
                        </Tooltip>
                        <Button
                            variant="contained"
                            label="CLEAR SEARCH"
                            sx={{ ml: 3, mr: '1VW', width: '77VW', height: '5VH', bgcolor: '#FFFFFF', color: '#000000', mt: 3, }}
                            onClick={() => handleClearSearchAndBackUpPreviousFilterModel()}
                        >
                            CLEAR SEARCH
                        </Button>


                        <Button
                            variant="contained"
                            label="GENERATE SEARCH"
                            sx={{ ml: 3, mr: '1VW', width: '77VW', height: '5VH', bgcolor: '#3B82F6', mt: 3, }}
                            onClick={() => handleGenerateNewMainSearchFilterModel()}
                        >
                            GENERATE SEARCH
                        </Button>
                    </Box>

                </>) : (<></>)}

                {/*  MOBILE PAGE TITLES/HEADINGS*/}
                <Container sx={{ mt: 9, ml: 0.75, mr: 2, display: { xs: 'block', md: 'none' } }}>
                    <div className="row-no-left-margin">
                        <div className="row-left">

                            <Typography textAlign="left" component="h1" gutterBottom={true} fontWeight="600" fontSize="1.5em" sx={{ mt: 0, }}> Search All</Typography>
{/*                            <Typography textAlign="left" component="h1" gutterBottom={true} fontWeight="600" fontSize="1.5em" sx={{ mt: 0, }}> {`${auditName}`}</Typography>*/}


                        </div>
                        <div className="row-right">


                            <>
                                <Tooltip title="Open Search Filter Options">
                                    <Button
                                        onClick={handleDrawerOpenMobile}
                                        variant="contained"
                                        disableElevation sx={{
                                            height: '3.5VH', width: '3.5VH',
                                            bgcolor: 'transparent', color: '#262626', border: '2px solid #dddddd', boxShadow: 0,
                                            ':hover': {
                                                bgcolor: '#dddddd', // theme.palette.primary.main
                                                color: '#ffffff',
                                                boxShadow: 0,
                                            },
                                        }}
                                        style={{ textTransform: 'none' }}
                                    >
                                        <MdFilterList />
                                    </Button>


                                </Tooltip>

                            </>
                        </div>
                    </div>
                    <Box sx={{height: '2VH'}}></Box>
                </Container>


                <Container sx={{ display: { xs: 'block', md: 'none' } }}>
                    {employeeOnlySearch === true ? (<>
                        {haveAPISearchData !== true && apiData.length !== 0 ? (<>
                            {/* DESKTOP TABLE - FALLBACK SKELETON*/}
                           
                            <Box sx={{ height: 600, width: '78VW', ml: 3 }}>
                                <DataGridPro
                                    onRowClick={(row) => handleNavigateToFamilyDetails(row)}
                                    getRowId={getEmployeeRowID}
                                    columnVisibilityModel={{
                                        // Hide columns status and traderName, the other columns will remain visible
                                        other: false,
                                    }}
                                    onFilterModelChange={(model) => console.log("NEW Filter MODEL - GEN: ", model)}
                                    columns={dataGridColumns}
                                    loading={displayTableLoadingAnimation === true ? true : false}
                                    rows={[]}
                                    sx={{
                                        '& .MuiDataGrid-columnContainer': {
                                            bgcolor: '#F1F5F9',
                                            fontWeight: 800,
                                        },
                                    }}
                                    apiRef={apiRef}
                                    localeText={{ noRowsLabel: "No results were found during the Search. Please try again with differtn Search Parameters." }}
                                    slots={{
                                        noResultsOverlay: CustomNoResultsOverlay,
                                        noRowsOverlay: CustomNoRowsOverlay,
                                        detailPanelExpandIcon: IoMdArrowDropright,
                                        detailPanelCollapseIcon: IoMdArrowDropdown,
                                    }}
                                    getDetailPanelHeight={() => 250}

                                />
                            </Box>

                           

                        </>) : (<>
                            <Box sx={{ height: 600, width: '78VW', ml: 3, mt: 3 }}>
                                <DataGridPro
                                        onRowClick={(row) => handleNavigateToFamilyDetails(row)}
                                    getRowId={getEmployeeRowID}
                                    columnVisibilityModel={{
                                        // Hide columns status and traderName, the other columns will remain visible
                                        other: false,
                                    }}

                                    onFilterModelChange={(model) => console.log("NEW Filter MODEL - GEN: ", model)}
                                    columns={dataGridColumnsMediumOrSmall}
                                    rows={apiData.current}
                                    sx={{
                                        '& .MuiDataGrid-columnContainer': {
                                            bgcolor: '#F1F5F9',
                                            fontWeight: 800,
                                        },
                                    }}
                                    slots={{
                                        noResultsOverlay: CustomNoResultsOverlay

                                    }}
                                    apiRef={apiRef}



                                />


                            </Box>
                        </>)}
                    </>) : (<>
                        {haveAPISearchData !== true && apiData.length !== 0 ? (<>
                            {/* MOBILE TABLE - FALLBACK SKELETON*/}
                                <Box sx={{ height: 650, width: '78VW', ml: 3 }}>
                                    <DataGridPro
                                        onRowClick={(row) => handleNavigateToFamilyDetails(row)}
                                        getRowId={getEmployeeRowID}
                                        columnVisibilityModel={{
                                            // Hide columns status and traderName, the other columns will remain visible
                                            other: false,
                                        }}
                                        onFilterModelChange={(model) => console.log("NEW Filter MODEL - GEN: ", model)}
                                        columns={dataGridColumns}
                                        loading={displayTableLoadingAnimation === true ? true : false}
                                        rows={[]}
                                        sx={{
                                            '& .MuiDataGrid-columnContainer': {
                                                bgcolor: '#F1F5F9',
                                                fontWeight: 800,
                                            },
                                        }}
                                        localeText={{ noRowsLabel: "No results were found during the Search. Please try again with differtn Search Parameters." }}
                                        apiRef={apiRef}
                                        slots={{
                                            noResultsOverlay: CustomNoResultsOverlay,
                                            noRowsOverlay: CustomNoRowsOverlay,
                                            detailPanelExpandIcon: IoMdArrowDropright,
                                            detailPanelCollapseIcon: IoMdArrowDropdown,
                                        }}
                                        getDetailPanelHeight={() => 250}
                                        getDetailPanelContent={({ row }) => row.dependent_data != [] ? (
                                            <Box sx={{ height: 'fit-content', bgcolor: '#ffffff' }}>
                                                <DataGridPro
                                                    density="compact"
                                                    getRowId={getDependentID}
                                                    columns={[
                                                        { field: 'dependent_first_name', headerName: 'First Name', "width": 110 },
                                                        { field: 'dependent_last_name', headerName: 'Last Name', "width": 160 },
                                                        {
                                                            field: "dependent_relationship",
                                                            headerName: 'Relationship',
                                                            "width": 150,
                                                            renderCell: (params) =>
                                                                getDependentRelationship(params.row)


                                                        },
                                                        {
                                                            field: "dependent_date_of_birth", headerName: 'DOB', "width": 110, renderCell: (params) =>
                                                                renderDate(params.row?.dependent_date_of_birth)
                                                        },
                                                    ]}
                                                    rows={row?.dependent_data}
                                                    sx={{
                                                        mt: '2.5VH',
                                                        ml: '2.5VW',

                                                        width: '80VW',

                                                        bgcolor: '#F8FAFC',
                                                        boxShadow: 2,
                                                        border: 2,
                                                        borderColor: 'primary.light',
                                                        '& .MuiDataGrid-columnContainer': {
                                                            bgcolor: '#F1F5F9',
                                                            fontWeight: 800,
                                                        },
                                                        '& .MuiDataGrid-cell:hover': {
                                                            color: 'primary.main',
                                                        },
                                                    }}
                                                    hideFooter
                                                />
                                            </Box>
                                        ) : (<></>)

                                        }

                                    />
                                </Box>
                           

                        </>) : (<>
                                    <Box sx={{ height: 600, width: '78VW', ml: 3 }}>
                                        <DataGridPro
                                            onRowClick={(row) => handleNavigateToFamilyDetails(row)}
                                            getRowId={getEmployeeRowID}
                                            columnVisibilityModel={{
                                                // Hide columns status and traderName, the other columns will remain visible
                                                other: false,
                                            }}
                                            onFilterModelChange={(model) => console.log("NEW Filter MODEL - GEN: ", model)}
                                            columns={dataGridColumns}
                                            rows={apiData.current}
                                            sx={{
                                                '& .MuiDataGrid-columnContainer': {
                                                    bgcolor: '#F1F5F9',
                                                    fontWeight: 800,
                                                },
                                            }}
                                            apiRef={apiRef}
                                            slots={{
                                                detailPanelExpandIcon: IoMdArrowDropright,
                                                detailPanelCollapseIcon: IoMdArrowDropdown,
                                            }}
                                            getDetailPanelHeight={() => 250}
                                            getDetailPanelContent={({ row }) => row.dependent_data != [] ? (
                                                <Box sx={{ height: 'fit-content', bgcolor: '#ffffff' }}>
                                                    <DataGridPro
                                                        density="compact"
                                                        getRowId={getDependentID}
                                                        columns={[
                                                            { field: 'dependent_first_name', headerName: 'First Name', "width": 250 },
                                                            { field: 'dependent_last_name', headerName: 'Last Name', "width": 250 },
                                                            {
                                                                field: "dependent_relationship",
                                                                headerName: 'Relationship',
                                                                "width": 150,
                                                                renderCell: (params) =>
                                                                    getDependentRelationship(params.row)


                                                            },
                                                            {
                                                                field: "dependent_date_of_birth", headerName: 'Date Of Birth', "width": 220, renderCell: (params) =>
                                                                    renderDate(params.row?.dependent_date_of_birth)
                                                            },
                                                        ]}
                                                        rows={row?.dependent_data}
                                                        sx={{
                                                            mt: '2.5VH',
                                                            ml: '2.5VW',

                                                            width: '80VW',

                                                            bgcolor: '#F8FAFC',
                                                            boxShadow: 2,
                                                            border: 2,
                                                            borderColor: 'primary.light',
                                                            '& .MuiDataGrid-columnContainer': {
                                                                bgcolor: '#F1F5F9',
                                                                fontWeight: 800,
                                                            },
                                                            '& .MuiDataGrid-cell:hover': {
                                                                color: 'primary.main',
                                                            },
                                                        }}
                                                        hideFooter
                                                    />
                                                </Box>
                                            ) : (<></>)

                                            }

                                        />
                                    </Box>
                        </>)}
                    </>)}


                </Container>



                {/*<Container sx={{ display: { xs: 'block', md: 'none' } }}>*/}
                {/*    <>*/}
                {/*        <div className="row-no-left-margin">*/}
                {/*            <div className="center">*/}
                {/*                <SkeletonTheme baseColor="#EFF6FF" highlightColor="ffffff">*/}
                {/*                    <Skeleton height={560} width={'75VW'} />*/}
                {/*                </SkeletonTheme>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </>*/}
                {/*</Container>*/}

                {/*<Container sx={{ display: { xs: 'block', md: 'none' } }}>*/}
                {/*    */}{/* Pinned Claims Audits - MOBILE */}
                {/*    <Grid container spacing={1} sx={{ mt: 5, mr: 1, display: { xs: 'block', md: 'none' } }}>*/}

                {/*    </Grid>*/}
                {/*</Container>*/}

                {/*<Container sx={{ display: { xs: 'block', md: 'none' } }}>*/}
                {/*    */}{/* Pinned Claims Audits - MOBILE */}
                {/*    <Grid container spacing={1} sx={{ mt: 2, mr: 1, display: { xs: 'block', md: 'none' } }}>*/}
                {/*        <Grid item xs={12} sx={{ pl: 0 }}>*/}

                {/*        </Grid>*/}
                {/*        <Grid item xs={12} sx={{ pl: 0 }}>*/}

                {/*        </Grid>*/}


                {/*        <Grid item xs={12} sx={{ pl: 0 }}>*/}

                {/*        </Grid>*/}

                {/*        <>*/}

                {/*        </>*/}
                {/*        <>*/}

                {/*        </>*/}

                {/*    </Grid>*/}


                {/*</Container>*/}
            </Box>
          
            <ToastContainer
                limit={1}
                rtl={false}

            />
        </>
    )
}

export default GlobalEmployeeSearch;
